/* AUTOEXPORT:SETTING:MODULE:ApplicationShellModule */
import { ErrorHandler, Injectable, InjectFlags, Injector, NgZone } from "@angular/core";
import { APP_SERVICE, IAppService, IRedrowErrorHandler, REDROW_ERROR_HANDLER } from "@redrow/utilities";

@Injectable()
export class RedrowErrorHandler implements ErrorHandler {

	protected app: IAppService = undefined;
	protected zone: NgZone = undefined;

	protected errorHandlers: IRedrowErrorHandler[] = undefined;

	constructor(protected readonly injector: Injector) {

	}

	handleError(err) {
		console.error(err);

		if (this.app === undefined) {
			this.app = this.injector.get<IAppService>(APP_SERVICE);
		}

		if (this.zone === undefined) {
			this.zone = this.injector.get(NgZone);
		}

		if (this.errorHandlers === undefined) {
			this.errorHandlers = this.injector.get<IRedrowErrorHandler[]>(REDROW_ERROR_HANDLER, null, InjectFlags.Optional) || [];
			if (!Array.isArray(this.errorHandlers)) {
				this.errorHandlers = [
					this.errorHandlers
				];
			}
		}

		// Process error handlers
		for (const handler of this.errorHandlers) {
			if (!handler.handleError(err)) {
				// Ignore the error if the handler returns false
				return;
			}
		}

		this.zone.run(() => {
			this.app.handleError(err, `RedrowErrorHandler`);
		});
	}

}