/* AUTOEXPORT:SETTING:MODULE:ApplicationErrorModule */
import { Component, Inject, Input } from "@angular/core";
import { CONFIGURATION_MAPPER, IConfiguration } from "@redrow/configuration";
import { RedrowEnvironmentType } from "@redrow/utilities";

export function GetErrorInstance(obj: any): Error {
	// Cannot be an error if null
	if (!obj) {
		return null;
	}

	// This sometimes works...
	if (obj instanceof Error) {
		return obj;
	}

	// This should work when instanceof fails
	if (obj.message && typeof obj.message === "string") {
		return obj as Error;
	}

	// Convert simple error to Error class
	if (obj.Message && typeof obj.Message === "string") {
		return new Error(obj.Message);
	}

	// Not an error
	return null;
}

interface IEnvConfig {
	environment: RedrowEnvironmentType;
}

@Component({
	selector: "application-error",
	templateUrl: "./application-error.component.html",
	styleUrls: ["./application-error.component.scss"],
})
export class ApplicationErrorComponent {

	public isDev: boolean = false;

	constructor(
		@Inject(CONFIGURATION_MAPPER) protected readonly config: IConfiguration<IEnvConfig>
	) {


		const env = this.config.get().environment;
		if (
			env === "Local"
			|| env === "Development"
		) {
			this.isDev = true;
		}
	}

	reloadApplication() {
		window.location.reload();
	}


	@Input("error")
	public error: any;

	public get errorMessage(): string {

		if (this.error) {
			const e = GetErrorInstance(this.error);
			if (e) {
				return `${e.message} ${e.stack ? e.stack : ""}`;
			}
		}
		return ``;
	}

}

