import {
    AfterContentInit,
    ContentChildren,
    Directive,
    Host,
    Inject,
    Input,
    OnChanges,
    QueryList,
    SimpleChanges,
} from "@angular/core";
import {
    IRenderable,
    IRenderTarget,
    mixinNgSubscriptions,
    NgSubscriptionsCtor,
    RENDER_TARGET,
    RENDERABLE,
} from "@redrow/utilities";
import { merge, Subscription } from "rxjs";
import { filter } from "rxjs/operators";


class RedrowRenderWhenDirtyDirectiveBase {
    constructor() { }
}

const _RedrowRenderWhenDirtyDirectiveMixinBase: NgSubscriptionsCtor & typeof RedrowRenderWhenDirtyDirectiveBase = mixinNgSubscriptions(RedrowRenderWhenDirtyDirectiveBase);


/**
 * A directive that can be applied to any render target, causing it to render if any of the renderables change.
 * It uses the dirty state that each renderable must provide to determine when to render.
 * 
 * <ng-services-canvas-render-target-demo></ng-services-canvas-render-target-demo>
 */
@Directive({
    selector: `[render-when="dirty"]`,
    exportAs: "rrRenderWhenDirty"
})
export class RedrowRenderWhenDirtyDirective extends _RedrowRenderWhenDirtyDirectiveMixinBase implements AfterContentInit, OnChanges {

    @ContentChildren(RENDERABLE, { descendants: true })
    renderableChildren: QueryList<IRenderable>;

    constructor(
        @Host() @Inject(RENDER_TARGET) protected readonly renderTarget: IRenderTarget
    ) {
        super();
    }

    @Input("width")
    canvasWidth: number;

    @Input("height")
    canvasHeight: number;

    ngOnChanges(changes: SimpleChanges) {
        // React to canvas size changes
        if ("canvasWidth" in changes || "canvasHeight" in changes) {
            this.handleChange();
        }
    }


    ngAfterContentInit() {
        if (this.renderableChildren) {

            // Listen for changes on future children
            this.ngSubscriptions.add(
                this.renderableChildren.changes.subscribe(() => this.listenForDirtyChanges())
            );

            // Listen for changes on the existing children
            this.listenForDirtyChanges();
        }
    }

    _dirtyChangesSub: Subscription;

    protected handleChange() {
        this.renderTarget.render();
    }

    protected listenForDirtyChanges() {
        if (this._dirtyChangesSub && !this._dirtyChangesSub.closed) {
            this._dirtyChangesSub.unsubscribe();
        }
        this._dirtyChangesSub = new Subscription();

        this._dirtyChangesSub = merge(
            ...this.renderableChildren.map(x => x.dirtyChange())
        ).pipe(

            // Only refresh if dirty
            filter(y => y === true)

        ).subscribe(
            () => this.handleChange()
        );

        this.ngSubscriptions.add(
            this._dirtyChangesSub
        );
    }


}