/* AUTOEXPORT:SETTING:MODULE:RedrowLoadingScreen */
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { LoadingScreenActionStatus } from "@redrow/utilities";

import { RedrowBaseComponent } from "../base-component";
import { ILoadingScreenActionDisplay } from "../interfaces/loading-screen-action-display.interface";


@Component({
	selector: "rr-loading-screen-action",
	styleUrls: ["./loading-screen-action.component.scss"],
	templateUrl: "./loading-screen-action.component.html",
})
export class RedrowLoadingScreenActionComponent extends RedrowBaseComponent implements OnChanges {
	@Input()
	action: ILoadingScreenActionDisplay;

	inProgress: boolean = false;
	showWarning: boolean = false;
	showComplete: boolean = false;

	friendlyName: string = "...";

	constructor() {
		super();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ("action" in changes) {
			if (this.action) {
				this.friendlyName = this.action.text;
				this.inProgress = this.action.status === LoadingScreenActionStatus.LOADING;
				this.showComplete = this.action.status === LoadingScreenActionStatus.LOADED;
				this.showWarning = this.action.showWarning;
			}
		}
	}
}
