import { coerceNumberProperty } from "@angular/cdk/coercion";
import { Directive, Host, Inject, Input, NgZone, OnChanges, OnInit } from "@angular/core";
import { IRenderTarget, mixinNgSubscriptions, NgSubscriptionsCtor, RENDER_TARGET } from "@redrow/utilities";
import { interval } from "rxjs";


class RedrowRenderWhenIntervalDirectiveBase {
    constructor() { }
}

const _RedrowRenderWhenIntervalDirectiveMixinBase: NgSubscriptionsCtor & typeof RedrowRenderWhenIntervalDirectiveBase = mixinNgSubscriptions(RedrowRenderWhenIntervalDirectiveBase);


/**
 * A directive that can be applied to any render target - allowing a constant update interval
 * 
 * To set the interval you must provide one of the following:
 * [interval-ms]="100"
 * [frames-per-second]="30"
 * 
 * <ng-services-canvas-render-target-demo></ng-services-canvas-render-target-demo>
 */
@Directive({
    selector: `[render-when="interval"]`,
    exportAs: "rrRenderWhenInterval"
})
export class RedrowRenderWhenIntervalDirective extends _RedrowRenderWhenIntervalDirectiveMixinBase implements OnInit, OnChanges {



    constructor(
        @Host() @Inject(RENDER_TARGET) protected readonly renderTarget: IRenderTarget,
        protected readonly ngZone: NgZone
    ) {
        super();
    }

    ngOnInit() {
        this.setupInterval();
    }

    ngOnChanges() {
        this.setupInterval();
    }

    protected _intervalMs: number;

    @Input("interval-ms")
    set intervalMs(ms: string | number) {
        const asNumber = coerceNumberProperty(ms);
        if (this._intervalMs === asNumber) return;
        this._intervalMs = asNumber;
    }

    @Input("frames-per-second")
    set fps(frames: string | number) {
        frames = coerceNumberProperty(frames);
        const calculated = 1000 / frames;
        if (this._intervalMs === calculated) return;
        this._intervalMs = calculated;
    }

    setupInterval() {
        if (typeof this._intervalMs !== "number") return;

        this.clearNgSubscriptions();
        this.ngZone.runOutsideAngular(
            () => this.ngSubscriptions.add(
                interval(this._intervalMs).subscribe(
                    () => this.renderTarget.render()
                )
            )
        );
    }

}