import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppConfigurationBase, AppConfigurationEnvironment, IAppConfiguration } from "@config/external-contractor-login-app.interface";
import { ResolveAngularOAuthConfigFromAppConfiguration } from "@config/oauth-url.factory";
import { CONFIGURATION_MAPPER, ConfigurationModule, IConfiguration } from "@redrow/configuration";
import { LOGGER_FILTER, LoggerModule } from "@redrow/logging";
import { LOADING_SCREEN_ACTIONS, LoadingLoginLoaderService, RedrowLibModule } from "@redrow/material";
import { RedrowCheckboxSlideToggleModule } from "@redrow/ng-material-components";
import { IOAuthClientConfig, OAUTH_CONFIG, OAUTH_PROFILE_CLASS, OAuthComponent, OAuthModule } from "@redrow/oauth";
import { SharedDirectiveModule } from "@shared/directives/shared-directive.module";
import { AppVersionInterceptor } from "@shared/interceptor/version.interceptor";
import { SubbyAppIconRegistryModule } from "@shared/modules";
import { ContractorProfile } from "@shared/oauth/contractor-profile";
import { TokenExpiryLogoutService } from "@shared/services/token-expiry-logout.service";
import { applicationConfigurationFactory } from "src/config/config";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LogFilterService } from "./log-filter.service";


@NgModule({
	declarations: [
		AppComponent,

	],
	imports: [

		/**
		 * Material imports
		 */
		BrowserAnimationsModule,
		MatSidenavModule,
		MatToolbarModule,
		MatIconModule,
		MatDividerModule,
		MatButtonModule,
		MatIconModule,
		MatProgressSpinnerModule,

		FormsModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		MatNativeDateModule,

		HttpClientModule,

		AppRoutingModule,

		SharedDirectiveModule,

		/**
			 * Disable initial navigation so we can wait for the application to load first
			 */
		// RouterModule.forRoot([
		// 	{
		// 		path: '',
		// 		component: HomeComponent,
		// 		pathMatch: 'full',
		// 		canActivate: [
		// 			// OAuthLoggedInGuard
		// 		]
		// 	},

		// ],
		// 	),

		// @redrow/logging
		LoggerModule.default(),

		// @redrow/configuration
		ConfigurationModule.defaultWithConfigurationFactory<
				IAppConfiguration, 
				AppConfigurationBase, 
				AppConfigurationEnvironment,
				IOAuthClientConfig,
				IOAuthClientConfig
			>(applicationConfigurationFactory),

		// @redrow/oauth - using iframe flow
		OAuthModule.iframeFlow<OAuthComponent>(),

		/**
			 * Exports required infrastructure for all Angular apps. Included by defaults
			 * in all Angular apps created with the CLI new command. Re-exports CommonModule
			 * and ApplicationModule, making their exports and providers available to all apps.
			 */
		BrowserModule,

		/**
		 * Exports all the basic Angulardirectives and pipes, such as NgIf, NgForOf,
		 * DecimalPipe, and so on. Re-exported by BrowserModule, which is included
		 * automatically in the root AppModule when you create a new app with the
		 * CLI new command.
		 */
		CommonModule,

		RedrowLibModule.forRoot({
			loadingScreen: true
		}),


		RedrowCheckboxSlideToggleModule.withResponsiveToggle(),

		SubbyAppIconRegistryModule.forRoot()


	],
	providers: [

		/**
		 * We want UK dates!
		 */
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

		{ provide: LOCALE_ID, useValue: "en-GB" },

		//
		// {
		// 	provide: OAuthService,
		// 	useClass: OAuthService,
		// },

		// Provide implementation for contractor class for OAuth
		{
			provide: OAUTH_PROFILE_CLASS,
			useValue: ContractorProfile
		},

		{
			provide: OAUTH_CONFIG,
			useFactory: ResolveAngularOAuthConfigFromAppConfiguration,
			deps: [CONFIGURATION_MAPPER],
			multi: false,
		},

		{
			provide: LOADING_SCREEN_ACTIONS,
			useClass: LoadingLoginLoaderService,
			multi: true,
		},

		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppVersionInterceptor,
			multi: true
		},

		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenExpiryLogoutService,
			multi: true
		},

		/**
		 * Filter logs so that debug logs are only shown in local
		 */
		{
			provide: LOGGER_FILTER,
			useFactory: (config: IConfiguration<IAppConfiguration>) => new LogFilterService(config),
			deps: [CONFIGURATION_MAPPER]
		}

	],
	bootstrap: [AppComponent]
})
export class AppModule { }
