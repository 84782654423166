import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

import { PostMessageService } from "./post-message.service";



interface IPrefixedPostMessageServiceOptions {

    // The expected origin to listen for messages
    // i.e. https://dev-ms-oauth-external.redrow.co.uk
    targetOrigin: string;
    target?: Window | "current" | "parent"; // A specific window, the current one or the parent window - defaults to current window
    expectedPrefix: string;
}

function resolvePostTarget(opts: IPrefixedPostMessageServiceOptions) {
    if (typeof opts.target === "string") {
        if (opts.target === "parent") {
            if (typeof window !== "undefined") {
                return window.opener;
            } else {
                return null;
            }
        }
    } else {
        return opts.target;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    return null;
}


@Injectable({
    providedIn: "root"
})
export class PrefixedPostMessageService {


    constructor(protected readonly postMessageService: PostMessageService) {

    }

    /**
     * Listen for messages with a specific prefix.
     * @param opts 
     */
    public listenForMessages<MessageType = string>(opts: IPrefixedPostMessageServiceOptions): Observable<MessageType> {
        return this.postMessageService.listen(
            resolvePostTarget(opts)
        ).pipe(

            // Only accept valid events which are prefixed & from the expected origin
            filter(e => {
                console.debug(e);
                return e && e.data && e.origin === opts.targetOrigin && typeof e.data === "string" && e.data.startsWith(opts.expectedPrefix);
            }),

            // Parse the message
            map(e => JSON.parse(e.data.substr(opts.expectedPrefix.length)))

        );
    }

    /**
     * Send a message to a specific origin with a prefix.
     * @param message 
     * @param opts 
     */
    public sendMessage(message: any, opts: IPrefixedPostMessageServiceOptions) {
        return this.postMessageService.post(
            `${opts.expectedPrefix}${JSON.stringify(message)}`,
            resolvePostTarget(opts),
            opts.targetOrigin
        );
    }

}
