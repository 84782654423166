/* AUTOEXPORT:SETTING:MODULE:ApplicationShellModule */

export class ErrorWithContext extends Error {

	public context: string;

	constructor(context: string, message?: string) {
		super(message);

		this.name = "ErrorWithContext";
		this.context = context;

		// restore prototype chain
		// https://stackoverflow.com/questions/41102060/typescript-extending-error-class
		const actualProto = new.target.prototype;

		if (Object.setPrototypeOf) {
			Object.setPrototypeOf(this, actualProto);
		} else {
			(this as any).__proto__ = actualProto;
		}
	}
}
