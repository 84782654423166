import { Inject, Injectable } from "@angular/core";
import {
    APP_AUTHENTICATION_SERVICE, IAppAuthenticationService, mixinNgSubscriptions,
    mixinOptionalLogging,
    NgSubscriptionsCtor,
    OptionalLoggingCtor
} from "@redrow/utilities";
import { OAuthService } from "angular-oauth2-oidc";
import { concat, defer, EMPTY, of } from 'rxjs';
import { catchError, finalize, map, switchMap, first } from 'rxjs/operators';

import { RedrowOAuthAuthenticationService } from "../../private/services/oauth-app-authentication.service";
import { OAuthOpenIdConfigurationService } from "../../private/services/oauth-openid-configuration.service";
import { ANGULAR_OAUTH_OIDC_SERVICE } from "../../private/tokens/angular-oauth-oidc-service.token";




const _RedrowOAuthOutletServiceMixin: NgSubscriptionsCtor & OptionalLoggingCtor = mixinNgSubscriptions(mixinOptionalLogging(class _ { }));


/**
 * This service provides a way of changing outlet via oauth.
 * 
 * It is intended to be utilized with {@link IAppAuthenticationService} and the OAuth authentication implementation.
 */
@Injectable({
    providedIn: "root"
})
export class RedrowOAuthOutletService extends _RedrowOAuthOutletServiceMixin {

    constructor(
        @Inject(APP_AUTHENTICATION_SERVICE) protected readonly appAuthenticationService: IAppAuthenticationService,
        @Inject(ANGULAR_OAUTH_OIDC_SERVICE) protected readonly oauthService: OAuthService,
        protected readonly openIdConfigurationService: OAuthOpenIdConfigurationService
    ) {
        super();
    }

    /**
     * Request a change of outlet via OAuth
     * If successful a new token will be granted with the given outletId.
     * @param outletId 
     * @returns 
     */
    public changeOutlet(outletId: number) {

        // This is only really going to work with OAuth - so we deliberately couple the code with it here
        if (!(this.appAuthenticationService instanceof RedrowOAuthAuthenticationService)) {
            throw new Error(`Expected APP_AUTHENTICATION_SERVICE to be instanceof ${RedrowOAuthAuthenticationService.name}`);
        }
        const authService: RedrowOAuthAuthenticationService = this.appAuthenticationService;
        
        return defer(
            () => {
                if (typeof outletId !== "number" || outletId < 0) {
                    return of(false);
                }

                // Configure which outlet we're going to change to - for OAuth to pickup
                authService.setCustomQueryParam(
                    "changeOutletId", outletId
                );

                return concat(

                    // Make sure the discovery document has been loaded fully
                    this.openIdConfigurationService.observeOpenIdConfiguration().pipe(
                        first(),
                        switchMap(() => EMPTY)
                    ),

                    // Then refresh the token
                    defer(
                        () => this.oauthService.refreshToken()
                    )
                ).pipe(

                    // If we get a response - we are successful
                    map(() => true),

                    // An error indicates the operation failed
                    catchError(() => of(false)),

                    // Clean up the state we added to the auth service
                    finalize(
                        () => authService.deleteCustomQueryParam("changeOutletId")
                    )
                );
            }
        );
    }

}


