import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IAppConfiguration } from "@config/external-contractor-login-app.interface";
import { CONFIGURATION_MAPPER, IConfiguration } from "@redrow/configuration";
import { iif, Observable, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { BaseSubcontractorController } from "./base-controller";

export interface ISharedSettings {

	maxEvidenceImageCount: number;
	maxCommentImageCount: number;
	maxAttachmentFileSizeInBytes: number;
	maxAttachmentImageDimension: number;
	maxCommentCharacterCount: number;
	maxRejectionReasonCharacterCount: number;
	maxResolveCommentCharacterCount: number;

	/**
	 * How long (in days) can a user set the date range filter range for completed instructions?
	 */
	maxCompletedDateRangeDays: number;

}

export interface ISharedSettingsCache extends ISharedSettings {
	storedAt: Date;
}

class SharedSettings implements ISharedSettings {

	protected _raw: ISharedSettings;

	constructor(x: ISharedSettings) {
		this._raw = x;
	}

	get maxEvidenceImageCount(): number {
		if ("maxEvidenceImageCount" in this._raw) {
			return +this._raw.maxEvidenceImageCount;
		}
		return 0;

	}
	get maxCommentImageCount(): number {
		if ("maxCommentImageCount" in this._raw) {
			return +this._raw.maxCommentImageCount;
		}
		return 0;

	}

	get maxAttachmentFileSizeInBytes(): number {
		if ("maxAttachmentFileSizeInBytes" in this._raw) {
			return +this._raw.maxAttachmentFileSizeInBytes;
		}
		return 1024 * 5;
	}

	get maxAttachmentImageDimension(): number {
		if ("maxAttachmentImageDimension" in this._raw) {
			return +this._raw.maxAttachmentImageDimension;
		}
		return 1024;
	}

	get maxCommentCharacterCount(): number {
		if ("maxCommentCharacterCount" in this._raw) {
			return +this._raw.maxCommentCharacterCount;
		}
		return 250;
	}

	get maxRejectionReasonCharacterCount(): number {
		if ("maxRejectionReasonCharacterCount" in this._raw) {
			return +this._raw.maxRejectionReasonCharacterCount;
		}
		return 100;
	}

	get maxResolveCommentCharacterCount(): number {
		if ("maxResolveCommentCharacterCount" in this._raw) {
			return +this._raw.maxResolveCommentCharacterCount;
		}
		return 250;
	}

	get maxCompletedDateRangeDays(): number {
		if ("maxCompletedDateRangeDays" in this._raw) {
			return +this._raw.maxCompletedDateRangeDays;
		}
		return 30;
	}

	// Check expected keys have been found
	get shouldInvalidateCache(): boolean {
		const expectedKeys = ["maxCompletedDateRangeDays"];
		const actualKeys = new Set(Object.keys(this._raw));

		return !!expectedKeys.find(x => !actualKeys.has(x));
	}

}

const SHARED_SETTINGS_KEY = "SP_SHARED_SETTINGS";
const MAX_CACHE_TIME_SECONDS = 3600000;

@Injectable({
	providedIn: "root"
})
export class SharedSettingController extends BaseSubcontractorController {

	/**
	 *
	 * TODO
	 * - cache the settings in local storage
	 * - verify the settings from local cache - maybe using OAuth?
	 *
	 */
	_cached: ISharedSettings;

	constructor(protected readonly httpClient: HttpClient, @Inject(CONFIGURATION_MAPPER) protected readonly configMapper: IConfiguration<IAppConfiguration>) {
		super(configMapper);
	}

	protected url(path: string) {
		return super.url(
			`sharedsetting/${path}`
		);
	}


	public get(): Observable<ISharedSettings> {

		// Load from cache
		let wasLoadedFromCache = false;
		let cachedValue: ISharedSettingsCache = null;
		try {
			cachedValue = JSON.parse(atob(sessionStorage.getItem(SHARED_SETTINGS_KEY)));
			if (typeof cachedValue !== "object") {
				cachedValue = null;
			}
			if (cachedValue) {
				let storedFor = new Date().getTime() - new Date(cachedValue.storedAt).getTime();
				wasLoadedFromCache = storedFor < MAX_CACHE_TIME_SECONDS;
			}
		} catch (e) { }


		return iif(
			() => wasLoadedFromCache,
			of(cachedValue),
			this.httpClient.get<ISharedSettings>(this.url(""))
		).pipe(

			catchError(err => {
				console.warn("Failed to load shared settings.");
				return of(null);
			}),
			tap(x => sessionStorage.setItem(SHARED_SETTINGS_KEY, btoa(
				JSON.stringify({
					...x,
					storedAt: new Date()
				} as ISharedSettingsCache)
			))),
			map(x => new SharedSettings(x)),
			switchMap(
				x => {
					if (x.shouldInvalidateCache) {
						console.warn(`Missing keys in shared settings. Invalidating cache.`);
						sessionStorage.removeItem(SHARED_SETTINGS_KEY);
						return this.get();
					}
					return of(x);
				}
			)
		)
	}

}
