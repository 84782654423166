// rework of https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format
// formatUnicorn... xD
// StringFormat("{0} hello {1}", "a", "b")
// StringFormat("{test} hello {this}", { test: "hello", this: "world" })
export function StringFormat(str: string, ...args: any[]) {
	if (Array.isArray(args) && args.length > 0) {
		if (typeof args[0] === "string" || typeof args[0] === "number") {
			args = args.slice();
		} else {
			args = args[0];
		}
		for (const key of Object.keys(args)) {
			str = str.replace(new RegExp("\\$\\{" + key + "\\}", "gi"), args[key]);
		}
	}
	return str;
}
