import { RedrowEnvironmentType } from "../../types/redrow-environment.types";

/**
 * Useful helper function to produce an API url based on the environment
 * i.e. MakeApiUrl("Local", "was-inspectionportal.redrow.co.uk/api/", false)
 * @param env The environment
 * @param apiBaseUrl the url to prefix
 * @param useHttps Should it be a HTTP url? defaults to true
 */
export function MakeApiUrl(env: RedrowEnvironmentType, apiBaseUrl: string, useHttps: boolean = true) {
	let envName = env
		.toLowerCase()
		.split(" ")
		.join("");

	switch (env) {
		case "Development":
		case "Dev Azure":
			envName = "dev";
			break;
		case "Development 2":
			envName = "dev2";
			break;
		case "Development Yesterday":
			envName = "devyesterday";
			break;
		case "Test Azure":
			envName = "test";
			break;
	}

	return `${useHttps ? "https" : "http"}://${env === "Live" ? "" : envName + "-"}${apiBaseUrl}`;
}
