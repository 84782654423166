<div class="loading-screen-container" *ngIf="isLoading | async">
	<div class="group-handlers">
		<div class="group-logo">
			<mat-icon svgIcon="redrow-logo-red"></mat-icon>
		</div>
		<rr-loading-screen-action *ngFor="let action of displayActions" [action]="action"></rr-loading-screen-action>
	</div>

	<div class="app-version">
		App Version: <strong>{{ appVersion }}</strong>
	</div>
</div>
