/**
 * A http timeout has occured on the frontend.
 */
export class HttpTimeoutError extends Error {
    constructor() {
        super("HttpTimeout");


        // restore prototype chain
        // https://stackoverflow.com/questions/41102060/typescript-extending-error-class
        const actualProto = new.target.prototype;

        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, actualProto);
        } else {
            (this as any).__proto__ = actualProto;
        }

        this.name = "HttpTimeoutError";
    }
}