import { Injectable } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IGenericFormService } from "@shared/interfaces/generic-form-service.interface";


export interface IEditProfileFormData {
	forename: string;
	surname: string;
	email: string;

}

@Injectable({
	providedIn: "root"
})
export class EditProfileFormService implements IGenericFormService<IEditProfileFormData> {

	constructor(protected readonly formBuilder: UntypedFormBuilder) { }
	public get(group: UntypedFormGroup): IEditProfileFormData {
		return {
			...group.value
		};
	}


	public create(defaultValues?: Partial<IEditProfileFormData>): UntypedFormGroup {
		const g = this.formBuilder.group({

			forename: [
				"",
				[
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100),
					Validators.pattern("[a-zA-Z]*") // Only A-Z or a-z
				]
			],

			surname: [
				"",
				[
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100),
					Validators.pattern("[a-zA-Z]*") // Only A-Z or a-z
				]
			],

			email: [
				"",
				[
					Validators.required,
					Validators.email,
				]
			],


		});

		g.get("email").disable();

		if (defaultValues) {
			g.patchValue(defaultValues);
		}

		return g;
	}

}
