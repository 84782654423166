import { Directive, HostBinding, Input, OnDestroy } from "@angular/core";
import { Subject, Subscription } from "rxjs";

import { RedrowBaseClass } from "./base-class";

/**
 * The base component for all components that comes with some useful functions.
 */
@Directive()
export class RedrowBaseComponent extends RedrowBaseClass implements OnDestroy {


	/**
	 * Private variables which store the subscription management instances so we only pay for the object if we use subscriptions.
	 */
	private _ngUnsubscribe: Subject<void> | undefined;
	private _ngSubscriptions: Subscription | undefined;

	/**
	 * Subject that gets fired when this component is destroyed
	 */
	protected get ngUnsubscribe(): Subject<void> {
		if (this._ngUnsubscribe === undefined || this._ngUnsubscribe === null || this._ngSubscriptions.closed) {
			this._ngUnsubscribe = new Subject<void>();
		}
		return this._ngUnsubscribe;
	}

	/**
	 * When subscribing to events you must add discardable events to this list.
	 * When the component is destroyed this list of subscriptions will automatically
	 * be freed from memory and unbound.
	 */
	protected get ngSubscriptions(): Subscription {
		if (this._ngSubscriptions === undefined || this._ngSubscriptions === null || this._ngSubscriptions.closed) {
			this._ngSubscriptions = new Subscription();
		}
		return this._ngSubscriptions;
	}

	/**
	 * Is this component currently loading?
	 */
	@Input()
	@HostBinding("class.loading")
	public loading: boolean;

	public error: string;

	/**
	 * Is this component currently disabled?
	 */
	@Input()
	public disabled: boolean;

	constructor() {
		super();

	}




	ngOnDestroy() {
		this.unsubscribeAll();
		this.dispose();

		this._ngUnsubscribe = null;
		this._ngSubscriptions = null;
	}

	/**
	 * Unsubscribe to all ngSubscriptions and refresh the ngSubscriptions variable
	 */
	protected unsubscribeAll() {
		if (this._ngUnsubscribe) {
			this._ngUnsubscribe.next();
			this._ngUnsubscribe.complete();
		}
		if (this._ngSubscriptions) {
			this._ngSubscriptions.unsubscribe();
		}
	}


}

