/* AUTOEXPORT:SETTING:MODULE:BrowserOfflineErrorModule */
import { Component } from "@angular/core";

import { RedrowBaseComponent } from "../../base-component";



@Component({
	selector: "browser-offline-error",
	templateUrl: "./browser-offline-error.component.html",
	styleUrls: ["./browser-offline-error.component.scss"],
})
export class BrowserOfflineErrorComponent extends RedrowBaseComponent {

	appName: string = "Subcontractors Portal";

	constructor() {
		super();
	}

	reloadApplication() {
		window.location.reload();
	}
}

