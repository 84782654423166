import { FocusMonitor } from "@angular/cdk/a11y";
import { COMMA, ENTER, SEMICOLON, SPACE } from "@angular/cdk/keycodes";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    forwardRef,
    HostBinding,
    Input,
    OnDestroy,
    Optional,
    Self,
} from "@angular/core";
import { FormGroupDirective, NgControl, NgForm } from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { CanUpdateErrorState, ErrorStateMatcher, mixinErrorState } from "@angular/material/core";
import { MatFormFieldControl } from "@angular/material/form-field";
import { Subject } from "rxjs";
import { delay } from "rxjs/operators";

import { redLibraryIconCancel } from "@redrow/ng-redlibrary-icons";
import {
	AbstractConstructor,
    CanDisableCtor,
    coerceBooleanProperty,
    Constructor,
    extractValidEmailsFromString,
    FormValueCtor,
    mixinCanDisable,
    mixinFormValue,
    mixinNgSubscriptions,
    NgSubscriptionsCtor,
} from "@redrow/utilities";

let _nextEmailInputId = 0;

class RedrowEmailInputComponentBase {
    constructor(
		public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        /** @docs-private */
        public ngControl: NgControl
	) { }

	/**
	 * Emit events when state changes
	 */
    public readonly stateChanges: Subject<void> = new Subject<void>();
}

declare type CanUpdateErrorStateCtor = Constructor<CanUpdateErrorState> & AbstractConstructor<CanUpdateErrorState>;

const _RedrowEmailInputComponentBase: CanUpdateErrorStateCtor & Constructor<OnDestroy> & NgSubscriptionsCtor & FormValueCtor<unknown> & CanDisableCtor & typeof RedrowEmailInputComponentBase = mixinErrorState(
    mixinNgSubscriptions(
        mixinFormValue(
            mixinCanDisable(
                RedrowEmailInputComponentBase
            )
        )
    )
);

/**
 * Allow the user to input a list of e-mail addresses.
 * 
 * Introduced as part of Complaint Portal.
 * 
 * <ng-material-components-email-input-demo></ng-material-components-email-input-demo>
 */
@Component({
    selector: "rr-email-input",
    templateUrl: `./email-input.component.html`,
    styleUrls: [
        `./email-input.component.scss`
    ],
    inputs: [
        "disabled"
    ],
    providers: [

        // So we can use within a mat-form-field component
        // See https://v11.material.angular.io/guide/creating-a-custom-form-field-control
        {
            provide: MatFormFieldControl,
            useExisting: forwardRef(() => RedrowEmailInputComponent)
        },

    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedrowEmailInputComponent extends _RedrowEmailInputComponentBase implements MatFormFieldControl<string[]>, DoCheck {

    // We want a new e-mail to be added when any of the following characters are entered in the input field
    readonly emailSeparatorKeysCodes = [ENTER, COMMA, SEMICOLON, SPACE] as const;

    // Bring in the x icon for use when removing e-mails
    public xIcon = redLibraryIconCancel;


    constructor(

        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        _defaultErrorStateMatcher: ErrorStateMatcher,

        // We inject ng control to control the form state
        @Optional() @Self() public readonly ngControl: NgControl,

        protected readonly focusMonitor: FocusMonitor,
        protected readonly elRef: ElementRef,
        protected readonly cd: ChangeDetectorRef
    ) {
        super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

        // The value accessor is implemented by this class
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }

        // Emit focused property whenever anything is focused within this element
        this.ngSubscriptions.add(
            this.focusMonitor.monitor(elRef, true).pipe(delay(1)).subscribe(
                origin => {
                    this.focused = !!origin;
                    this.stateChanges.next();
                }
            )
        );
    }

    /**
     * Override the paste event so that we can extract valid e-mail addresses.
     * @param e
     */
    handleInputPaste(e: Event) {
        const clipboardEvent = e as ClipboardEvent;
        const text = clipboardEvent.clipboardData.getData("text/plain");
        if (!!text) {

            // Support for Outlook format
            // John Doe <aaaa@bbb.com>; Jessica Jones <ccc@ddd.com>
            const matchedEmails = extractValidEmailsFromString(text);

            for (let matchedEmail of matchedEmails) {
                this.addEmail(matchedEmail);
            }
        }

        // Don't paste value
        e.preventDefault();

    }

    /**
     * Provide a list of e-mails which cannot be removed
     */
    @Input("required-email-list")
    set requiredEmailList(x: string[]) {
        this._requiredEmailList = Array.isArray(x) ? x.map(y => y.toLowerCase()) : [];
    }

    get requiredEmailList(): string[] {
        return this._requiredEmailList;
    }

    protected _requiredEmailList: string[] = [];

    public isEmailRequired(emailAddress: string) {
        return this._requiredEmailList.indexOf(emailAddress) > -1;
    }

    get empty() {
        return !Array.isArray(this.formValue) || this.formValue.length === 0
    }

    get value(): string[] {
        return this.formValue as string[];
    }

    set value(x: string[]) {
        this.setFormValue(x);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        this.stateChanges.complete();
    }

    ngDoCheck() {
        if (this.ngControl) {
            // We need to re-evaluate this on every change detection cycle, because there are some
            // error triggers that we can't subscribe to (e.g. parent form submissions). This means
            // that whatever logic is in here has to be super lean or we risk destroying the performance.
            this.updateErrorState();
        }
    }

    id: string = `email-input-${++_nextEmailInputId}`;

    /**
     * Display a placeholder within the input.
     */
    @Input()
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }
    private _placeholder: string;

    focused: boolean;

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }
    @Input()
    get required() {
        return this._required;
    }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;

    controlType: string = "cp-email-input";
    autofilled?: boolean;

    /** An object used to control when error messages are shown. */
    @Input() errorStateMatcher: ErrorStateMatcher;

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    @Input('aria-describedby') userAriaDescribedBy: string;

    setDescribedByIds(ids: string[]): void {
        // TODO
        // Considering we don't officially support accessibility options - this could be done at a later date
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() != 'input') {
            this.elRef.nativeElement.querySelector('input').focus();
        }
    }


    public get emails(): string[] {
        return this.formValue as string[];
    }

    /**
     * Add a new e-mail address to the form value.
     * @param emailAddress
     */
    public addEmail(emailAddress: string) {
        // All emails should be lowercase
        let e = emailAddress.trim().toLowerCase();

        if (e) {

            // Check for duplicate
            let duplicate = false;
            if (this.emails) {
                if (this.emails.indexOf(e) > -1) {
                    duplicate = true;
                }
            }

            if (!duplicate) {
                // Add to form value
                this.setFormValue([
                    ...this.emails,
                    e
                ]);
            }
        }
    }

    /**
     * Handle a new chip event
     * @param event
     * @private
     */
    public handleChipInputEvent(event: MatChipInputEvent) {
        const emailAddress = (event.value || "");

        if (emailAddress) {
            this.addEmail(emailAddress);
        }

        // Clear the input value
        if (event.input) {
            event.input.value = "";
        }

    }

    /**
     * Remove an e-mail from the form value.
     * @param emailAddress
     * @returns
     */
    public removeEmail(emailAddress: string) {

        if (!emailAddress) return;
        emailAddress = emailAddress.toLowerCase();

        if (Array.isArray(this.emails)) {
            this.setFormValue(
                this.emails.filter(x => x !== emailAddress)
            );
        }
    }

    writeValue(obj: any): void {
        super.writeValue(obj);

        this.cd.markForCheck();
    }

}
