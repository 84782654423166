import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SharedMaterialImportsModule } from "@shared/shared-material-imports.module";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { DashboardModule } from "./dashboard/dashboard.module";
import { DocumentsComponent } from "./documents/documents.component";
import { DocumentsModule } from "./documents/documents.module";
import { SettingsComponent } from "./settings/settings.component";
import { SettingsModule } from "./settings/settings.module";



const routes: Routes = [


	/**
	 * Core routes
	 */
	{
		path: "dashboard",
		component: DashboardComponent,
		pathMatch: "full"
	},

	{
		path: "documents",
		component: DocumentsComponent,
		pathMatch: "full"
	},

	{
		path: "profile",
		component: SettingsComponent,
		pathMatch: "full"
	},

	/**
	 * Lazy load the User Management module
	 */
	{
		path: "usermanagement",
		loadChildren: () => import("./user-management/usermanagement.module").then(m => m.UserManagementModule)
	},

	/**
	 * Lazy load the Instruction module
	 */
	{
		path: "instructions",
		loadChildren: () => import("./instructions/instructions.module").then(m => m.InstructionsModule)
	},

	/**
	 * Lazy load the Help module
	 */
	{
		path: "help",
		loadChildren: () => import("./help/help.module").then(m => m.HelpModule)
	},

	{
		path: "**",
		redirectTo: "dashboard"
	},
];

@NgModule({
	imports: [
		SharedMaterialImportsModule,
		RouterModule.forRoot(routes, {
    initialNavigation: "disabled"
}),
		DashboardModule,
		DocumentsModule,
		SettingsModule
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
