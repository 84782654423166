import { HttpClient, HttpErrorResponse, HttpEventType, HttpResponseBase } from "@angular/common/http";
import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { catchError, filter, finalize, first, map, share, tap, timeout } from "rxjs/operators";

/**
 * Provide a custom url to check for an internet connection.
 */
export const INTERNET_CONNECTIVITY_CHECK_URL = new InjectionToken<string>("INTERNET_CONNECTIVITY_CHECK_URL");

/**
 * How long should an online check result be valid for?
 */
export const INTERNET_CONNECTIVITY_CHECK_RESULT_CACHE_TIME = new InjectionToken<number>("INTERNET_CONNECTIVITY_CHECK_RESULT_CACHE_TIME");

/**
 * Configure how long the timeout should be for internet connectivity - default 2 seconds
 */
export const INTERNET_CONNECTIVITY_CHECK_TIMEOUT_TIME = new InjectionToken<number>("INTERNET_CONNECTIVITY_CHECK_TIMEOUT_TIME");

/**
 * An implementation of internet connectivity checking.
 * Allows you to get status updates when the internet connectivity changes and to initiate internet connectivity checks.
 * The implementation just sends a head request to INTERNET_CONNECTIVITY_CHECK_URL or /favicon.ico to see if we get a resonse.
 * With this implementation the result is also cached for a period of time which defaults to 30 seconds and can be configured via INTERNET_CONNECTIVITY_CHECK_RESULT_CACHE_TIME
 */
@Injectable({
	providedIn: "root"
})
export class RedrowInternetConnectivityService {

	protected readonly _online: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	protected readonly _error: Subject<any> = new Subject<any>();

	/**
	 * Determine if we have internet connectivity and when it changes.
	 * This value gets updated by the use of the .check() method.
	 */
	public online(): Observable<boolean> {
		return this._online.asObservable();
	}

	/**
	 * Emits when an error occurs when checking for internet connectivity
	 */
	public error(): Observable<any> {
		return this._error.asObservable();
	}

	protected _check: Observable<boolean>;

	// Date.getTime - when did we last check the online status?
	protected _lastChecked: number;

	constructor(
		@Optional() @Inject(INTERNET_CONNECTIVITY_CHECK_URL) protected readonly checkUrl: string,
		@Optional() @Inject(INTERNET_CONNECTIVITY_CHECK_RESULT_CACHE_TIME) protected readonly cacheTime: number,
		@Optional() @Inject(INTERNET_CONNECTIVITY_CHECK_TIMEOUT_TIME) protected readonly timeoutTime: number,
		protected readonly httpClient: HttpClient
	) {
		this.checkUrl = this.checkUrl || `/favicon.ico`;
		this.cacheTime = this.cacheTime || 30000;
		this.timeoutTime = this.timeoutTime || 2000;
	}


	/**
	 * Check internet connectivity by send a head request to a static file on the server.
	 * This only checks connectivity to the frontend static files, not the API.
	 * You could configure it to send a request to the API if you need that information instead.
	 * @param maxCacheTime How long is acceptable to use the cached online status?
	 */
	public check(maxCacheTime: number = this.cacheTime): Observable<boolean> {

		// If the last online check was within an acceptable margin of error - use the current value
		if (this._lastChecked !== undefined) {
			const timeSinceLastCheck = new Date().getTime() - this._lastChecked;
			if (timeSinceLastCheck < maxCacheTime) {
				return of(
					this._online.value
				);
			}
		}

		// Otherwise we start a new check/use an existing one

		// Use an existing check if one is already going on
		if (this._check) {
			return this._check;
		}

		// Start a new check
		this._check = this.httpClient.head(

			// Stop caching by putting time in the url
			// Bypass the service worker using ngsw-bypass
			`${this.checkUrl}?t=${new Date().getTime()}&ngsw-bypass=true`,

			// We only care about events here - not the actual content as this is a head request
			// Only trying to determine if we have some kind of connection
			{
				observe: "events",
				responseType: "text"
			}
		).pipe(

			// We don't care about the body - just if we get a header or response
			filter(x => x.type === HttpEventType.ResponseHeader || x.type === HttpEventType.Response),
			first(),

			// Short timeout
			timeout(this.timeoutTime),

			// Translate to true (connected) or false (not connected)
			map(x => (x as HttpResponseBase).ok),
			catchError(err => {

				// If we get an error it still means we have a connection as long as we have a valid status
				if (err instanceof HttpErrorResponse) {
					if (err.status !== 0 && err.status !== 504) {
						return of(true);
					}
				}

				this._error.next(err);
				return of(false);
			}),

			// Notify listeners
			tap(isOnline => {
				if (isOnline !== this._online.value) {
					this._online.next(isOnline)
				}
				this._lastChecked = new Date().getTime();
			}),

			finalize(
				() => this._check = undefined
			),

			share()
		);

		return this._check;
	}

}
