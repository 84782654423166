import { Component, Input } from "@angular/core";

@Component({
	selector: "ip-page-shell",
	templateUrl: `./page-shell.component.html`,
	styleUrls: [
		`./page-shell.component.scss`
	]
})
export class PageShellComponent {

	@Input()
	public error: string;

	@Input()
	public loading: boolean;

}
