import { coerceBooleanProperty } from "../../functions/coercion/boolean-property";
import { Constructor } from "../constructor.type";
import { CanDisableCtor } from "./can-disable-ctor.type";

/**
 * Mixin to augment a directive with a `disabled` property.
 * **NOTE** Please refer to {@page ~~/wiki/best-practice/import-inferred-types.md} for a common gotcha when using this within the library.
*/
export function mixinCanDisable<T extends Constructor<{}>>(base: T): CanDisableCtor & T {
    return class extends base {
        private _disabled: boolean = false;

        get disabled() { return this._disabled; }
        set disabled(value: any) { this._disabled = coerceBooleanProperty(value); }

        constructor(...args: any[]) { super(...args); }
    };
}