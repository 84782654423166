import { CommonModule } from "@angular/common";
import { Inject, ModuleWithProviders, NgModule, Optional, Type } from "@angular/core";
import { provideRoutes, RouterModule } from "@angular/router";
import { CONFIGURATION_MAPPER } from "@redrow/configuration";
import { DefaultLogger, LOGGER_DESTINATION, LOGGER_FILTER } from "@redrow/logging";
import { OAUTH_SERVICE, REDROW_HELPDESK_URL_SERVICE } from "@redrow/utilities";
import { OAuthLogger, OAuthModule as AngularOAuthOpenIdModule, OAuthModuleConfig } from "angular-oauth2-oidc";

import {
    LegacyAngularOauthOidcServiceFactory,
} from "../../../private/_deprecated/factory/legacy-angular-oauth-oidc-service.factory";
import {
    LegacyOAuthAuthenticationOptionsFactory,
} from "../../../private/_deprecated/factory/legacy-oauth-authentication-options.factory";
import { OAUTH_LOGGER } from "../../../private/_deprecated/tokens/oauth-logger.token";
import {
    REFRESH_TOKEN_CHECK_BLACKLIST,
    REFRESH_TOKEN_CHECK_ENABLED,
} from "../../../private/_deprecated/tokens/refresh-token-check-blacklist.token";
import { IFrameHandlerService } from "../../../private/services/oauth-iframe-handler.service";
import { OAuthIFrameParamInjectionFlow } from "../../../private/services/oauth-iframe-param-injection-flow.service";
import { ANGULAR_OAUTH_OIDC_SERVICE } from "../../../private/tokens/angular-oauth-oidc-service.token";
import { RedrowProfile } from "../../models/redrow-profile.model";
import { OAuthHelpdeskUrlService } from "../../services/oauth-helpdesk-url.service";
import { REDROW_OAUTH_AUTHENTICATION_SERVICE_OPTIONS } from "../../tokens/oauth-authentication-service-options.token";
import { OAuthComponent } from "../components/oauth/oauth.component";
import { OAuthLoggedInGuard } from "../guards/oauth.loggedin.guard";
import { OAuthService } from "../services/oauth.service";
import { OAUTH_CONFIG } from "../tokens/oauth-config.token";
import { OAUTH_PROFILE_CLASS } from "../tokens/profile.token";


const exportsComponents = [OAuthComponent];
const exportModals = [];

export function OAuthLoggerFactory(loggers, filters) {
	return new DefaultLogger(loggers, filters, "@redrow/oauth");
}

/**
 * OAuth module for frontend apps.
 * 
 * @deprecated Please see {@page ~~/wiki/solutions/authentication/oauth-quickstart.md} for how to configure OAuth.
 */
@NgModule({
    imports: [CommonModule, RouterModule, AngularOAuthOpenIdModule.forRoot()],
    exports: [...exportsComponents],
    declarations: [...exportsComponents],
    providers: [
        OAuthLoggedInGuard,
        {
            provide: OAUTH_LOGGER,
            useFactory: OAuthLoggerFactory,
            deps: [LOGGER_DESTINATION, [new Optional(), new Inject(LOGGER_FILTER)]],
            multi: false,
        },
        // Provide the API resource server from app config
        {
            provide: OAuthModuleConfig,
            useExisting: OAUTH_CONFIG,
        },
        // Make angular-oauth2-oidc use the same logger implementation as this module
        {
            provide: OAuthLogger,
            useExisting: OAUTH_LOGGER,
        },
        // Use local storage instead of session storage
        // { provide: OAuthStorage, useValue: localStorage },
    ]
})
export class OAuthModule {

	/**
	 * @deprecated Please see {@page ~~/wiki/solutions/authentication/oauth-quickstart.md} for how to configure OAuth.
	 */
	static iframeFlow<T extends OAuthComponent>(customOAuthScreen?: Type<T>): ModuleWithProviders<OAuthModule> {
		return {
			ngModule: OAuthModule,
			providers: [
				// Provide the iframe injection flow
				{
					provide: OAuthIFrameParamInjectionFlow,
					useClass: OAuthIFrameParamInjectionFlow,
				},

				// Provide an iframe handler for the oauth iframe service to use
				{
					provide: IFrameHandlerService,
					useClass: IFrameHandlerService,
				},

				// Provide OAUTH_SERVICe implementation
				{
					provide: OAUTH_SERVICE,
					useExisting: OAuthService
				},

				// Provide default profile class (internal redrow oauth)
				{
					provide: OAUTH_PROFILE_CLASS,
					useValue: RedrowProfile
				},

				// Provide a helpdesk url implementation
				{
					provide: REDROW_HELPDESK_URL_SERVICE,
					useClass: OAuthHelpdeskUrlService
				},

				{
					provide: REFRESH_TOKEN_CHECK_ENABLED,
					useValue: true
				},

				{
					provide: REFRESH_TOKEN_CHECK_BLACKLIST,
					useValue: "amr",
					multi: true
				},

				{
					provide: REFRESH_TOKEN_CHECK_BLACKLIST,
					useValue: "at_hash",
					multi: true
				},

				{
					provide: REFRESH_TOKEN_CHECK_BLACKLIST,
					useValue: "aud",
					multi: true
				},

				{
					provide: REFRESH_TOKEN_CHECK_BLACKLIST,
					useValue: "auth_time",
					multi: true
				},

				{
					provide: REFRESH_TOKEN_CHECK_BLACKLIST,
					useValue: "iss",
					multi: true
				},

				{
					provide: REFRESH_TOKEN_CHECK_BLACKLIST,
					useValue: "idp",
					multi: true
				},

				{
					provide: REFRESH_TOKEN_CHECK_BLACKLIST,
					useValue: "iat",
					multi: true
				},

				{
					provide: REFRESH_TOKEN_CHECK_BLACKLIST,
					useValue: "exp",
					multi: true
				},

				{
					provide: REFRESH_TOKEN_CHECK_BLACKLIST,
					useValue: "nbf",
					multi: true
				},

				// Forward compatibility with IAppAuthenticationService
				{
					provide: REDROW_OAUTH_AUTHENTICATION_SERVICE_OPTIONS,
					useFactory: LegacyOAuthAuthenticationOptionsFactory,
					deps: [
						[new Inject(CONFIGURATION_MAPPER)]
					]
				},

				{
					provide: ANGULAR_OAUTH_OIDC_SERVICE,
					useFactory: LegacyAngularOauthOidcServiceFactory,
					deps: [
						[new Inject(OAUTH_SERVICE)]
					]
				},


				provideRoutes([
					///
					/// OAUTH ROUTES
					///

					// After oauth goto the init page - initapp for inspection portal
					// Provided by app
					// { path: "postoauth", redirectTo: "/", pathMatch: "full" },

					// Callback from oauth which will transfer token to client and forward to /postoauth
					// { path: "callback", component: OAuthComponent, data: { action: "callback" }, pathMatch: "full" },

					// OAuth url - will redirect to OAuth provided and handle login
					{ path: "oauth/:action", component: customOAuthScreen || OAuthComponent, pathMatch: "full" },
				]),
			],
		};
	}
}
