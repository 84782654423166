import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";
import { SharedDirectiveModule } from "@shared/directives/shared-directive.module";
import { PageShellModule } from "@shared/page-shell/page-shell.module";

import { DashboardComponent } from "./dashboard.component";



@NgModule({
	declarations: [
		DashboardComponent,

	],
	imports: [
		CommonModule,
		MatCardModule,
		MatButtonModule,
		MatGridListModule,
		MatProgressSpinnerModule,
		MatIconModule,
		RouterModule,
		PageShellModule,
		MatDividerModule,
		SharedDirectiveModule
	]
})
export class DashboardModule { }
