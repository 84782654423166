import { IConfiguration } from "@redrow/configuration";

import { IOAuthClientConfig } from "../../../public/_deprecated/interfaces/application-config-with-oauth.interface";
import {
    IRedrowOAuthAuthenticationServiceOptions,
} from "../../../public/interfaces/oauth-authentication-service-options.interface";

/**
 * Forward compatibility with IRedrowOAuthAuthenticationServiceOptions based on old configuration method.
 */
export function LegacyOAuthAuthenticationOptionsFactory(oauthConfig: IConfiguration<IOAuthClientConfig>) {
    const config = oauthConfig.get();

    // Move over the minimum properties to support some changes to change password & helpdesk utl service
    const newConfig: IRedrowOAuthAuthenticationServiceOptions = {
        clientId: config.OAuthClient.clientId,
        apiUrls: undefined,
        appBuild: undefined,
        appRevision: undefined,
        appVersion: undefined,
        issuer: config.OAuthClient.issuer,
        loginType: undefined,
        scopes: undefined
    };
    return newConfig
}