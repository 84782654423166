import { IOAuthError } from "@redrow/utilities";


export function CreateOAuthError(eventType: string): IOAuthError {
	const e: IOAuthError = {
		name: eventType,
		isRecoverable: false,
	};

	if (eventType === "user_profile_load_error") {
		e.details = "Failed to load user profile.";
	} else if (eventType === "token_refresh_error") {
		e.details = "Failed to refresh token.";
	} else if (eventType === "token_validation_error") {
		e.details = "Invalid token.";
	} else if (eventType === "session_error") {
		e.details = "Session error.";
	}

	// Fallback to error name
	if (typeof e.details !== "string") {
		e.details = e.name;
	}

	return e;
}
