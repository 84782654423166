import { IAppConfiguration } from "@config/external-contractor-login-app.interface";
import { IConfiguration } from "@redrow/configuration";

export class BaseSubcontractorController {

	protected readonly config;

	constructor(protected readonly configMapper: IConfiguration<IAppConfiguration>) {
		this.config = configMapper.get();
	}

	protected url(path: string) {
		return `${this.config.apiUrl}${path}`;
	}

	protected urlWithId(path: string, id?: number) {
		if (typeof id === "number") {
			return this.url(`${path}/${id}`);
		}
		return this.url(path);
	}

}
