import { Injectable } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    IRedrowInternalLoginFormData,
    IRedrowInternalLoginFormService,
    IRedrowInternalLoginOptions,
    RedrowInternalLoginFormControlName,
} from "@redrow/utilities";


function setFormDisabledStateSafeControl(control: AbstractControl, newDisabled: boolean) {
    const disabled = control.disabled;
    if (newDisabled !== disabled) {
        if (newDisabled) {
            control.disable();
        }
        else {
            control.enable();
        }
    }
}

/**
 * Default implementation of redrow internal login form service.
 */
@Injectable({
    providedIn: "root"
})
export class RedrowInternalLoginFormService implements IRedrowInternalLoginFormService {

    constructor(protected readonly formBuilder: FormBuilder) { }

    public get(group: FormGroup): IRedrowInternalLoginFormData {
        if (!group) {
            return null;
        }

        return {
            ...group.value
        };
    }

    public control(group: FormGroup, controlName: RedrowInternalLoginFormControlName): AbstractControl {
        return group.get(controlName);
    }

    public setControlDisabled(group: FormGroup, controlName: RedrowInternalLoginFormControlName, newDisabled: boolean) {
        const c = this.control(group, controlName);
        if (c) {
            setFormDisabledStateSafeControl(c, newDisabled);
        }
    }

    public resetControl(group: FormGroup, controlName: RedrowInternalLoginFormControlName) {
        const c = this.control(group, controlName);
        if (c) {
            c.reset();
        }
    }

    public setControlValue(group: FormGroup, controlName: RedrowInternalLoginFormControlName, value: any) {
        const c = this.control(group, controlName);
        if (c) {
            c.setValue(value);
        }
    }

    public create(options: IRedrowInternalLoginOptions, defaultValues?: Partial<IRedrowInternalLoginFormData>): FormGroup {

        let controls: any = {
            username: [
                options.username,
                [
                    Validators.required
                ]
            ],
        };

        if (options.isLoggedIn) {
            controls.password = [
                "",
                []
            ];

            controls.divisionId = [
                null,
                [
                    Validators.required,
                    Validators.pattern(/[0-9]*/)
                ]
            ];

            controls.departmentCode = [
                null,
                [
                    Validators.required,
                    Validators.minLength(1)
                ]
            ];

            if (options.showOutlet) {
                controls.outletId = [
                    null,
                    [
                        Validators.required,
                        Validators.pattern(/[0-9]*/)
                    ]
                ];
            }


        } else {
            controls.password = [
                "",
                [
                    Validators.required
                ]
            ];
        }

        const g = this.formBuilder.group(controls);

        if (defaultValues) {
            g.patchValue(defaultValues);
        }

        return g;
    }

}
