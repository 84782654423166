import { Inject, Injectable, Optional } from "@angular/core";
import { ILogger } from "@redrow/utilities";

import { LogLevel } from "../../enums/log-level.enum";
import { ILoggerDestination } from "../../interfaces/logger-destination.interface";
import { ILoggerFilter } from "../../interfaces/logger-filter.interface";
import { LOGGER_CATEGORY } from "../../tokens/logger-category.token";
import { LOGGER_DESTINATION } from "../../tokens/logger-destination.token";
import { LOGGER_FILTER } from "../../tokens/logger-filter.token";

@Injectable()
export class DefaultLogger implements ILogger {
	private _loggers: ILoggerDestination[];
	private _filters: ILoggerFilter[];

	constructor(
		@Optional() @Inject(LOGGER_DESTINATION) protected readonly loggers: ILoggerDestination | ILoggerDestination[],
		@Optional() @Inject(LOGGER_FILTER) protected readonly filters: ILoggerFilter | ILoggerFilter[],
		@Optional() @Inject(LOGGER_CATEGORY) protected readonly category: string
	) {
		this._loggers = (Array.isArray(loggers) ? loggers : [loggers]) || [];
		this._filters = (Array.isArray(filters) ? filters : [filters]) || [];
	}

	protected filter(level: LogLevel, logger: ILoggerDestination, ...args: any[]) {
		for (const filter of this._filters) {
			if (filter && filter.shouldFilter(level, this.category, logger, args)) {
				return true;
			}
		}
		return false;
	}

	/**
	 * Internal function used to pass logged values to logger implementations.
	 */
	protected log(level: LogLevel, ...args: any[]): void {
		// Log using all providers
		for (const logger of this._loggers) {
			if (logger) {
				try {
					if (!this.filter(level, logger, args)) {
						logger.log(level, this.category, ...args);
					}
				} catch (e) {
					// Silently ignore logger errors
				}
			}
		}
	}

	//
	// Logger usage implementation:
	//

	verbose(...args: any[]) {
		return this.log(LogLevel.Verbose, ...args);
	}

	debug(...args: any[]) {
		return this.log(LogLevel.Debug, ...args);
	}

	info(...args: any[]) {
		return this.log(LogLevel.Info, ...args);
	}

	warn(...args: any[]) {
		return this.log(LogLevel.Warn, ...args);
	}

	error(...args: any[]) {
		return this.log(LogLevel.Error, ...args);
	}

	fatal(...args: any[]) {
		return this.log(LogLevel.Fatal, ...args);
	}
}
