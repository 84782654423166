import { Inject, Injectable } from "@angular/core";
import { IRedrowHelpdeskUrlService } from "@redrow/utilities";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";

import { IInternalOauthOpenIdConfiguration } from "../../private/interfaces/internal-oauth-openid-configuration.interface";
import { IOpenIdConfigurationRepository } from "../../private/interfaces/openid-configuration-repository.service";
import { OAuthOpenIdConfigurationService } from "../../private/services/oauth-openid-configuration.service";

/**
 * Provides an implementation of IRedrowHelpdeskUrlService which uses the oauth discovery document to get the helpdesk url
 */
@Injectable()
export class OAuthHelpdeskUrlService implements IRedrowHelpdeskUrlService {

    constructor(
        @Inject(OAuthOpenIdConfigurationService) protected readonly openIdConfigurationRepositoryService: IOpenIdConfigurationRepository<IInternalOauthOpenIdConfiguration>,
    ) {

    }
    get(): Observable<string> {
        return this.openIdConfigurationRepositoryService.observeOpenIdConfiguration().pipe(
            first(),
            map(doc => doc && doc.redrow ? doc.redrow.helpdeskUrl : null)
        );
    }

}