import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";

import { RedrowCanvasProviderService } from "./redrow-canvas-provider.service";

interface ICanvasState {
	canvas: HTMLCanvasElement;
	locked: boolean;
}

@Injectable({
	providedIn: "root"
})
export class RedrowCanvasManagerService {

	protected initCanvasState() {
		if (typeof window !== "undefined") {
			let s = window["__PoolCanvasManagerService_canvasState"] || {};
			window["__PoolCanvasManagerService_canvasState"] = s;
			return s;
		}
		return {};
	}

	constructor(protected readonly canvasProvider: RedrowCanvasProviderService) {
		// Store global state in window object
		this._canvasState = this.initCanvasState();
	}

	/**
	 * Key to canvas state pair
	 */
	protected readonly _canvasState: { [name: string]: ICanvasState };

	/**
	 * Get canvas state or create a new one if not exists
	 * @param name canvas state name
	 */
	protected _get(name: string): ICanvasState {
		if (name in this._canvasState) {
			return this._canvasState[name];
		}
		this._canvasState[name] = {
			canvas: this.canvasProvider.create(1, 1),
			locked: false
		};
		return this._canvasState[name];
	}

	/**
	 * Resolve canvas state after use
	 * @param name canvas state name
	 */
	unlock(name: string): void {
		const canvasState: ICanvasState = this._get(name);

		// ios memory fix
		canvasState.canvas.width = 1;
		canvasState.canvas.height = 1;
		canvasState.locked = false;
	}

	get(name: string, w: number = 1, h: number = 1, timeout: number = 2000): Observable<HTMLCanvasElement> {
		// NOTE: just converying promise to observable for now until this function is re-written
		return from(new Promise<HTMLCanvasElement>(
			(resolve, reject) => {

				// Initial get
				let tryGet: () => void = null;
				let cleanup: () => void = null;
				let i = null;
				let t = null;
				tryGet = () => {
					const canvas = this._get(name);
					if (!canvas.locked) {
						cleanup();

						canvas.canvas.width = w;
						canvas.canvas.height = h;
						canvas.locked = true;

						resolve(canvas.canvas);
						return;
					}
				};

				cleanup = () => {
					clearTimeout(t);
					clearInterval(i);
					t = undefined;
					i = undefined;
					tryGet = undefined;
					cleanup = undefined;
				};

				i = setInterval(tryGet, 10);
				t = setTimeout(() => {
					cleanup();
					reject(new Error("timeout"));
				}, timeout);

			}
		));
	}
}