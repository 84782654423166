import { Inject, Injectable } from "@angular/core";
import { IRedrowOAuthService, OAUTH_SERVICE } from "@redrow/utilities";
import { defer, Observable, of, Subject } from "rxjs";
import { retry, switchMap } from "rxjs/operators";

import { ILoadingScreenAction } from "../interfaces/loading-screen-action.interface";

@Injectable()
export class LoadingLoginLoaderService implements ILoadingScreenAction {
	constructor(@Inject(OAUTH_SERVICE) protected readonly oauthService: IRedrowOAuthService<any>) { }

	public execute(): Observable<boolean> {
		return defer(() => this.oauthService.init()).pipe(switchMap(x => this.login().pipe(retry(3))));
	}

	protected loginImpl(): Observable<boolean> {
		return new Observable<boolean>(observable => {

			const innerObservable = new Subject<void>();

			(async () => {
				try {
					const loggedIn = await this.oauthService.login({
						asyncCancelToken: innerObservable
					});

					if (!loggedIn) {
						throw new Error("Not logged in");
					}

					observable.next(
						loggedIn
					);
					observable.complete();
				} catch (e) {
					observable.error(e);
				}
			})();

			return {
				unsubscribe() {
					innerObservable.unsubscribe();
				}
			};

		});
	}

	protected login() {
		/**
		 * If we are already logged in, we can skip the login iframe
		 */
		if (this.oauthService.hasValidLogin()) {
			return of(true);
		}



		/**
		 * Otherwise start the login flow
		 */
		return this.loginImpl();
		// return defer(() => this.oauthService.login()).pipe(
		// 	switchMap(loggedIn => {
		// 		if (!loggedIn) {
		// 			return throwError(new Error("Not logged in"));
		// 		}
		// 		return of(true);
		// 	})
		// );
	}

	public getDisplayName(): string {
		return "Loading Log In...";
	}
}
