import { IOAuthTokenBase } from "@redrow/utilities";

import { JwtToken } from "./jwt-base.model";

export class OAuthTokenBase extends JwtToken implements IOAuthTokenBase {
	constructor(raw: any) {
		super(raw);
	}

	get sub() {
		return this._raw.sub;
	}

	get idp() {
		return this._raw.idp;
	}

	get iss() {
		return this._raw.iss;
	}

	get aud() {
		return this._raw.aud;
	}

	get amr() {
		return this._raw.amr;
	}

	get auth_time() {
		return new Date(this._raw.auth_time * 1000);
	}
}
