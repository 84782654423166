<!--

	ERROR SECTION

-->

<ng-container *ngIf="appError | async">
	<ng-container [ngSwitch]="appStateContext | async">
		<!-- Show an error if the browser is not supported -->
		<ng-container *ngSwitchCase="'browser_unsupported'">
			<ng-container *ngIf="isFeatureEnabled('checkForBrowserSupport', true)">
				<!-- <browser-support-error></browser-support-error> -->
				Browser not supported
			</ng-container>
		</ng-container>

		<!-- Show an error if the browser is offline -->
		<ng-container *ngSwitchCase="'browser_offline'">
			<ng-container *ngIf="isFeatureEnabled('checkForBrowserOffline', true)">
				<browser-offline-error></browser-offline-error>
			</ng-container>
		</ng-container>

		<!-- Fallback to generic error page -->
		<ng-container *ngSwitchDefault>
			<application-error [error]="appLastError | async"></application-error>
		</ng-container>
	</ng-container>
</ng-container>

<!-- Allow the user of this component to inject custom error components -->
<ng-content selector="error"></ng-content>

<!--

	APP SECTION

-->

<!-- Determine whether the app is ready to be displayed based on state !-->
<ng-container *ngIf="appReady | async">
	<ng-content selector="before-nav"></ng-content>

	<!-- App page content -->
	<ng-content></ng-content>
</ng-container>

<!-- Loading screen -->
<ng-container *ngIf="appLoading | async">
	<ng-container *ngIf="isFeatureEnabled('loadingScreen', false)">
		<rr-loading-screen></rr-loading-screen>
	</ng-container>
</ng-container>

<ng-content selector="below-shared-backdrop"></ng-content>
