<div class="error-wrapper">
	<div class="error-container">
		<rr-icon name="redrow-flower" [size]="50"></rr-icon>
		<h1>Whoops, something went wrong.</h1>
		<p *ngIf="isDev">{{ errorMessage }}</p>
		<p>
			Unfortunately something has gone wrong. Please retry your last action again. If you get this message consistently please contact our support team on the email address below including
			details of what you were trying to do.
		</p>
		<p>
			<a href="mailto:portalsupport@redrow.co.uk">portalsupport&#64;redrow.co.uk</a>
		</p>
		<button mat-stroked-button (click)="reloadApplication()">RELOAD</button>
	</div>
</div>
