import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { IThemeService } from "@redrow/utilities";

/**
 * A list of possible theme names for the DomThemeService.
 * Expected to be in lowercase like css properties.
 */
export const DOM_THEME_LIST = new InjectionToken<string[]>("DOM_THEME_LIST");

/**
 * This implementation of the theme service applies a CSS class to the html element with the theme name.
 * It expects a list of all the possible themes to be provided via the DOM_THEME_LIST injection token.
 */
@Injectable({
    providedIn: "root"
})
export class DomThemeService implements IThemeService {

    protected readonly document: Document;

    constructor(
        @Inject(DOCUMENT) private _document: any,
        @Inject(DOM_THEME_LIST) protected readonly themeList: string[]
    ) {
        this.document = _document;
    }

    protected getHtmlNode() {
        // Get all the html tags
        const htmlNodes = this.document.getElementsByTagName("HTML");

        // Null check
        if (htmlNodes.length === 0) return null;

        // Traverse the class list of the html tag until we find something we recognise.
        return htmlNodes[0];
    }

    protected getHtmlClassList(): string[] {
        const htmlNode = this.getHtmlNode();
        let outList: string[] = [];
        if (htmlNode) {
            for (let i = 0; i < htmlNode.classList.length; i++) {
                outList.push(htmlNode.classList[i]);
            }
        }
        return outList;
    }

    protected getActiveThemeList(): string[] {
        return this.getHtmlClassList().filter(x => this.isValidTheme(x));
    }

    protected isValidTheme(themeName: string) {
        return this.themeList.indexOf(themeName) > -1;
    }

    setTheme(themeName: string) {

        // Check theme name
        if (!this.isValidTheme(themeName)) return;

        // Remove any existing themes
        this.clearTheme();

        // Apply the theme
        this.addTheme(themeName);
    }
    getTheme(): string {
        const activeThemeList = this.getActiveThemeList();
        if (activeThemeList.length) {
            return activeThemeList[0];
        }

        // No theme
        return null;
    }

    addTheme(themeName: string) {
        // Check theme name
        if (!this.isValidTheme(themeName)) return;

        // Already active
        if (this.isThemeActive(themeName)) return;

        // Add the class to the DOM
        const htmlNode = this.getHtmlNode();
        htmlNode.classList.add(themeName);
    }

    removeTheme(themeName: string) {
        // Check theme name
        if (!this.isValidTheme(themeName)) return;

        // Add the class to the DOM
        const htmlNode = this.getHtmlNode();
        htmlNode.classList.remove(themeName);
    }

    isThemeActive(themeName: string) {
        return this.getThemes().indexOf(themeName) > -1;
    }

    getThemes() {
        return this.getActiveThemeList();
    }

    clearTheme() {
        for (const themeName of this.themeList) {
            this.removeTheme(themeName);
        }
    }

}