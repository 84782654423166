import { coerceNumberProperty } from "@angular/cdk/coercion";
import { Directive, HostListener, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from "@angular/core";
import {
    CanDisableCtor,
    coerceBooleanProperty,
    mixinCanDisable,
    mixinNgSubscriptions,
    NgSubscriptionsCtor,
} from "@redrow/utilities";
import { fromEvent, Observable, Subject } from "rxjs";
import { first, map } from "rxjs/operators";


class RedrowImportImageActionDirectiveBase {
    constructor() { }
}

const _RedrowImportImageActionDirectiveMixinBase: CanDisableCtor & NgSubscriptionsCtor & typeof RedrowImportImageActionDirectiveBase = mixinCanDisable(mixinNgSubscriptions(RedrowImportImageActionDirectiveBase));


/**
 * A directive which can be attached to a DOM element which imports a series of images in Blob format.
 * 
 * <ng-services-image-import-demo></ng-services-image-import-demo>
 */
@Directive({
    selector: "[import-image-action]",
    exportAs: "rrImportImageAction",
    inputs: [
        "disabled"
    ]

})
export class RedrowImportImageActionDirective extends _RedrowImportImageActionDirectiveMixinBase implements OnInit, OnChanges {

    @Input()
    public accept: string = "image/*";

    protected _multiple: boolean = false;

    @Input("multiple")
    set multipleSetter(newValue: string | boolean) {
        this._multiple = coerceBooleanProperty(newValue);
    }

    protected _maxNumImages: number = undefined;

    @Input("max-imports")
    set maxNumImagesSetter(newValue: string | number) {
        this._maxNumImages = coerceNumberProperty(newValue);
    }

    protected readonly _importedEvent: Subject<Blob[]> = new Subject<Blob[]>();
    private readonly _importedEventObserver: Observable<Blob[]> = this._importedEvent.asObservable();

    @Output()
    get imported(): Observable<Blob[]> {
        return this._importedEventObserver;
    }

    protected _inputElement: HTMLInputElement;

    constructor(
        protected readonly renderer: Renderer2
    ) {
        super();
    }

    ngOnInit() {
        this.reset();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateInputProperties();
    }

    public reset() {

        this.clearNgSubscriptions();

        this._inputElement = this.renderer.createElement("input");

        // Setup the input as a file input
        this._inputElement.type = "file";

        // Listen to the change event
        this.ngSubscriptions.add(
            fromEvent(this._inputElement, "change").pipe(

                // Split files into stream
                map(
                    e => {
                        // https://stackoverflow.com/questions/25333488/why-isnt-the-filelist-object-an-array
                        const files = Array.from(
                            (e.target as HTMLInputElement).files
                        );

                        // Limit output
                        if (this._multiple && typeof this._maxNumImages === "number") {
                            return files.slice(0, this._maxNumImages);
                        }

                        return files;
                    }
                ),

                // We only want one output from the change event
                first()

            )
                .subscribe({
                    // Emit the images as output
                    next: images => this._importedEvent.next(images),
                    complete: () => this.reset()
                })
        );

        this.updateInputProperties();

    }

    protected updateInputProperties() {
        if (!this._inputElement) return;

        // Create a sudo input element so we can click it to capture
        this._inputElement.accept = this.accept;
        this._inputElement.multiple = this._multiple;
    }

    @HostListener("click", ["$event"])
    public onHostClick(e: MouseEvent) {
        if (this.disabled) return;

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        // Click the input element
        this._inputElement.click();
    }


}