import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Optional } from "@angular/core";
import { ILogger } from "@redrow/utilities";
import { interval, Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { IFrameCreateOptions } from "../_deprecated/interfaces/iframe-create-options.interface";
import { OAUTH_LOGGER } from "../_deprecated/tokens/oauth-logger.token";

/**
 * Create an manage an iframe.
 */
@Injectable({
	providedIn: "root"
})
export class IFrameHandlerService {
	protected readonly document: Document;

	// https://github.com/angular/angular/issues/20351
	constructor(
		@Inject(DOCUMENT) private _document: any,
		@Optional() @Inject(OAUTH_LOGGER) protected readonly logger: ILogger
	) {
		this.document = _document;
	}

	protected opDebugLog(...args: any[]) {
		if (this.logger) {
			this.logger.debug(...args);
		}
	}

	protected opErrorLog(...args: any[]) {
		if (this.logger) {
			this.logger.error(...args);
		}
	}

	public create(uri: string, opts?: IFrameCreateOptions): HTMLIFrameElement {
		const el = this.document.createElement("iframe");

		if (opts && typeof opts.id === "string") {
			el.id = opts.id;
		}

		if (opts && opts.fullscreen === true) {
			el.style.position = "fixed";
			el.style.top = "0";
			el.style.left = "0";
			el.style.right = "0";
			el.style.bottom = "0";
			el.style.width = "100%";
			el.style.height = "100%";
			el.style.border = "0";
			el.style.margin = "0";
			el.style.padding = "0";
			el.style.overflow = "hidden";
			el.style.zIndex = "999999";
			el.style.backgroundColor = "white";
		}

		if (opts && opts.attributes) {
			for (const key of Object.keys(opts.attributes)) {
				el.setAttribute(key, opts.attributes[key]);
			}
		}

		el.setAttribute("src", uri);

		return el;
	}

	public createListen<T = string>(uri: string, expectedPrefix: string, opts?: IFrameCreateOptions): Observable<T> {
		return new Observable<T>(observable => {
			let sub: Subscription;
			let handle: HTMLIFrameElement;

			try {
				const data = this.listen<T>(expectedPrefix);

				handle = this.create(uri, opts);

				if (handle) {
					if (opts.hideUntilReady) {
						handle.style.opacity = "0";
					}

					handle.onload = event => {
						this.opDebugLog("LOAD", event);
					};
					handle.onerror = event => {
						this.opErrorLog("ERROR", event);
					};
					handle.onabort = event => {
						this.opDebugLog("ABORT", event);
					};
					(handle as any).oncancel = event => {
						this.opDebugLog("CANCEL", event);
					};
					handle.onprogress = event => {
						this.opDebugLog("PROGRESS", event);
					};

					// Check if window has closed
					handle.onclose = event => {
						observable.complete();
					};

					let timeoutSub;
					if (opts && typeof opts.timeout === "number" && opts.timeout > 0) {
						timeoutSub = interval(opts.timeout)
							.pipe(take(1))
							.subscribe(x => {
								observable.error(new Error("iframe timeout"));
								observable.complete();
							});
					}

					sub = data.subscribe(msg => {
						// Check for the alive message
						if (opts && typeof opts.timeout === "number" && opts.timeout > 0) {
							if (typeof msg === "string" && msg === "alive") {
								if (timeoutSub) {
									timeoutSub.unsubscribe();
									timeoutSub = undefined;
								}

								return;
							}
						}

						/**
						 * Show the iframe once it's ready to be used
						 */
						if (opts.hideUntilReady) {
							if (typeof msg === "string" && msg === "ready") {
								handle.style.opacity = "1";
								return;
							}
						}

						observable.next(msg);
					});

					// Attach iframe to body so it displays to the user
					this.document.body.appendChild(handle);
				} else {
					throw new Error("Could not create iframe element");
				}
			} catch (e) {
				observable.error(e);
			}

			return {
				unsubscribe() {
					if (handle) {
						handle.parentNode.removeChild(handle); // Supported by IE
						handle = undefined;
					}

					if (sub) {
						sub.unsubscribe();
						sub = undefined;
					}
				},
			};
		});
	}

	public listen<T = string>(expectedPrefix: string): Observable<T> {
		return new Observable(observable => {
			const eventListener = (e: MessageEvent) => {
				// Check for valid data
				if (!e || !e.data || typeof e.data !== "string") {
					return;
				}

				const prefixedMessage = e.data;

				// Ignore message - probably another app - or an attack
				if (!prefixedMessage.startsWith(expectedPrefix)) {
					return;
				}

				// Pass along the message
				try {
					observable.next(JSON.parse(prefixedMessage.substr(expectedPrefix.length)));
				} catch (e) {
					observable.error(e);
				}
			};

			// Start listening for messages
			window.addEventListener("message", eventListener);

			return {
				unsubscribe() {
					// Stop listening for messages
					window.removeEventListener("message", eventListener);
				},
			};
		});
	}
}
