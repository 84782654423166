import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";

import { GenericActionButtonBarComponent } from "./generic-action-button-bar.component";

const EXPORT_COMPONENTS = [
	GenericActionButtonBarComponent
];

@NgModule({

	imports: [
		CommonModule,
		MatButtonModule
	],

	declarations: [
		...EXPORT_COMPONENTS
	],

	exports: [
		...EXPORT_COMPONENTS
	]

})
export class GenericActionButtonBarModule { }
