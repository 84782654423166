import { InjectionToken } from "@angular/core";

/**
 * Part of the @redrow/oauth implementation is to compare values in the User Profile from /openid/userprofile endpoint
 * with values in the Identity Token to determine if a users permissions have changed since
 * being issued the access_token.
 * 
 * The set of tokens below exist to configure this behavior for future implementations of tokens, to keep things generic.
 */


/**
 * @deprecated Please see {@page ~~/wiki/solutions/authentication/oauth-quickstart.md} for how to configure OAuth.
 * Is the feature enabled?
 * When disabled the token will not attempt to refresh after a profile update
 */
export const REFRESH_TOKEN_CHECK_ENABLED = new InjectionToken<boolean>("REFRESH_TOKEN_CHECK_ENABLED");

/**
 * A list of keys which will not be compared - likely identity keys to do with expiration adn such
 * @deprecated Please see {@page ~~/wiki/solutions/authentication/oauth-quickstart.md} for how to configure OAuth.
 */
export const REFRESH_TOKEN_CHECK_BLACKLIST = new InjectionToken<string>("REFRESH_TOKEN_CHECK_BLACKLIST");

// Example profile response from Subby:
// BuildIssueApi: "15"
// PermissionManagementApi: "1"
// UserManagementApi: "127"
// WorkInstructionApi: "15"
// admin: "true"
// company: "fd5a9357-d28d-4877-ac44-a841c8813d10"
// email: "jack.penny@redrow.co.uk"
// id: "3"
// name: "Jack Penny"
// status: "4"
// sub: "jack.penny@redrow.co.uk"

// Example identity token for Subby:
// BuildIssueApi: "15"
// PermissionManagementApi: "1"
// UserManagementApi: "127"
// WorkInstructionApi: "15"
// admin: "true"
// amr: ["pwd"]
// at_hash: "pO4hpDz4orhrDhwC2cYHWQ"
// aud: "contractorsportal.redrow.co.uk"
// auth_time: 1591866410
// company: "fd5a9357-d28d-4877-ac44-a841c8813d10"
// email: "jack.penny@redrow.co.uk"
// exp: 1591879352
// iat: 1591879052
// id: "3"
// idp: "local"
// iss: "https://local-ms-contractor-oauth.redrow.co.uk"
// name: "Jack Penny"
// nbf: 1591879052
// status: "4"
// sub: "jack.penny@redrow.co.uk"