import { Component, Inject, OnInit } from "@angular/core";
import { RedrowBaseComponent } from "@redrow/material";
import { RedrowPlatformDetectionService } from "@redrow/ng-services";
import { IRedrowOAuthService, OAUTH_SERVICE } from "@redrow/utilities";
import { DashboardController } from "@shared/api/dashboard.controller";
import { IContractorProfile } from "@shared/oauth/contractor-profile.interface";
import { ErrorContext, UserMessageService } from "@shared/services/user-message-service.service";
import { forkJoin, iif, of, Subscription, throwError } from "rxjs";
import { concatMap, delay, first, retryWhen, switchMap, tap } from "rxjs/operators";

interface ITile {
	title: string;
	subTitle: string;
	content: string;
	content2: string;
	content3?: string;
	content4?: string;
	link?: string;
	icon?: string;
}

@Component({

	templateUrl: './dashboard.component.html',
	styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent extends RedrowBaseComponent implements OnInit {

	tiles: ITile[];
	tileSub: Subscription;

	desktop: boolean = false;
	handset: boolean = false;

	get cols(): number {
		if (this.handset) {
			return 1;
		}
		if (this.desktop) {
			return 4;
		}
		return 2;
	}

	constructor(
		protected readonly dashboardController: DashboardController,
		protected readonly platformDetectionService: RedrowPlatformDetectionService,
		@Inject(OAUTH_SERVICE) protected readonly oauthService: IRedrowOAuthService<IContractorProfile>,
		protected readonly userMessageService: UserMessageService) {
		super();

		this.ngSubscriptions.add(
			platformDetectionService.observePlatform().subscribe(x => {
				this.desktop = x === "desktop";
				this.handset = x === "mobile";
		}));

		this.ngSubscriptions.add(
			this.oauthService.getUserProfile().subscribe(
				user => this.checkForPermissionChangeAndRefresh(user)
			)
		);
	}

	checkForPermissionChangeAndRefresh(user: IContractorProfile) {

		// If we haven't displayed anything yet - ignore this check
		if (!this.user) {
			return;
		}

		// If we have, check if anything relevant changed
		if (this.user && user) {

			if (
				user.BuildIssueApi.list === this.user.BuildIssueApi.list
				&& user.WorkInstructionApi.list === this.user.WorkInstructionApi.list
			) {
				return;
			}

		}

		this.refresh();
	}

	user: IContractorProfile;

	getUser() {
		return this.oauthService.getUserProfile().pipe(
			first(),
			switchMap(user => {
				if (!user) {
					return throwError(new Error("No user available"));
				}
				return of(user);
			}),
			tap(user => this.user = user)
		).pipe(
			// Retry a few times - the user profile may not have loaded yet - up to 5 seconds
			retryWhen(errors => errors.pipe(
				concatMap(
					(e, i) => iif(
						() => i > 50,
						throwError(e),
						of(e).pipe(delay(50))
					)
				)
			))
		);
	}



	refresh() {
		// Stop any existing refresh
		if (this.tileSub && !this.tileSub.closed) {
			this.tileSub.unsubscribe();
		}

		this.tiles = undefined;
		this.loading = true;
		this.error = null;

		this.tileSub = forkJoin([
			this.getUser(),
			this.dashboardController.getPageData()
		]).pipe(

			// Retry a few times with delay - before failing
			retryWhen(errors => errors.pipe(
				concatMap(
					(e, i) => iif(
						() => i > 2,
						throwError(e),
						of(e).pipe(delay(1000))
					)
				)
			))

		).subscribe(
			([user, data]) => {

				this.tiles = [];

				const wic = data.workInstructionCount;
				const wicme = data.workInstructionsAssignedToMe;
				const wiar = data.workInstructionsRequiringAppointment;
				const workInstructionsNotYetOpened = data.workInstructionsNotYetOpened;
				const bic = data.buildIssueInstructionCount;
				const bicme = data.buildIssuesAssignedToMe;
				const biar = data.buildIssuesRequiringAppointment;
				const bipe = data.buildIssuesRequiringPhotoEvidence;
				const buildIssuesNotYetOpened = data.buildIssuesNotYetOpened;

				/**
				 * Build issue tile
				 */
				if (user.BuildIssueApi.list) {
					this.tiles.push(
						{
							icon: "icon-info",
							subTitle: "Build Issues",
							title: `${bic}`,
							content: typeof bicme === "number" ? `(${bicme} assigned to me)` : "-",
							content2: typeof buildIssuesNotYetOpened === "number" ? `${buildIssuesNotYetOpened} Issues Not Yet Opened` : "-",
							content3: typeof biar === "number" ? `${biar} Require Appointment date` : "-",
							content4: typeof bipe === "number" ? `${bipe} Require Photo Evidence` : "-",
							link: "/instructions/build"
						},
					);
				}

				/**
				 * Work instruction tile
				 */
				if (user.WorkInstructionApi.list) {
					this.tiles.push(

						{
							icon: "icon-info",
							subTitle: "Work Instructions",
							title: `${wic}`,
							content: typeof wicme === "number" ? `(${wicme} assigned to me)` : "-",
							content2: typeof workInstructionsNotYetOpened === "number" ? `${workInstructionsNotYetOpened} Instructions Not Yet Opened` : "-",
							content3: typeof wiar === "number" ? `${wiar} Require Appointment date` : "-",
							content4: "-",
							link: "/instructions/work"
						},
					);
				}



			},
			err => {
				this.error = `Failed to load dashboard tiles.`;
				this.loading = false;
				this.tileSub = undefined;

				this.ngSubscriptions.add(
					this.userMessageService.showErrorNotice(err, ErrorContext.LOADING).subscribe()
				);
			},
			() => {
				this.tileSub = undefined;
				this.loading = false;
			}
		);

		this.ngSubscriptions.add(
			this.tileSub
		);
	}

	ngOnInit() {

		this.refresh();

		// this.ngSubscriptions.add(
		// 	this.workInstruction.count().subscribe(x => this.workInstructionCount.next(x))
		// );

		// this.ngSubscriptions.add(
		// 	this.buildIssue.count().subscribe(x => this.buildIssueCount.next(x))
		// );

		// this.ngSubscriptions.add(
		// 	this.workInstruction.count({ assignedTo: ASSIGNED_TO_ME }).subscribe(x => this.workInstructionAssignedToMeCount.next(x))
		// );

		// this.ngSubscriptions.add(
		// 	this.buildIssue.count({ assignedTo: ASSIGNED_TO_ME }).subscribe(x => this.buildIssueAssignedToMeCount.next(x))
		// );
	}



}
