import { IOAuthIdentityToken } from "@redrow/utilities";

import { OAuthTokenBase } from "./oauth-token-base.model";


export class OAuthIdentityToken extends OAuthTokenBase implements IOAuthIdentityToken {
	constructor(raw: any) {
		super(raw);
	}

	get sid() {
		return this._raw.sid;
	}

	get nonce() {
		return this._raw.nonce;
	}

	get at_hash() {
		return this._raw.at_hash;
	}
}
