import { IWorkInstructionPermission } from "./work-instruction-permission.interface";

export class WorkInstructionPermission implements IWorkInstructionPermission {

	constructor(flags: number) {
		this.list = (flags & 1) === 1;
		this.update = (flags & 2) === 2;
		this.assignOthers = (flags & 4) === 4;
		this.assignSelf = (flags & 8) === 8;
	}

	list: boolean;
	update: boolean;
	assignSelf: boolean;
	assignOthers: boolean;

}
