
/**
 * Something has gone wrong during the iframe login flow.
 * The app should show an error screen if this occurs.
 */
export class RedrowOAuthAuthenticationIframeServiceError extends Error {
    constructor(public readonly innerError?: Error) {
        super("RedrowOAuthAuthenticationIframeServiceError");


        // restore prototype chain
        // https://stackoverflow.com/questions/41102060/typescript-extending-error-class
        const actualProto = new.target.prototype;

        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, actualProto);
        } else {
            (this as any).__proto__ = actualProto;
        }

        this.name = "RedrowOAuthAuthenticationIframeServiceError";
    }
}