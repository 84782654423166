import { Directive, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { IRedrowTextFilter, mixinNgSubscriptions, NgSubscriptionsCtor, REDROW_TEXT_FILTER } from "@redrow/utilities";
import { BehaviorSubject, Observable } from "rxjs";

class RedrowTextFilterDirectiveBase {
    constructor() { }
}

const _RedrowTextFilterDirectiveMixinBase: NgSubscriptionsCtor & typeof RedrowTextFilterDirectiveBase = mixinNgSubscriptions(RedrowTextFilterDirectiveBase);


/**
 * Generic data filter using a text query.
 * 
 * ```html
 * <mat-form-field appearance="outline" style="display: block">
        <mat-label>Select your company</mat-label>
        <mat-select [(ngModel)]="companyId" [rr-text-filter]="companyList" text-filter-on="name" #textFilter="rrTextFilter">
            <!-- Filter -->
            <rr-material-select-filter></rr-material-select-filter>

            <mat-option *ngFor="let company of textFilter.filteredData" [value]="company.id">{{ company.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    ```
 * 
 * 
 * Usage:
 * TODO
 */
@Directive({
    selector: "[rr-text-filter]",
    exportAs: "rrTextFilter",
    inputs: [

    ],
    host: {

    },
    providers: [
        {
            provide: REDROW_TEXT_FILTER,
            useExisting: forwardRef(() => RedrowTextFilterDirective)
        }
    ]
})
export class RedrowTextFilterDirective<SourceItemType = any> extends _RedrowTextFilterDirectiveMixinBase implements OnChanges, OnInit, IRedrowTextFilter {

    constructor() {
        super();

        this.queryText = this._queryText.asObservable();
    }


    /**
     * The source data.
     */
    @Input("rr-text-filter")
    sourceData: SourceItemType[];

    /**
     * Which field on the source items should be used when filtering?
     */
    @Input("text-filter-on")
    filterOnFieldName: string;

    /**
     * The query text to filter on
     */
    protected readonly _queryText: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    public readonly queryText: Observable<string>;



    @Output("filteredData")
    _filteredData: BehaviorSubject<SourceItemType[]> = new BehaviorSubject<SourceItemType[]>(undefined);

    public get filteredData(): SourceItemType[] {
        return this._filteredData.value;
    }


    ngOnChanges(changes: SimpleChanges) {
        // If the source data changes - we need to refresh the filter
        if ("sourceData" in changes) {
            this.applyTextFilter();
        }
    }

    ngOnInit() {
        this.ngSubscriptions.add(
            this._queryText.subscribe(
                x => this.applyTextFilter()
            )
        );
    }



    /**
     * Applies the active filter to the source data and emits the filtered data.
     */
    public applyTextFilter() {

        // Pass-thru
        if (
            !this.sourceData
            || !this._queryText.value
            || !this.filterOnFieldName
        ) {
            this._filteredData.next(this.sourceData);
            return;
        }

        const queryToLower: string = this._queryText.value.toLowerCase();

        // Filtering
        this._filteredData.next(
            this.sourceData.filter(
                item => {


                    const itemValue = item[this.filterOnFieldName];
                    const itemValueToLower = typeof itemValue === "string" ? itemValue.toLowerCase() : undefined;

                    if (itemValueToLower === undefined) return false;

                    return itemValueToLower.indexOf(queryToLower) > -1;

                }
            )
        );

    }


    public textFilterChange(): Observable<string> {
        return this.queryText;
    }

    getTextFilter(): string {
        return this._queryText.value;
    }
    setTextFilter(queryText: string): void {
        this._queryText.next(queryText);
    }
    resetTextFilter(): void {
        this._queryText.next(undefined);
    }


}