<div class="modal-title">
	<h1 mat-dialog-title>
		<ng-content select="[title]"></ng-content>
		<ng-container *ngIf="title">
			<p>{{ title }}</p>
		</ng-container>
	</h1>
	<div class="button-spacer"></div>
	<button mat-icon-button [mat-dialog-close]="true">
		<mat-icon svgIcon="cancel"></mat-icon>
	</button>
</div>

<div mat-dialog-content>
	<ng-content select="[content]"></ng-content>
	<ng-container *ngIf="contentLines">
		<p *ngFor="let line of contentLines">{{ line }}</p>
	</ng-container>
</div>
<div mat-dialog-actions>
	<div class="button-spacer"></div>
	<button [mat-dialog-close]="true" mat-stroked-button>OK</button>
</div>
