import { EnvironmentExternalOAuthConfig, IOAuthClientConfig } from "@redrow/oauth";
import { MakeApiUrl, RedrowEnvironmentType } from "@redrow/utilities";

import { API_URL } from "./api-url.constant";
import { AppConfigurationEnvironment } from "./external-contractor-login-app.interface";

const THIS_ENVIRONMENT: RedrowEnvironmentType = "Development";

export const CONFIGURATION_ENVIRONMENT: AppConfigurationEnvironment = {
	environment: THIS_ENVIRONMENT,
	apiUrl: MakeApiUrl(THIS_ENVIRONMENT, API_URL, true)
};

export const OAUTH_ENVIRONMENT: IOAuthClientConfig = EnvironmentExternalOAuthConfig(THIS_ENVIRONMENT);
