import { Constructor } from "../constructor.type";
import { ICanDisable } from "../disabled/can-disable.interface";
import { FormValueCtor } from "./form-value-ctor.type";

/** 
 * Mixin to add basic implementation of form value which can be used as ControlValueAccessor
 * **NOTE** Please refer to {@page ~~/wiki/best-practice/import-inferred-types.md} for a common gotcha when using this within the library.
 */
export function mixinFormValue<T extends Constructor<ICanDisable>, ValueType>(base: T): FormValueCtor<ValueType> & T {
    return class extends base {

        formValue: ValueType;

        _onFormValueTouched: () => void;
        _onFormValueChange: (newValue: ValueType) => void;


        setFormValue(newValue: ValueType): void {
            this.formValue = newValue;

            if (typeof (this._onFormValueChange) === "function") {
                this._onFormValueChange(newValue);
            }
        }

        touchFormValue(): void {
            if (typeof (this._onFormValueTouched) === "function") {
                this._onFormValueTouched();
            }
        }

        /// ControlValueAccessor
        writeValue(obj: ValueType): void {
            this.formValue = obj;
        }

        registerOnChange(fn: (newValue: ValueType) => void): void {
            this._onFormValueChange = fn;
        }
        registerOnTouched(fn: () => void): void {
            this._onFormValueTouched = fn;
        }
        setDisabledState(isDisabled: boolean): void {
            this.disabled = isDisabled;
        }

        constructor(...args: any[]) { super(...args); }
    };
}