import { Component, Inject, Input, OnDestroy, OnInit, Optional } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";
import { Subscription } from "rxjs";

import { CheckboxSlideToggleComponentType } from "./checkbox-slide-toggle-component-type.enum";
import { ICheckboxSlideToggleTypeResolverService } from "./checkbox-slide-toggle-type-resolver-service.interface";
import { CHECKBOX_SLIDE_TOGGLE_TYPE_RESOLVER_SERVICE } from "./checkbox-slide-toggle-type-resolver-service.token";

/**
 * This component will switch between the mat-checkbox and mat-slide-toggle component based on the `type` input.
 * 
 * The intended use case for this component is to switch between different checkbox styles, showing the slide toggle when the user is on mobile
 * and checkbox when the user is on desktop.
 */
@Component({
	selector: "rr-checkbox-slide-toggle",
	templateUrl: "./checkbox-slide-toggle.component.html",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: CheckboxSlideToggleComponent,
		},
	],
})
export class CheckboxSlideToggleComponent implements ControlValueAccessor, OnInit, OnDestroy {

	protected _sub: Subscription = new Subscription();

	constructor(
		@Optional() @Inject(CHECKBOX_SLIDE_TOGGLE_TYPE_RESOLVER_SERVICE) protected readonly _typeResolver: ICheckboxSlideToggleTypeResolverService
	) { }

	ngOnInit() {
		if (this._typeResolver) {
			this._sub.add(
				this._typeResolver.resolveType.subscribe(type => this.type = type)
			);
		}
	}

	ngOnDestroy() {
		if (this._sub) {
			if (!this._sub.closed) {
				this._sub.unsubscribe();
			}
			this._sub = undefined;
		}
	}

	public _checkbox: CheckboxSlideToggleComponentType = CheckboxSlideToggleComponentType.CHECKBOX;
	public _slideToggle: CheckboxSlideToggleComponentType = CheckboxSlideToggleComponentType.SLIDE_TOGGLE;

	@Input()
	type: CheckboxSlideToggleComponentType = CheckboxSlideToggleComponentType.CHECKBOX;

	@Input()
	value: boolean;

	@Input()
	color: ThemePalette = "primary"; // Material colours i.e. primary, accent, warn

	@Input()
	disabled: boolean;

	@Input()
	id: string;

	@Input()
	name: string;

	@Input()
	labelPosition: "before" | "after" = "after";

	@Input()
	required: boolean;

	@Input()
	disableRipple: boolean;

	@Input()
	checked: boolean;

	@Input('aria-label')
	ariaLabel: string | null;

	@Input('aria-labelledby')
	ariaLabelledby: string | null;

	@Input('aria-describedby')
	ariaDescribedby: string

	public _inputChange() {
		this.change();
	}

	protected change() {
		if (typeof this._onChange === "function") {
			this._onChange(this.value);
		}
	}

	protected _onChange: (newValue: boolean) => void;


	writeValue(obj: boolean): void {
		this.value = obj;
	}
	registerOnChange(fn: (newValue: boolean) => void): void {
		this._onChange = fn;
	}
	registerOnTouched(fn: any): void {
		// Unused
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}




}

