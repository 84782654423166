import { Directive, HostListener, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from "@angular/core";
import { CanDisableCtor, mixinCanDisable, mixinNgSubscriptions, NgSubscriptionsCtor } from "@redrow/utilities";
import { fromEvent, Observable, Subject } from "rxjs";
import { first, map } from "rxjs/operators";


class RedrowCaptureImageActionDirectiveBase {
    constructor() { }
}

const _RedrowCaptureImageActionDirectiveMixinBase: CanDisableCtor & NgSubscriptionsCtor & typeof RedrowCaptureImageActionDirectiveBase = mixinCanDisable(mixinNgSubscriptions(RedrowCaptureImageActionDirectiveBase));


/**
 * A directive which can be attached to a DOM element which captures an image from the camera and emits the blob as an event.
 * 
 * <ng-services-image-import-demo></ng-services-image-import-demo>
 */
@Directive({
    selector: "[capture-image-action]",
    exportAs: "rrCaptureImageAction",
    inputs: [
        "disabled"
    ]

})
export class RedrowCaptureImageActionDirective extends _RedrowCaptureImageActionDirectiveMixinBase implements OnInit, OnChanges {

    @Input()
    public accept: string = "image/*";

    protected readonly _importedEvent: Subject<Blob> = new Subject<Blob>();
    private readonly _importedEventObserver: Observable<Blob> = this._importedEvent.asObservable();

    @Output()
    get imported(): Observable<Blob> {
        return this._importedEventObserver;
    }

    protected _inputElement: HTMLInputElement;

    constructor(
        protected readonly renderer: Renderer2
    ) {
        super();
        // this.document = _document;

    }

    ngOnInit() {
        this.reset();
    }

    reset() {
        this.clearNgSubscriptions();

        this._inputElement = this.renderer.createElement("input");

        // Setup the input as a file input
        this._inputElement.type = "file";

        // We want to capture from the camera only
        this._inputElement.setAttribute("capture", "camera");

        // Listen to the change event
        this.ngSubscriptions.add(
            fromEvent(this._inputElement, "change").pipe(

                // Split files into stream
                map(
                    e => (e.target as HTMLInputElement).files[0]
                ),

                // We only want one image
                first(),


            ).subscribe({
                // Emit the images as output
                next: image => this._importedEvent.next(image),
                complete: () => this.reset()
            })
        );

        this.updateInputProperties();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateInputProperties();
    }

    protected updateInputProperties() {
        if (!this._inputElement) return;

        // Create a sudo input element so we can click it to capture
        this._inputElement.accept = this.accept;
    }

    @HostListener("click", ["$event"])
    public onHostClick(e: MouseEvent) {
        if (this.disabled) return;

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        // Click the input element
        this._inputElement.click();
    }


}