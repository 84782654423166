import { ChangeDetectorRef, Directive, Inject, InjectionToken, Input, Optional } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
    CanLoadCtor,
    ILogger,
    IRedrowInternalLoginApplication,
    IRedrowInternalLoginApplicationRepository,
    IRedrowInternalLoginDepartment,
    IRedrowInternalLoginDepartmentRepository,
    IRedrowInternalLoginDivision,
    IRedrowInternalLoginDivisionRepository,
    IRedrowInternalLoginFormService,
    IRedrowInternalLoginOptions,
    IRedrowInternalLoginOutlet,
    IRedrowInternalLoginOutletRepository,
    IRedrowInternalLoginService,
    mixinCanLoad,
    mixinNgSubscriptions,
    mixinOptionalLogging,
    NgSubscriptionsCtor,
    OptionalLoggingCtor,
    REDROW_INTERNAL_LOGIN_APPLICATION_REPOSITORY,
    REDROW_INTERNAL_LOGIN_DEPARTMENT_REPOSITORY,
    REDROW_INTERNAL_LOGIN_DIVISION_REPOSITORY,
    REDROW_INTERNAL_LOGIN_FORM_SERVICE,
    REDROW_INTERNAL_LOGIN_OUTLET_REPOSITORY,
    REDROW_INTERNAL_LOGIN_SERVICE,
} from "@redrow/utilities";
import { forkJoin, Observable, of, Subscription, throwError, timer } from "rxjs";
import { map, pairwise, startWith, switchMap, tap } from "rxjs/operators";

class RedrowLoginDirectiveBase {
    constructor() { }
}

const _RedrowLoginDirectiveComponentMixinBase: OptionalLoggingCtor & CanLoadCtor & NgSubscriptionsCtor & typeof RedrowLoginDirectiveBase = mixinOptionalLogging(mixinCanLoad(mixinNgSubscriptions(RedrowLoginDirectiveBase)));



export const REDROW_INTERNAL_LOGIN_LOGGER = new InjectionToken<ILogger>("REDROW_INTERNAL_LOGIN_LOGGER");


/**
 * This directive tries to achieve shared functionality for the internal redrow login, so no matter the "component" it should function correctly.
 * 
 * See {@link @redrow/ng-redlibrary-components!RedrowLoginComponent} and {@link @redrow/ng-material-components!RedrowMaterialLoginComponent}
 */
@Directive({
    selector: "[rr-internal-login]",
    exportAs: "rrInternalLogin",
    inputs: [

    ],
    host: {

    },
    providers: [

    ]
})
export class RedrowInternalLoginDirective extends _RedrowLoginDirectiveComponentMixinBase {

    constructor(

        @Inject(REDROW_INTERNAL_LOGIN_FORM_SERVICE) protected readonly formService: IRedrowInternalLoginFormService,
        @Inject(REDROW_INTERNAL_LOGIN_SERVICE) protected readonly loginService: IRedrowInternalLoginService,
        @Inject(REDROW_INTERNAL_LOGIN_DEPARTMENT_REPOSITORY) protected readonly departmentRepository: IRedrowInternalLoginDepartmentRepository,
        @Inject(REDROW_INTERNAL_LOGIN_DIVISION_REPOSITORY) protected readonly divisionRepository: IRedrowInternalLoginDivisionRepository,
        @Inject(REDROW_INTERNAL_LOGIN_OUTLET_REPOSITORY) protected readonly outletRepository: IRedrowInternalLoginOutletRepository,
        @Optional() @Inject(REDROW_INTERNAL_LOGIN_APPLICATION_REPOSITORY) protected readonly applicationRepository: IRedrowInternalLoginApplicationRepository,
        @Optional() @Inject(REDROW_INTERNAL_LOGIN_LOGGER) protected readonly logger: ILogger,
        protected readonly cd: ChangeDetectorRef
    ) {
        super();
    }

    public findDivision(id: number) {
        return this.divisions.find(x => x.id === id);
    }

    public findDepartment(code: string) {
        return this.departments.find(x => x.code === code);
    }

    public findOutlet(id: number) {
        return this.outlets.find(x => x.id === id);
    }

    errorMessage: string;

    get showPasswordField(): boolean {
        return !this.hasLoggedIn;
    }

    get disableUsernameField(): boolean {
        return this.hasLoggedIn;
    }

    showLoginForm: boolean = true;

    hasLoggedIn: boolean;
    hideLoginButton: boolean;

    form: FormGroup;

    formSub: Subscription;



    protected setupForm() {
        if (this.formSub && !this.formSub.closed) {
            this.formSub.unsubscribe();
        }

        this.optDebug("setupForm");

        this.form = this.formService.create({
            ...this.options,
            isLoggedIn: this.hasLoggedIn
        }, this.formService.get(this.form));

        this.refreshForm();

        // We only need to listen for changes if we ave logged in
        if (!this.hasLoggedIn) return;

        this.formSub = new Subscription();

        this.formSub.add(
            this.form.get("divisionId")
                .valueChanges
                .pipe(
                    startWith<number>(null),
                    pairwise()
                )
                .subscribe(
                    ([oldValue, newValue]) => {
                        if (this.form.disabled) return;
                        if (oldValue === newValue) return;

                        this.optDebug(`divisionId`, newValue);

                        this._loadMoreData(
                            timer(1).pipe(
                                tap(() => {
                                    // Clear data
                                    this.departments = [];
                                    this.outlets = [];

                                    // Reset inputs
                                    this.formService.resetControl(this.form, "departmentCode");
                                    this.formService.resetControl(this.form, "outletId");
                                }),

                                // Get new department data
                                switchMap(() => this._resolveDepartmentData())
                            )
                        );
                    }
                )
        );

        this.formSub.add(
            this.form.get("departmentCode")
                .valueChanges
                .pipe(
                    startWith<string>(null),
                    pairwise()
                )
                .subscribe(
                    ([oldValue, newValue]) => {
                        if (this.form.disabled) return;
                        if (oldValue === newValue) return;

                        this.optDebug(`departmentCode`, newValue);

                        this._loadMoreData(
                            timer(1).pipe(
                                tap(() => {
                                    this.outlets = [];
                                    this.formService.resetControl(this.form, "outletId");
                                }),
                                switchMap(() => this._resolveOutletData())
                            )
                        );
                    }
                )
        );


        this.ngSubscriptions.add(this.formSub);
    }

    protected refreshForm() {
        if (!this.form) return;

        this.formService.setControlDisabled(this.form, "username", this.hasLoggedIn);
    }


    cleared(field) {
        // Clear the password if the username gets cleared
        if (field === "username") {
            this.formService.setControlValue(this.form, "password", "");
        }
    }

    @Input("app")
    app: IRedrowInternalLoginApplication;






    divisions: IRedrowInternalLoginDivision[];
    departments: IRedrowInternalLoginDepartment[];
    outlets: IRedrowInternalLoginOutlet[];

    protected _loadMoreData<T>(obs: Observable<T>) {
        this.optVerb("disableForm");
        this.form.disable();
        this.cd.detectChanges();

        return obs.subscribe(
            {
                complete: () => {
                    this.form.enable();
                    this.refreshForm();
                    this.cd.detectChanges();
                    this.optVerb("enableForm");
                }
            }
        );
    }

    protected _loadSomething<T>(obs: Observable<T>) {
        this.loading = true;
        this.cd.detectChanges();

        this.ngSubscriptions.add(
            obs.subscribe(
                () => {

                    this.loading = false;
                    this.cd.detectChanges();
                },
                (err: Error) => {
                    this.optError(err);

                    this.errorMessage = err.message || "Something went wrong, please try again."
                    this.loading = false;

                    if (!this.hasLoggedIn) {
                        if (this.form) { // Form might not be ready yet - if the error happens during ngOnInit
                            this.formService.setControlValue(this.form, "username", "");
                            this.formService.setControlValue(this.form, "password", "");
                        }
                    }

                    this.cd.detectChanges();
                }
            )
        );
    }

    get minRequiredDataToShow(): number {
        if (this.options && this.options.autoSelectSingleValues) {
            return 2;
        }
        return 1;
    }

    public options: IRedrowInternalLoginOptions;

    protected _optionallyLoadApplicationFromRepository() {
        if (this.applicationRepository) {
            return this.applicationRepository.getApplication();
        }
        return of(this.app);
    }

    ngOnInit() {

        this._loadSomething(

            forkJoin([
                this._optionallyLoadApplicationFromRepository(),
                this.loginService.getOptions()
            ]).pipe(

                map(
                    ([app, opts]) => {
                        this.app = app;
                        return opts;
                    }
                ),

                tap(opts => this.options = opts),
                tap(() => {
                    this.hasLoggedIn = this.options.isLoggedIn;

                    this.setupForm();
                }),

                // Resolve division data if we're already logged in
                switchMap(() => {
                    if (this.hasLoggedIn) {
                        return this._resolveDivisionData();
                    }
                    return of(true);
                })
            )
        );

    }

    protected _resolveOutletData() {
        if (this.options.showOutlet) {
            const formInput = this.formService.get(this.form);
            return this.outletRepository.getFilteredOutletData(
                formInput.divisionId,
                formInput.departmentCode
            ).pipe(
                tap(
                    data => {
                        this.outlets = data;

                        if (this.outlets) {
                            this.optInfo(`Resolved ${this.outlets.length} outlets`);
                        }

                        if (this.options.autoSelectSingleValues && this.outlets.length === 1) {
                            this.formService.setControlValue(this.form, "outletId", this.outlets[0].id);
                        }
                    }
                )
            );
        }
        return of(true);
    }

    protected _resolveDepartmentData() {
        return this.departmentRepository.getFilteredDepartmentData(
            this.formService.get(this.form).divisionId
        ).pipe(
            switchMap(data => {
                this.departments = data;

                if (this.departments) {
                    this.optInfo(`Resolved ${this.departments.length} departments`);
                }

                if (this.options.autoSelectSingleValues && this.departments.length === 1) {
                    this.formService.setControlValue(this.form, "departmentCode", this.departments[0].code);
                    return this._resolveOutletData();
                }

                return of(true);
            })
        );
    }

    protected _resolveDivisionData() {
        return this.divisionRepository.getDivisionData().pipe(
            switchMap(data => {
                this.divisions = data;

                if (this.divisions) {
                    this.optInfo(`Resolved ${this.divisions.length} divisions`);
                }

                if (this.options.autoSelectSingleValues && this.divisions.length === 1) {
                    this.formService.setControlValue(this.form, "divisionId", this.divisions[0].id);
                    return this._resolveDepartmentData();
                }

                return of(true);
            })
        );
    }

    protected _loginUsernamePassword() {
        return this.loginService.login(
            this.formService.get(this.form)
        ).pipe(
            switchMap(res => {
                if (res.success) {
                    this.hasLoggedIn = true;
                    this.setupForm();
                    this.refreshForm();

                    return this._resolveDivisionData();
                } else {
                    return throwError(new Error(res.errorMessage));
                }
            })
        );
    }

    protected _loginDivision() {

        // re-enable username field so it gets picked up in data grab
        this.formService.setControlDisabled(this.form, "username", false);

        const formData = this.formService.get(this.form);

        // Return to state before this
        this.refreshForm();

        return this.loginService.login(
            formData
        ).pipe(
            switchMap(res => {
                if (res.success) {

                    // Logged into division!
                    return of(true);

                } else {
                    return throwError(new Error(res.errorMessage));
                }
            })
        );
    }

    public onLoginSubmit() {

        if (this.loading) return;

        this.errorMessage = null;

        this.optVerb("onLoginSubmit");

        // If the user has already logged in - then they are selecting division
        if (this.hasLoggedIn) {
            this.optInfo(`Login Division`);
            this._loadSomething(
                this._loginDivision()
            );
        } else {
            this.optInfo(`Login Username & Password`);
            this._loadSomething(
                this._loginUsernamePassword()
            );
        }

    }

}

