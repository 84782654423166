export enum InstructionStatus {
	AppointmentRequired = 1,
	AppointmentMade = 2,

	Completed = 4,

	AwaitingResponse = 9,
	ContactAttempted = 10,
	AwaitingParts = 11,

	Unknown = 12
}
