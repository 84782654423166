<!-- Page content -->
<ip-page-shell [loading]="loading" [error]="error">
	<div class="hero-tile-container" [class.desktop]="desktop" [class.handset]="handset">
		<div class="hero-tile">
			<figure class="best-practice" routerLink="/help/bestpractice"></figure>
		</div>
		<div class="hero-tile">
			<figure class="how-to-use" routerLink="/help/howtouse"></figure>
		</div>
	</div>

	<div class="dashboard-tile-container" [class.desktop]="desktop">
		<div dashboard-tile *ngFor="let tile of tiles">
			<mat-card appearance="outlined" class="dashboard-card mat-card-flat">
				<mat-card-header>
					<mat-card-title>{{ tile.title }}</mat-card-title>
					<mat-card-subtitle>{{ tile.subTitle }}</mat-card-subtitle>

					<div class="button-spacer"></div>
					<a *ngIf="desktop" [disabled]="!tile.link" mat-stroked-button routerLink="{{ tile.link }}">Open {{ tile.subTitle }}</a>
				</mat-card-header>
				<mat-divider></mat-divider>
				<mat-card-content class="dashboard-tile-content">
					<p [style.opacity]="tile.content === '-' ? 0 : 1">
						{{ tile.content }}
					</p>
					<p [style.opacity]="tile.content2 === '-' ? 0 : 1">
						<a [routerLink]="[tile.link]" [queryParams]="{f:1, notYetOpened:1}" 
							[highlightUnopened]="tile.content2"
							#highlightUnopened="highlightUnopened" 
							[class.highlight-text]="highlightUnopened.state"
							style="text-decoration: none; color: unset">
							{{ tile.content2 }}
						</a>
					</p>
					<p [style.opacity]="tile.content3 === '-' ? 0 : 1">
						{{ tile.content3 }}
					</p>
					<p [style.opacity]="tile.content4 === '-' ? 0 : 1">
						{{ tile.content4 }}
					</p>
				</mat-card-content>
				<mat-card-actions *ngIf="!desktop">
					<a [disabled]="!tile.link" mat-stroked-button routerLink="{{ tile.link }}">Open {{ tile.subTitle }}</a>
				</mat-card-actions>
			</mat-card>
		</div>
	</div>
</ip-page-shell>
