import { MakeApiUrl, RedrowEnvironmentType } from "@redrow/utilities";
import { AuthConfig } from "angular-oauth2-oidc";

import { IOAuthClientConfig } from "../interfaces/application-config-with-oauth.interface";
import { BaseOAuthCOnfig, EnvironmentOAuthConfig } from "./oauth-standard-config.function";

/**
 * Setup config for external oauth.
 * Used in Subcontractor Portal.
 * @deprecated Please see {@page ~~/wiki/solutions/authentication/oauth-quickstart.md} for how to configure OAuth.
 */
export function BaseExternalOAuthConfig(customAuthConfig: Partial<AuthConfig>, profileScope: string = "contractor.profile"): IOAuthClientConfig {
    return BaseOAuthCOnfig({
        ...customAuthConfig,

        // Combine scopes
        // Remove internal profile
        // Add external profile
        scope: `-redrow.profile ${profileScope}${("scope" in customAuthConfig ? " " + customAuthConfig.scope : "")}`,
    });
}

export function EnvironmentExternalOAuthConfig(env: RedrowEnvironmentType, customAuthConfig?: Partial<AuthConfig>): IOAuthClientConfig {
    return EnvironmentOAuthConfig(env, {

        issuer: MakeApiUrl(env, "ms-oauth-external.redrow.co.uk", true),

        ...customAuthConfig

    });
}
