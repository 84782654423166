import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ILogger } from "@redrow/utilities";

import { OAUTH_LOGGER } from "../../../../private/_deprecated/tokens/oauth-logger.token";
import { OAuthService } from "../../services/oauth.service";



/**
 * Used in Subcontractor Portal.
 * @deprecated Please see {@page ~~/wiki/solutions/authentication/oauth-quickstart.md} for how to configure OAuth.
 */
@Component({
	selector: "rr-oauth-screen",
	templateUrl: "./oauth.component.html",
	styleUrls: ["./oauth.component.scss"],
})
export class OAuthComponent implements OnInit {
	constructor(protected readonly oauthService: OAuthService, protected readonly activatedRoute: ActivatedRoute, protected readonly router: Router, @Inject(OAUTH_LOGGER) protected readonly logger: ILogger) { }

	public status = "";

	protected async loginFlow(returnUrl: string, maxTries: number = 3, tries: number = 0, lastError: any = null) {
		tries++;

		// Too many tries - failed
		if (tries > maxTries) {
			throw lastError;
		}

		// Default to login action
		status = `Logging in (attempt ${tries})...`;

		await this.oauthService.init();

		try {
			const loggedIn = await this.oauthService.login();
			this.logger.info(`loggedIn: ${loggedIn}`);

			// If we are logged in - return to pre-authenticated url or postoauth
			if (loggedIn) {
				this.router.navigate([returnUrl]);
			}
		} catch (e) {
			// try again
			return this.loginFlow(returnUrl, maxTries, tries, e);
		}
	}

	async ngOnInit() {
		// Read from snapshot data or route param :action
		const action = this.activatedRoute.snapshot.data.action || this.activatedRoute.snapshot.paramMap.get("action");

		const returnUrl = this.activatedRoute.snapshot.queryParams["returnUrl"] || "/postoauth";

		this.logger.debug(`oauth action ${action}`);

		// Logout action
		if (action === "logout") {
			status = "Logging out...";
			this.logger.info(`Logout action initated`);
			this.oauthService.logout();
			return;
		}

		// Login action
		if (action === "login" || action === "callback") {
			try {
				await this.loginFlow(returnUrl);
			} catch (e) {
				this.logger.error(`Failed to login`, e);
				this.router.navigate(["/error"], {
					state: {
						error: "The Logon Server is currently unavailable, sorry for any inconvenience and try again later.",
					},
				});
			}

			return;
		}

		status = "Error";

		throw new Error(`Unknown or missing action '${action}' in OAuth component. Pass 'login' action to login`);
	}
}
