import { OAuthService } from "../../../public/_deprecated/services/oauth.service";

/**
 * Forward compatibility for ANGULAR_OAUTH_OIDC_SERVICE token by looking into the legacy OAuthService
 * @param oauthService 
 * @returns 
 */
export function LegacyAngularOauthOidcServiceFactory(oauthService: OAuthService) {
    return oauthService["AngularOAuthOpenIdService"];
}

