import { Directive, Input, OnDestroy, OnInit } from "@angular/core";
import { LoadingService } from "@shared/services/loading.service";

@Directive({
	selector: '[ipLoading]'
})
export class LoadingDirective implements OnInit, OnDestroy {

	@Input("ipLoading")
	loaderName: string = "default";

	constructor(protected readonly loadingService: LoadingService) { }

	ngOnInit() {
		this.loadingService.startLoading(this.loaderName);
	}

	ngOnDestroy() {
		this.loadingService.stopLoading(this.loaderName);
	}

}
