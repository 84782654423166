import { coerceBooleanProperty } from "@redrow/utilities";

import { ImageResizeCalcFn } from "./image-resize-calc-function.type";


/**
 * Helper function for the IImageResizeBlobOptions.calcDimensionFn property.
 * 
 * If an image is too large it will be resized to fit maxWidth and/or maxHeight. 
 * Optionally maintaining aspect ratio.
 * @param maxWidth 
 * @param maxHeight 
 * @param maintainAspectRatio 
 * @returns 
 */
export function restrictImageSize(
	options: {
		maxWidth: number,
		maxHeight?: number,
		maintainAspectRatio?: boolean
	} | {
		maxWidth?: number,
		maxHeight: number,
		maintainAspectRatio?: boolean
	} | {
		maxWidth: number,
		maxHeight: number,
		maintainAspectRatio?: boolean
	}
): ImageResizeCalcFn {
	return (w: number, h: number) => {

		let transformedWidth = w;
		let transformedHeight = h;
		let maintainAspectRatio: boolean = true;

		// Accept string values
		if (options.maintainAspectRatio !== undefined && options.maintainAspectRatio !== null) {
			maintainAspectRatio = coerceBooleanProperty(options.maintainAspectRatio);
		}


		// Handle max width
		if (options.maxWidth) {
			// Set max width if needed
			if (transformedWidth > options.maxWidth) {

				transformedWidth = options.maxWidth;

				// Update height if we need to maintain aspect ratio
				if (maintainAspectRatio) {
					transformedHeight = transformedWidth * (h / w);
				}
			}
		}

		// Handle max height
		if (options.maxHeight) {
			// Set max height if needed
			if (transformedHeight > options.maxHeight) {

				/**
				 * Note: we might have already reduced the transformed height due to width at this stage, but height might still be too large.
				 */
				transformedHeight = options.maxHeight;

				// Update width if we need to maintain aspect ratio
				if (maintainAspectRatio) {
					transformedWidth = transformedHeight * (w / h);
				}
			}
		}

		// Return transformed widths and heights
		return {
			width: transformedWidth,
			height: transformedHeight
		};

	};
}

