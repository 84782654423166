import { merge } from "lodash-es";
import { MergeConfiguration } from "../types/configuration-merge.type";

/**
 * Deep merge collection of configurations in to single object
 * @param configuration Configuration to deep merge
 * @returns Object of type {@link TConfiguration}
 */
export function deepMergeConfiguration<TConfiguration, T1, T2 = undefined, T3 = undefined, T4 = undefined>(
	configuration: MergeConfiguration<TConfiguration, T1, T2, T3, T4>
): TConfiguration {
	if (!Array.isArray(configuration)) return null;
	if (configuration.length === 1) return configuration[0];
	// Merge all configurations into one object
	return configuration.reduce((a, b) => merge(a, b), null) as TConfiguration;
}
