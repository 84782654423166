import { NgModule } from "@angular/core";
import { MatChipsModule } from "@angular/material/chips";

const imports = [

	MatChipsModule

];

@NgModule({

	imports: [
		...imports
	],
	exports: [
		...imports
	]

})
export class SharedMaterialImportsModule { }
