import { DOCUMENT } from "@angular/common";
import { Directive, ElementRef, Host, Inject, OnChanges, Output, SimpleChanges } from "@angular/core";
import { CanDisableCtor, mixinCanDisable, mixinNgSubscriptions, NgSubscriptionsCtor } from "@redrow/utilities";
import { fromEvent, Observable, Subject, Subscription } from "rxjs";

import { RedrowPointerMoveType } from "./enums/pointer-move-type.enum";
import { IRedrowPointerMove } from "./interfaces/pointer-move.interface";

export class RedrowPointerMoveBase {
    constructor() { }
}

/**
 * Setup a mixin for the button so it can inherit common functionality.
 */
const _RedrowPointerMoveMixinBase: NgSubscriptionsCtor & CanDisableCtor & typeof RedrowPointerMoveBase = mixinNgSubscriptions(mixinCanDisable(RedrowPointerMoveBase));

/**
 * This directive is to be used for handling events touch and mouse move.
 * 
 * Touch events need testing!
 * 
 * <ng-services-pointer-move-demo></ng-services-pointer-move-demo>
 */
@Directive({
    selector: "[rr-pointer-move]",
    exportAs: "rrPointerMove",
    inputs: [
        "disabled"
    ],
    host: {},
    providers: []
})
export class RedrowPointerMoveDirective extends _RedrowPointerMoveMixinBase implements OnChanges {

    protected onMouseDown($event: MouseEvent) {
        if (!this.disabled && !this.currentPointerMoveType) {
            this.resetPointerMoveData();
            this.setTargetElement($event.target);

            const offset = this.calculatePointerOffsetFromEvent($event);
            this.pointerMoveStartData = {
                pageX: $event.pageX,
                pageY: $event.pageY,
                offsetX: offset.x,
                offsetY: offset.y
            };

            this.currentPointerMoveType = RedrowPointerMoveType.Mouse;
            this.pointerMoveIdentifier = this.getMouseEventIdentifier();
            this.pointerMoveStart($event);

            this.refreshEventListeners();
        }
    }

    /**
     * The DOM target that offset values are relative to.
     */
    protected target: HTMLElement;

    // Bounding box of the target element - calculated once after mouse has been clicked.
    protected clientBoundingBox: DOMRect;

    /**
     * Calculate the offset of the pointer to the target element - taking into account the element width vs client width.
     * @param e 
     * @returns 
     */
    protected calculatePointerOffsetFromEvent(e: MouseEvent | Touch) {

        const offset = {
            x: e.clientX - this.clientBoundingBox.left,
            y: e.clientY - this.clientBoundingBox.top
        };

        /**
         * Because the target could be a different size to what gets displayed - we need to correct the offset value based on the target size
         */

        // Canvas/Image etc have independent width/height
        // So we need to correct the offset values
        if ("width" in this.target) {
            const { clientWidth, clientHeight } = this.target;

            const withWidth = (this.target as HTMLCanvasElement);
            offset.x = (offset.x / clientWidth) * withWidth.width;
            offset.y = (offset.y / clientHeight) * withWidth.height;
        }

        return offset;
    }

    protected setTargetElement(element: any) {
        if (element) {
            this.target = element;
            this.clientBoundingBox = this.target.getBoundingClientRect();
        } else {
            this.target = undefined;
            this.clientBoundingBox = undefined;
        }
    }


    protected onWindowMouseMove($event: MouseEvent) {
        if (this.currentPointerMoveType == RedrowPointerMoveType.Mouse) {
            if (!this.disabled) {
                this.pointerMove($event);
            } else {
                this.pointerMoveEnd($event);
            }
        }
    }

    protected onWindowMouseUp($event: MouseEvent) {
        if (this.currentPointerMoveType == RedrowPointerMoveType.Mouse) {
            this.pointerMoveEnd($event);
        }
    }

    /**
     * Touch events
     */

    protected onTouchStart($event: TouchEvent) {
        if (!this.disabled && !this.currentPointerMoveType) {
            this.resetPointerMoveData();
            /* Set identifier and data based on first Touch in the list */
            const firstTouch = $event.targetTouches[0];
            this.setTargetElement($event.target);

            const offset = this.calculatePointerOffsetFromEvent(firstTouch);
            this.pointerMoveStartData = {
                pageX: firstTouch.pageX,
                pageY: firstTouch.pageY,
                offsetX: offset.x,
                offsetY: offset.y
            };

            this.currentPointerMoveType = RedrowPointerMoveType.Touch;
            this.pointerMoveIdentifier = this.getTouchEventIdentifier(firstTouch);
            this.pointerMoveStart($event);

            this.refreshEventListeners();
        }
    }

    protected onWindowTouchMove($event: TouchEvent) {
        if (this.currentPointerMoveType == RedrowPointerMoveType.Touch) {
            if (!this.disabled) {
                this.pointerMove($event);
            } else {
                this.pointerMoveEnd($event);
            }
        }
    }

    protected onWindowTouchEnd($event: TouchEvent) {
        if (this.currentPointerMoveType == RedrowPointerMoveType.Touch) {
            this.pointerMoveEnd($event);
        }
    }

    /**
     * Event emitted after the user touches/clicks the host element.
     */
    @Output("pointer-move-start")
    public get onPointerMoveStart(): Observable<IRedrowPointerMove> {
        return this._onPointerMoveStartEvent.asObservable();
    }

    /**
     * Event fired every time the users finger/mouse moved after being clicked.
     * 
     * NOTE: Can give values outside of the bounds of the host element.
     */
    @Output("pointer-move")
    public get onPointerMove(): Observable<IRedrowPointerMove> {
        return this._onPointerMoveEvent.asObservable();
    }

    /**
     * Event fired after the user stops pressing down their finger/clicking mouse.
     */
    @Output("pointer-move-end")
    public get onPointerMoveEnd(): Observable<IRedrowPointerMove> {
        return this._onPointerMoveEndEvent.asObservable();
    }


    protected readonly _onPointerMoveStartEvent = new Subject<IRedrowPointerMove>();
    protected readonly _onPointerMoveEvent = new Subject<IRedrowPointerMove>();
    protected readonly _onPointerMoveEndEvent = new Subject<IRedrowPointerMove>();

    protected _windowEventListeners: Subscription;
    protected _hostEventListeners: Subscription;

    protected refreshEventListeners() {

        // Host element events
        if (this.disabled) {
            if (this._hostEventListeners && !this._document.closed) {
                this._hostEventListeners.unsubscribe();
            }
            this._hostEventListeners = undefined;
        } else {
            if (!this._hostEventListeners || this._hostEventListeners.closed) {
                this._hostEventListeners = new Subscription();

                // mousedown
                this._hostEventListeners.add(
                    fromEvent(this.hostElement.nativeElement, "mousedown").subscribe(
                        (e: MouseEvent) => this.onMouseDown(e)
                    )
                );

                // touchstart
                this._hostEventListeners.add(
                    fromEvent(this.hostElement.nativeElement, "touchstart").subscribe(
                        (e: TouchEvent) => this.onTouchStart(e)
                    )
                );

                this.ngSubscriptions.add(
                    this._hostEventListeners
                );
            }
        }

        // Window events
        if (this.currentPointerMoveType) {
            // Should be bound to window
            if (!this._windowEventListeners || this._windowEventListeners.closed) {
                this._windowEventListeners = new Subscription();

                // document:mousemove
                this._windowEventListeners.add(
                    fromEvent(this._document, "mousemove").subscribe(
                        (e: MouseEvent) => this.onWindowMouseMove(e)
                    )
                );

                // document:mouseup
                this._windowEventListeners.add(
                    fromEvent(this._document, "mouseup").subscribe(
                        (e: MouseEvent) => this.onWindowMouseUp(e)
                    )
                );

                // document:touchmove
                this._windowEventListeners.add(
                    fromEvent(this._document, "touchmove", { passive: false }).subscribe(
                        (e: TouchEvent) => this.onWindowTouchMove(e)
                    )
                );

                // document:touchend
                this._windowEventListeners.add(
                    fromEvent(this._document, "touchend").subscribe(
                        (e: TouchEvent) => this.onWindowTouchEnd(e)
                    )
                );

                this.ngSubscriptions.add(
                    this._windowEventListeners
                );
            }
        } else {
            // Should not be bound to window
            if (this._windowEventListeners && !this._windowEventListeners.closed) {
                this._windowEventListeners.unsubscribe();
            }
            this._windowEventListeners = undefined;
        }
    }


    /**
     * Internal variables
     */

    protected currentPointerMoveType: RedrowPointerMoveType;
    protected pointerMoveIdentifier: string;
    protected pointerMoveStartData: {
        pageX: number;
        pageY: number;
        offsetX: number;
        offsetY: number;
    };

    protected _lastTouchPosition: {
        pageX: number;
        pageY: number;
        offsetX: number;
        offsetY: number;
    };

    protected readonly document: Document;

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: any,
        @Host() protected readonly hostElement: ElementRef<HTMLElement>
    ) {

        super();

        this.document = _document;
        this.resetPointerMoveData();
    }

    protected resetPointerMoveData() {
        this.currentPointerMoveType = undefined;
        this.pointerMoveIdentifier = undefined;
        this.pointerMoveStartData = undefined;
        this._lastTouchPosition = undefined;
        this.setTargetElement(undefined);
        this.refreshEventListeners();
    }

    /**
     * Start pointer move
     */
    protected pointerMoveStart($event: MouseEvent | TouchEvent) {
        if (!this.hasValidPointerMoveData()) {
            return;
        }
        const pointerPosition = this.getPointerEventPosition($event);
        this.recordLastTouchPosition($event, pointerPosition);

        this._onPointerMoveStartEvent.next(pointerPosition);
        this.handleEvent($event);
    }

    protected recordLastTouchPosition($event: MouseEvent | TouchEvent, pointerPosition: IRedrowPointerMove) {
        // Record the last known touch position so we can emit it at touchend
        if ($event instanceof TouchEvent) {
            this._lastTouchPosition = {
                pageX: pointerPosition.currentPageX,
                pageY: pointerPosition.currentPageY,
                offsetX: pointerPosition.currentOffsetX,
                offsetY: pointerPosition.currentOffsetY,
            };
        }
    }

    /**
     * Pointer move
     */
    protected pointerMove($event: MouseEvent | TouchEvent) {
        if (!this.hasValidPointerMoveData()) {
            return;
        }
        const pointerPosition = this.getPointerEventPosition($event);
        this.recordLastTouchPosition($event, pointerPosition);
        this._onPointerMoveEvent.next(pointerPosition);

        this.handleEvent($event);
    }

    /**
     * End pointer move
     */
    protected pointerMoveEnd($event: MouseEvent | TouchEvent) {
        if (!this.hasValidPointerMoveData()) {
            return;
        }
        this._onPointerMoveEndEvent.next(this.getPointerEventPosition($event));
        this.resetPointerMoveData();
        this.handleEvent($event);
    }

    /**
     * Handle event
     */

    /* Identifier is just mouse type as we are only listening for 1 mouse */
    protected getMouseEventIdentifier(): string {
        return RedrowPointerMoveType.Mouse;
    }

    /**
     * Identifier takes Touch contact point as a TouchEvent has multiple Touch points
     * and we want to track the same Touch for the duration of a move
     */
    protected getTouchEventIdentifier(touch: Touch): string {
        return `${RedrowPointerMoveType.Touch}-${touch.identifier}`;
    }




    protected getPointerEventPosition($event: MouseEvent | TouchEvent): IRedrowPointerMove {
        if (!this.hasValidPointerMoveData()) {
            return null;
        }
        if ($event instanceof MouseEvent) {
            const offset = this.calculatePointerOffsetFromEvent($event);
            return {
                initialPageX: this.pointerMoveStartData.pageX,
                initialPageY: this.pointerMoveStartData.pageY,
                currentPageX: $event.pageX,
                currentPageY: $event.pageY,
                initialOffsetX: this.pointerMoveStartData.offsetX,
                initialOffsetY: this.pointerMoveStartData.offsetY,
                currentOffsetX: offset.x,
                currentOffsetY: offset.y

            }

        } else if ($event instanceof TouchEvent) {

            if ($event.type === "touchend") {
                return {
                    initialPageX: this.pointerMoveStartData.pageX,
                    initialPageY: this.pointerMoveStartData.pageY,
                    currentPageX: this._lastTouchPosition.pageX,
                    currentPageY: this._lastTouchPosition.pageY,
                    initialOffsetX: this.pointerMoveStartData.offsetX,
                    initialOffsetY: this.pointerMoveStartData.offsetY,
                    currentOffsetX: this._lastTouchPosition.offsetX,
                    currentOffsetY: this._lastTouchPosition.offsetY
                };
            }

            /* Can have multi touch events, so we need to keep track of a particular Touch */
            let currentTouch: Touch;
            for (let i: number = 0; i < $event.touches.length; i++) {
                if (this.pointerMoveIdentifier == this.getTouchEventIdentifier($event.touches[i])) {
                    currentTouch = $event.touches[i];
                    break;
                };
            }
            if (currentTouch) {
                const offset = this.calculatePointerOffsetFromEvent(currentTouch);

                return {
                    initialPageX: this.pointerMoveStartData.pageX,
                    initialPageY: this.pointerMoveStartData.pageY,
                    currentPageX: currentTouch.pageX,
                    currentPageY: currentTouch.pageY,
                    initialOffsetX: this.pointerMoveStartData.offsetX,
                    initialOffsetY: this.pointerMoveStartData.offsetY,
                    currentOffsetX: offset.x,
                    currentOffsetY: offset.y
                }
            }
        }
        return null;
    }

    protected handleEvent($event: Event) {
        if (!!$event) {
            $event.stopPropagation();

            $event.preventDefault();
        }
    }

    /**
     * Pointer move data validation
     */

    protected hasValidPointerMoveData(): boolean {
        return (
            (this.currentPointerMoveType == RedrowPointerMoveType.Mouse || this.currentPointerMoveType == RedrowPointerMoveType.Touch) &&
            (!!this.pointerMoveIdentifier) &&
            (!!this.pointerMoveStartData)
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ("disabled" in changes) {
            if (this.disabled) {
                // If disabled - stop listening for mouse events
                this.currentPointerMoveType = undefined;
            }

            this.refreshEventListeners();
        }
    }
}