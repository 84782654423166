import { Inject, Injectable, Optional } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ICON_REGISTRY, IIconRegistryService, ISvgIcon } from "@redrow/utilities";

/**
 * Angular Material implementation of IIconRegistryService so we can be consistent between apps how we register icons.
 */
@Injectable({
    providedIn: "root"
})
export class MatIconRegistryService<TIconName> implements IIconRegistryService<TIconName> {

    constructor(
        @Optional() @Inject(ICON_REGISTRY) protected readonly moduleRegistry: ISvgIcon<TIconName>[],
        protected readonly registry: MatIconRegistry,
        protected readonly sanitizer: DomSanitizer
    ) {
        // Register icons via module imports
        if (this.moduleRegistry) {
            for (const icon of this.moduleRegistry) {
                this.addSvg(icon);
            }
        }
    }

    getSvg(name: any): string {
        throw new Error("Method not implemented.");
    }
    addSvg(icon: ISvgIcon<any>): void {
        this.registry.addSvgIconLiteral(
            icon.name,
            this.sanitizer.bypassSecurityTrustHtml(icon.data)
        );
    }

}