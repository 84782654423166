import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

import {
    RedrowCheckboxSlideToggleResponsiveTypeResolverService,
} from "./checkbox-slide-toggle-responsive-type-resolver.service";
import { CHECKBOX_SLIDE_TOGGLE_TYPE_RESOLVER_SERVICE } from "./checkbox-slide-toggle-type-resolver-service.token";
import { CheckboxSlideToggleComponent } from "./checkbox-slide-toggle.component";




const EXPORT_COMPONENTS = [
    CheckboxSlideToggleComponent
];

@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        FormsModule
    ],
    exports: [...EXPORT_COMPONENTS],
    declarations: [...EXPORT_COMPONENTS]
})
export class RedrowCheckboxSlideToggleModule {

    /**
     * Import the component with the auto responsive toggle enabled.
     */
    static withResponsiveToggle(): ModuleWithProviders<RedrowCheckboxSlideToggleModule> {
        return {

            ngModule: RedrowCheckboxSlideToggleModule,
            providers: [
                {
                    provide: CHECKBOX_SLIDE_TOGGLE_TYPE_RESOLVER_SERVICE,
                    useClass: RedrowCheckboxSlideToggleResponsiveTypeResolverService
                }
            ]

        };
    }

}
