import { InjectionToken } from "@angular/core";

import { ISyncItemHandlerService } from "../interfaces";



/**
 * <ng-sync-sync-handler-demo></ng-sync-sync-handler-demo>
 */
export const SYNC_ITEM_HANDLER_SERVICE = new InjectionToken<ISyncItemHandlerService>("SYNC_ITEM_HANDLER_SERVICE");
