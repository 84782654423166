// use (16 chars of) 'password' to decrypt 'ciphertext' with xTEA

export function decrypt(ciphertext, password) {
	let v = new Array(2),
		k = new Array(4),
		s = "",
		i;

	for (let i = 0; i < 4; i++) {
		k[i] = Str4ToLong(password.slice(i * 4, (i + 1) * 4));
	}

	ciphertext = unescCtrlCh(ciphertext);
	for (i = 0; i < ciphertext.length; i += 8) {
		// decode ciphertext into s in 64-bit (8 char) blocks
		v[0] = Str4ToLong(ciphertext.slice(i, i + 4));
		v[1] = Str4ToLong(ciphertext.slice(i + 4, i + 8));
		decode(v, k);
		s += LongToStr4(v[0]) + LongToStr4(v[1]);
	}

	// strip trailing null chars resulting from filling 4-char blocks:
	s = s.replace(/\0+$/, "");

	return unescape(s);
}

function decode(v, k) {
	let y = v[0],
		z = v[1];
	let delta = 0x9e3779b9,
		sum = delta * 32;

	while (sum != 0) {
		z -= (((y << 4) ^ (y >>> 5)) + y) ^ (sum + k[(sum >>> 11) & 3]);
		sum -= delta;
		y -= (((z << 4) ^ (z >>> 5)) + z) ^ (sum + k[sum & 3]);
	}
	v[0] = y;
	v[1] = z;
}

// supporting functions

function Str4ToLong(s) {
	// convert 4 chars of s to a numeric long
	let v = 0;
	for (let i = 0; i < 4; i++) {
		v |= s.charCodeAt(i) << (i * 8);
	}
	return isNaN(v) ? 0 : v;
}

function LongToStr4(v) {
	// convert a numeric long to 4 char string
	const s = String.fromCharCode(v & 0xff, (v >> 8) & 0xff, (v >> 16) & 0xff, (v >> 24) & 0xff);
	return s;
}

function unescCtrlCh(str) {
	// unescape potentially problematic nulls and control characters
	return str.replace(/!\d\d?\d?!/g, function(c) {
		return String.fromCharCode(c.slice(1, -1));
	});
}
