import { Inject, Injectable } from "@angular/core";
import { mixinNgSubscriptions, NgSubscriptionsCtor } from "@redrow/utilities";
import { OAuthService, OAuthSuccessEvent } from "angular-oauth2-oidc";
import { defer, Observable } from "rxjs";
import { filter, map, shareReplay } from "rxjs/operators";

import { IOpenIdConfigurationRepository } from "../interfaces/openid-configuration-repository.service";
import { ANGULAR_OAUTH_OIDC_SERVICE } from "../tokens/angular-oauth-oidc-service.token";

const _OAuthOpenIdConfigurationServiceMixin: NgSubscriptionsCtor = mixinNgSubscriptions(class _ { });

/**
 * Implementation of {@link IOpenIdConfigurationRepository} which looks at either the legacy oauth service or the new one to get access to the openid-configuration when loaded.
 */
@Injectable({
    providedIn: "root"
})
export class OAuthOpenIdConfigurationService extends _OAuthOpenIdConfigurationServiceMixin implements IOpenIdConfigurationRepository<any> {

    protected _discoveryDocumentObserver: Observable<any>;

    constructor(
        @Inject(ANGULAR_OAUTH_OIDC_SERVICE) protected readonly oauthService: OAuthService,
    ) {
        super();
    }

    protected async _requestDiscoveryDocumentAsync() {
        if (this._discoveryDocumentObserver) return;

        this._discoveryDocumentObserver = this.oauthService.events.pipe(
            filter(x => x.type === "discovery_document_loaded" && x instanceof OAuthSuccessEvent && !!x.info && !!x.info.discoveryDocument),
            map((x: OAuthSuccessEvent) => x.info.discoveryDocument),
            shareReplay(1)
        );

        this.oauthService.loadDiscoveryDocument()
    }

    observeOpenIdConfiguration(): Observable<any> {
        return defer(
            () => {
                this._requestDiscoveryDocumentAsync();
                return this._discoveryDocumentObserver;
            }
        )
    }
}