import { coerceBooleanProperty } from "../../functions/coercion/boolean-property";
import { Constructor } from "../constructor.type";
import { CanLoadCtor } from "./can-load-ctor.type";

/** Mixin to augment a directive with a `loading` property. */
export function mixinCanLoad<T extends Constructor<{}>>(base: T): CanLoadCtor & T {
    return class extends base {
        private _loading: boolean = false;

        get loading() { return this._loading; }
        set loading(value: any) { this._loading = coerceBooleanProperty(value); }

        constructor(...args: any[]) { super(...args); }
    };
}