import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { HttpTimeoutError } from "@redrow/utilities";
import { Observable, throwError } from "rxjs";
import { timeoutWith } from "rxjs/operators";

/**
 * Default timeout to use with the {@link TimeoutInterceptor} in milliseconds.
 */
export const DEFAULT_HTTP_TIMEOUT_MS = new InjectionToken<number>("DEFAULT_HTTP_TIMEOUT");

export type HttpTimeoutUrlFilterFnType = (url: string) => boolean;

/**
 * Provide an optional function to filter which urls use the default timeout.
 */
export const HTTP_TIMEOUT_URL_FILTER = new InjectionToken<HttpTimeoutUrlFilterFnType>("HTTP_TIMEOUT_URL_FILTER");

export const HTTP_TIMEOUT_OVERRIDE_HEADER_MS: string = "x-http-timeout";

/**
 * Timeout http requests after a configured amount of time OR using the timeout present in a header.
 */
@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {

    protected readonly timeoutFilterFn: HttpTimeoutUrlFilterFnType;

    constructor(
        @Inject(DEFAULT_HTTP_TIMEOUT_MS) protected readonly defaultTimeout: number,
        @Optional() @Inject(HTTP_TIMEOUT_URL_FILTER) _timeoutFilterFn: any
    ) {
        this.timeoutFilterFn = _timeoutFilterFn;
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Filter function
        if (typeof this.timeoutFilterFn === "function") {
            if (!this.timeoutFilterFn(req.url)) {
                return next.handle(req);
            }
        }

        // Implement timeout
        return next.handle(req).pipe(
            timeoutWith(
                req.headers.has(HTTP_TIMEOUT_OVERRIDE_HEADER_MS) ? Number(req.headers.get(HTTP_TIMEOUT_OVERRIDE_HEADER_MS)) : this.defaultTimeout,
                throwError(
                    new HttpTimeoutError()
                )
            )
        );

    }



}
