import { Component, Inject, Input, Optional, Output } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { redLibraryIconCancel, RedLibraryIconType } from "@redrow/ng-redlibrary-icons";
import { ICON_REGISTRY_SERVICE, IIconRegistryService } from "@redrow/utilities";
import { Subject } from "rxjs";

export interface INoticeModalData {
	title?: string;
	content?: string;
}

@Component({
	selector: "notice-modal",
	templateUrl: `./notice-modal.component.html`
})
export class NoticeModalComponent {

	@Output("yes")
	public yes: Subject<void> = new Subject<void>();

	@Output("no")
	public no: Subject<void> = new Subject<void>();

	@Input()
	public title: string;

	@Input("content")
	public set content(newContent: string) {
		if (typeof newContent === "string") {
			this.contentLines = newContent.split("\n");
		} else {
			this.contentLines = null;
		}
	}

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) protected readonly data: INoticeModalData,
		@Optional() @Inject(ICON_REGISTRY_SERVICE) protected readonly iconRegistry: IIconRegistryService<RedLibraryIconType>
	) {
		if (data) {
			this.title = data.title ? data.title : this.title;
			this.content = data.content ? data.content : this.content;
		}

		if (this.iconRegistry) {
			this.iconRegistry.addSvg(redLibraryIconCancel);
		}
	}

	public contentLines: string[];

}
