<ip-page-shell [loading]="loading" [error]="error">
	<!-- PAGE CONTENT-->

	<div class="document-content">
		<h2>Documents</h2>

		<p *ngIf="!documents || documents.length === 0">There are no documents to display.</p>

		<mat-selection-list [multiple]="false" *ngIf="documents && documents.length">
			<mat-list-option *ngFor="let document of documents" (click)="openDocument(document)">
				{{ document.docTitle }}
			</mat-list-option>
		</mat-selection-list>
	</div>
</ip-page-shell>
