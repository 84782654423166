import { Component, Inject, Optional } from "@angular/core";
import { redLibraryIconCancel, RedLibraryIconType } from "@redrow/ng-redlibrary-icons";
import { ICON_REGISTRY_SERVICE, IIconRegistryService } from "@redrow/utilities";

@Component({
	selector: "modal-title",
	templateUrl: `./modal-title.component.html`
})
export class ModalTitleComponent {

	constructor(
		@Optional() @Inject(ICON_REGISTRY_SERVICE) protected readonly iconRegistry: IIconRegistryService<RedLibraryIconType>

	) {
		if (this.iconRegistry) {
			this.iconRegistry.addSvg(redLibraryIconCancel);
		}
	}

}
