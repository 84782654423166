import { InstructionStatus } from "./InstructionStatus";
import { OverdueReason } from "./OverdueStatus";

export interface IWorkInstructionHeader {
	id: number;
	instructionReference: string;
	incidentId: number;
	outletId: number;
	calculatedStatusId: number;
	incidentSummary: string;
	incidentDateReceived: Date;
	calculatedStatusText: string;
	overdueReason: OverdueReason;
	overdue: boolean;
	assignedToUserId?: number;
	assignedToUserName: string;

	lastAppointmentDate: Date;
	urgency: number;
	awaitingPartsDate: Date;
	photographRequired: boolean;
	statusId: InstructionStatus;

	appointmentPast: boolean;
	daysOutstanding: number;
	companyId: string;

	isClosed: boolean;

	OpenedOnScPortalDate?: Date;
	OpenedByScPortalUserId?: number;
	
}


export class WorkInstructionHeader {

	protected _raw: IWorkInstructionHeader;

	constructor(raw: IWorkInstructionHeader | WorkInstructionHeader) {
		if ("_raw" in raw) {
			// Load from an existing object (i.e. copy operation)
			this._raw = {
				...raw._raw
			};
		} else {
			// Load from raw object (i.e. from the API)
			this._raw = raw;
		}
	}

	get isClosed(): boolean {
		return this.statusId === InstructionStatus.Completed;
	}

	get companyId(): string {
		return this._raw.companyId;
	}

	get id(): number {
		return +this._raw.id;
	}
	get instructionReference(): string {
		return this._raw.instructionReference;
	}
	get incidentId(): number {
		return +this._raw.incidentId;
	}
	get outletId(): number {
		return +this._raw.outletId;
	}
	get calculatedStatusId(): number {
		return +this._raw.calculatedStatusId;
	}
	get incidentSummary(): string {
		return this._raw.incidentSummary;
	}
	get incidentDateReceived(): Date {
		if ("incidentDateReceived" in this._raw && this._raw.incidentDateReceived !== null) {
			return new Date(this._raw.incidentDateReceived);
		}
		return null;
	}
	get calculatedStatusText(): string {
		return this._raw.calculatedStatusText;
	}
	overdueReason: OverdueReason;
	get overdue(): boolean {
		return (+this._raw.overdue) ? true : false;
	}
	get assignedToUserId(): number {
		if ("assignedToUserId" in this._raw && this._raw.assignedToUserId !== null) {
			return +this._raw.assignedToUserId;
		}
		return null;
	}

	get assignedToUserName(): string {
		return this._raw.assignedToUserName;
	}

	get lastAppointmentDate(): Date {
		if ("lastAppointmentDate" in this._raw && this._raw.lastAppointmentDate !== null) {
			return new Date(this._raw.lastAppointmentDate);
		}
		return null;
	}
	get urgency(): number {
		return +this._raw.urgency;
	}
	get awaitingPartsDate(): Date {
		if ("awaitingPartsDate" in this._raw && this._raw.awaitingPartsDate !== null) {
			return new Date(this._raw.awaitingPartsDate);
		}
		return null;
	}
	get photographRequired(): boolean {
		return (+this._raw.photographRequired) ? true : false;
	}
	get statusId(): InstructionStatus {
		return +this._raw.statusId;
	}

	get appointmentPast(): boolean {
		return (+this._raw.appointmentPast) ? true : false;
	}
	get daysOutstanding(): number {
		return +this._raw.daysOutstanding;
	}

	get openedOnScPortalDate(): Date {
		return this._raw.OpenedOnScPortalDate ? this._raw.OpenedOnScPortalDate : null;
	}

	get openedByScPortalUserId(): number {
		return this._raw.OpenedByScPortalUserId ? this._raw.OpenedByScPortalUserId : null;
	}

}
