import { Provider } from "@angular/core";
import { LEGACY_APP_CONFIG } from "@redrow/utilities";
import { IAuthConfig } from "../interfaces/oauth-config.interface";
import { OAUTH_CONFIG } from "../tokens/oauth-config.token";

interface ILegacyConfigLike {
	Api: {
		Url: string;
	};
}

/**
 * Builds a custom factory for the OAUTH_CONFIG token which injects the legacy app configuration Api.Url & params to this function
 * @param requireOutlet Does your application require outlet to be selected?
 * @param sendAccessToken Should the token be sent to server? defaults to true
 * @param additionalResourceServerUrls additional urls which the token will be sent to
 * @param customUrlValidation
 */
export function UseAppConfigurationForOAuthSettings(requireOutlet: boolean = false, sendAccessToken: boolean = true, additionalResourceServerUrls: string[] = [], customUrlValidation?: (url: string) => boolean) {
	return (legacyAppConfig: ILegacyConfigLike): IAuthConfig => {
		return {
			resourceServer: {
				allowedUrls: [legacyAppConfig.Api.Url, ...additionalResourceServerUrls],
				sendAccessToken,
				customUrlValidation,
			},
			requireOutlet,
		};
	};
}

export const OAuthConfigurationFromAppSettings: Provider[] = [
	{
		provide: OAUTH_CONFIG,
		useFactory: UseAppConfigurationForOAuthSettings(),
		deps: [LEGACY_APP_CONFIG],
		multi: false,
	},
];

export const OAuthConfigurationFromAppSettingsWithSalesOutlet: Provider[] = [
	{
		provide: OAUTH_CONFIG,
		useFactory: UseAppConfigurationForOAuthSettings(true),
		deps: [LEGACY_APP_CONFIG],
		multi: false,
	},
];
