export enum IChangePasswordEventType {
    ERROR = 1,
    SUCCESS = 2,
    READY = 3
}

/**
 * Model which describes a response from changing your password
 * Used as part of the OAuth external OAuthChangePasswordService interface which integrated into the OAuth external ClientApp.
 */
export interface IChangePasswordEvent {

    /**
     * Was the password changed?
     */
    type: IChangePasswordEventType;

    /**
     * An error happened in the change password window.
     */
    errorMessage?: string;
}