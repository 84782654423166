import { ILogger } from "../../interfaces/logging/logger.interface";
import { Constructor } from "../constructor.type";
import { OptionalLoggingCtor } from "./optional-logging-ctor.type";



/**
 * Mixin that provides an implementation of {@link IOptionalLogging} providing optional logging.
 * 
 * The expectation if that you provide a logger in your constructor like so:
 * 
 * ```typescript
 * 
 * import { Optional, Inject } from "@angular/core";
 * import { mixinOptionalLogging, ILogger } from "@redrow/utilities";
 * 
 * const _MixinMyAwesomeClassWithLogging = mixinOptionalLogging(class MyAwesomeClassWithLoggingBase {});
 * 
 * @Component({
 *     // ...
 * })
 * export class MyAwesomeClassWithLogging extends _MixinMyAwesomeClassWithLogging {
 * 
 *  constructor(
 *      @Optional() @Inject(MY_LOGGER_TOKEN) protected readonly logger: ILogger
 *  ){
 *      super();
 *  }
 * 
 *  anotherMethod() {
 *   // This will only log if MY_LOGGER_TOKEN has been provided.
 *   this.optInfo("Hello world!");
 *  }
 * 
 * }
 * 
 * ```
 * 
 * **NOTE** Please refer to {@page ~~/wiki/best-practice/import-inferred-types.md} for a common gotcha when using this within the library.
 * 
 * @param base 
 * @returns 
 */
export function mixinOptionalLogging<T extends Constructor<{}>>(base: T): OptionalLoggingCtor & T {
    return class extends base {
        constructor(...args: any[]) { super(...args); }

        protected readonly logger: ILogger;

        optVerb(...args: any[]) {
            if (this.logger) {
                this.logger.verbose(...args);
            }
        }

        optDebug(...args: any[]) {
            if (this.logger) {
                this.logger.debug(...args);
            }
        }

        optInfo(...args: any[]) {
            if (this.logger) {
                this.logger.info(...args);
            }
        }

        optWarn(...args: any[]) {
            if (this.logger) {
                this.logger.warn(...args);
            }
        }

        optError(...args: any[]) {
            if (this.logger) {
                this.logger.error(...args);
            }
        }

        optFatal(...args: any[]) {
            if (this.logger) {
                this.logger.fatal(...args);
            }
        }

    };
}