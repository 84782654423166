import { Injectable } from "@angular/core";
import { iif, Observable, throwError } from "rxjs";
import { switchMap, map, finalize } from "rxjs/operators";

import { RedrowMimeType } from "@redrow/utilities";

import { RedrowImageConverterService } from "../image/redrow-image-converter.service";

import { ICanvasConverterOptions } from "./ICanvasConverterOptions";
import { RedrowCanvasManagerService } from "./redrow-canvas-manager.service";

@Injectable({
	providedIn: "root"
})
export class RedrowCanvasConverterService {


	constructor(
		protected readonly redrowCanvasManagerService: RedrowCanvasManagerService,
		protected readonly RedrowImageConverterService: RedrowImageConverterService
	) { }

	/**
	 * Convert a HTML canvas element to a blob
	 * @param canvas canvas to convert
	 * @param options options to describe transforms to canvas
	 * @returns Blob
	 */
	toBlob(canvas: HTMLCanvasElement, options?: ICanvasConverterOptions): Observable<Blob> {
		// Verify canvas is valid
		return iif(
			() => !canvas,
			throwError(new Error("missing HTML canvas element")),
			// Convert canvas to blob
			new Observable<Blob>(observer => {

				// Handle canvas to blob conversion
				canvas.toBlob(
					blob => {
						// Once callback has resolved, emit new blob once
						observer.next(blob);
						observer.complete();
					},
					options?.mimeType ?? RedrowMimeType.Image_Jpeg,
					options?.quality ?? 1.0
				);

				return {
					unsubscribe() { }
				};
			})
		);
	}

	/**
	 * Convert blob to HTMLCanvasElement
	 * @param blob blob to convert
	 * @returns HTMLCanvasElement
	 */
	fromBlob(blob: Blob): Observable<HTMLCanvasElement> {

		return this.RedrowImageConverterService.fromBlob(blob)
			.pipe(

				// Create HTML canvas
				switchMap(htmlImageElement => {
					return this.redrowCanvasManagerService.get("BasicFileConverter", htmlImageElement.width, htmlImageElement.height)
						.pipe(
							map(htmlCanvasElement => {
								const ctx = htmlCanvasElement.getContext("2d");
								ctx.clearRect(0, 0, htmlImageElement.width, htmlImageElement.height);
								ctx.drawImage(htmlImageElement, 0, 0, htmlImageElement.width, htmlImageElement.height);

								return htmlCanvasElement;
							})
						);
				}),

				// Tidy up on error or complete
				finalize(() => this.redrowCanvasManagerService.unlock("BasicFileConverter"))

			);
	}
}