import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { RedrowPlatformDetectionService } from "@redrow/ng-services";
import {
	INgSubscriptions,
	mixinNgSubscriptions
} from "@redrow/utilities";

import { CheckboxSlideToggleComponentType } from "./checkbox-slide-toggle-component-type.enum";
import { ICheckboxSlideToggleTypeResolverService } from "./checkbox-slide-toggle-type-resolver-service.interface";

/**
 * Provider for CHECKBOX_SLIDE_TOGGLE_TYPE_RESOLVER_SERVICE token
 * This implementation will change to a slide toggle if in mobile view
 */
@Injectable()
export class RedrowCheckboxSlideToggleResponsiveTypeResolverService
	extends mixinNgSubscriptions(class _ { })
	implements ICheckboxSlideToggleTypeResolverService, INgSubscriptions {

	readonly resolveType = new BehaviorSubject<CheckboxSlideToggleComponentType>(CheckboxSlideToggleComponentType.SLIDE_TOGGLE);

	constructor(
		protected readonly redrowPlatformDetectionService: RedrowPlatformDetectionService
	) {
		super();

		this.ngSubscriptions.add(
			this.redrowPlatformDetectionService.observePlatform()
				.subscribe(platform => this.resolveType.next(
					platform == "desktop" ? CheckboxSlideToggleComponentType.CHECKBOX : CheckboxSlideToggleComponentType.SLIDE_TOGGLE
				))
		);
	}
}