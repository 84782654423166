/**
 * Defines the various log levels that are available. Depending on the environment some of these may get filtered in production.
 * @readonly
 * @enum {number}
 */
export const enum LogLevel {

	/**
	 * Use the Verbose log level for things that don't fit into the other categories that give too much detail for dev/test/production
	 */
	Verbose = -1,
	Debug = 0,
	Info = 1,
	Warn = 2,
	Error = 3,
	Fatal = 4,
}

const a = {};
a[LogLevel.Verbose] = "Verbose";
a[LogLevel.Debug] = "Debug";
a[LogLevel.Info] = "Info";
a[LogLevel.Warn] = "Warn";
a[LogLevel.Error] = "Error";
a[LogLevel.Fatal] = "Fatal";

export const LogLevelText: { [key: string]: string } = a;
