import { Component, Inject, OnInit } from "@angular/core";
import { CONFIGURATION_MAPPER, IConfiguration } from "@redrow/configuration";
import { redLibraryIconPerson, redLibraryIconRedrowLogoRed } from "@redrow/ng-redlibrary-icons";
import { RedrowAppAuthenticatedUserService } from "@redrow/ng-services";
import {
    APP_AUTHENTICATION_SERVICE,
    IAppAuthenticationService,
    IAppConfiguration,
    mixinNgSubscriptions,
    NgSubscriptionsCtor,
    RedrowEnvironmentType,
} from "@redrow/utilities";
import { filter } from "rxjs/operators";

interface IExpectedProfile {
    user_id: string;
    email: string;
    name: string;
}

class RedrowProfilePopoverComponentBase {
    constructor() { }
}

const _RedrowProfilePopoverComponentBase: NgSubscriptionsCtor & typeof RedrowProfilePopoverComponentBase = mixinNgSubscriptions(RedrowProfilePopoverComponentBase);

/**
 * A component which displays the users name & e-mail address.
 * 
 * <ng-material-components-profile-demo></ng-material-components-profile-demo>
 */
@Component({
    templateUrl: `./profile-popover.component.html`,
    styleUrls: [
        `./profile-popover.component.scss`
    ]
})
export class RedrowProfilePopoverComponent extends _RedrowProfilePopoverComponentBase implements OnInit {

    public readonly profileIcon = redLibraryIconPerson;
    public readonly companyLogo = redLibraryIconRedrowLogoRed;

    protected _env: RedrowEnvironmentType;

    constructor(
        protected readonly userService: RedrowAppAuthenticatedUserService<IExpectedProfile>,
        @Inject(APP_AUTHENTICATION_SERVICE) protected readonly appAuthenticationService: IAppAuthenticationService,
        @Inject(CONFIGURATION_MAPPER) protected readonly configMapper: IConfiguration<IAppConfiguration>
    ) {
        super();

        this._env = configMapper.get().environment;
    }

    ngOnInit() {
        // Fill profile details using user service
        this.ngSubscriptions.add(
            this.userService.observeAuthenticatedUser().pipe(
                filter(x => !!x)
            ).subscribe(
                user => {
                    this.profileName = user.name;
                    this.profileEmail = user.email;

                    // In non-live environments we also put the user id
                    if (this._env !== "Live") {
                        this.profileName = `${this.profileName} (${user.user_id})`
                    }
                }
            )
        );
    }

    public signOut() {
        this.appAuthenticationService.logout();
    }

    public profileName: string;
    public profileEmail: string;

}
