import { Inject, Injectable, Optional } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { APP_SERVICE, IAppService, ILogger, RedrowAppState } from "@redrow/utilities";
import { defer, Observable, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

import { OAUTH_LOGGER } from "../../../private/_deprecated/tokens/oauth-logger.token";
import { OAuthService } from "../services/oauth.service";

/**
 * Check if the user is authenticated - use this to protect routes which require user authorization.
 * 
 * Used in Inspection Portal.
 * 
 * @deprecated Please see {@page ~~/wiki/solutions/authentication/oauth-quickstart.md} for how to configure OAuth.
 */
@Injectable()
export class OAuthLoggedInGuard implements CanActivate {
	constructor(protected readonly router: Router, protected readonly oauthService: OAuthService, @Optional() @Inject(APP_SERVICE) protected readonly app: IAppService, @Optional() @Inject(OAUTH_LOGGER) protected readonly logger: ILogger) { }

	protected handleOAuthError(err: any, msg: string = "Failed to authorise user.") {

		if (this.logger) {
			this.logger.error(`handleOAuthError: ${msg}`, err);
		}

		/**
		 * If we have an app state - pass this error to that instead
		 */
		if (this.app) {
			this.app.handleError(err, "oauth_init");
			return;
		}

		this.router.navigate(["/error"], {
			state: {
				error: msg,
			},
		});
	}

	protected optionalLog(...what: any[]) {
		if (this.logger) {
			this.logger.info(...what);
		}
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


		/**
		 * Check for valid login
		 */
		return defer(() => this.oauthService.init()).pipe(

			/**
			 * Check again for login
			 */
			switchMap(initSuccess => {

				if (initSuccess) {
					if (this.oauthService.hasValidLogin()) {
						this.optionalLog(`initSuccess: true, hasValidLogin: true`);
						return of(true);
					} else {

						this.optionalLog(`initSuccess: true, hasValidLogin: false`);

						/**
						 * If the application implements an app service this likely means they are using the higher level state management
						 */
						if (this.app) {

							/**
							 * If the app isn't ready then we shouldn't reload the app here.
							 */
							if (this.app.state.value !== RedrowAppState.READY) {
								this.optionalLog(`app state available. App not ready.`);
								return of(false);
							}

							this.optionalLog(`app state available. Reloading app...`);

							/**
							 * When using this higher level state management we can just reload the app at this point to init oauth
							 */
							location.reload();
							return of(false);
						}

						this.optionalLog(`app state not available. Going to oauth login url...`);

						// Redirect to login screen
						this.router.navigate(["/oauth/login"], {
							queryParams: { returnUrl: state.url },
						});

						return of(false);

					}
				}

				this.optionalLog(`initSuccess: false`);
				this.handleOAuthError(new Error("Failed to init oauth"));
				return of(false);


			}),

			catchError(e => {
				// failed to init - show error page
				this.handleOAuthError(e);
				return of(false);
			})
		);


	}
}
