<rr-default-app-shell>
	<div class="sticky-header">
		<!-- LOGOUT BAR - For desktop -->
		<div *ngIf="desktop" class="logout-bar">
			<div class="logout-bar-content">
				<ng-container *ngIf="showAppVersion">
					<div class="button-spacer"></div>
					{{ appVersion }}
				</ng-container>
				<div class="button-spacer"></div>
				<span (click)="clickForAppVersion()">
					<strong>{{ appName }}</strong>
				</span>
				<button mat-button (click)="logout()">Sign Out</button>
			</div>
		</div>

		<!-- Toolbar -->
		<mat-toolbar main-toolbar [class.desktop]="desktop">
			<div class="navbar">
				<div *ngIf="!desktop">
					<button mat-icon-button (click)="sidenav.toggle()">
						<mat-icon svgIcon="cancel" *ngIf="showMenuCloseButton"></mat-icon>
						<mat-icon svgIcon="menu" *ngIf="!showMenuCloseButton"></mat-icon>
					</button>
				</div>

				<!-- Redrow logo -->
				<div class="companyName-container" [class.desktop]="desktop">
					<mat-icon svgIcon="redrow-logo-red" class="companyName" [class.desktop]="desktop" routerLink="/"></mat-icon>
				</div>

				<!-- <span class="example-spacer"></span> -->

				<!-- Desktop routes -->
				<div *ngIf="desktop" class="desktop-nav">
					<ng-container *ngTemplateOutlet="renderRoutes"></ng-container>
				</div>
			</div>
		</mat-toolbar>
	</div>

	<mat-sidenav-container [class.desktop]="desktop">
		<!-- Sidenav - for mobile -->
		<mat-sidenav
			color="primary"
			#sidenav
			mode="over"
			[(opened)]="sidenavOpen"
			(opened)="sidenavOpening = false; sidenavClosing = false"
			(openedStart)="sidenavOpening = true"
			(closedStart)="sidenavClosing = true"
			*ngIf="!desktop"
		>
			<div class="sidenav-routes-mobile">
				<ng-container *ngTemplateOutlet="renderRoutes"></ng-container>
			</div>
		</mat-sidenav>

		<!-- Actual content container-->
		<mat-sidenav-content>
			<div class="content-container" [class.desktop]="desktop">
				<!-- Content -->
				<router-outlet *ngIf="!isLazyLoading"></router-outlet>

				<!-- Lazy loading spinner -->
				<!-- <div *ngIf="isLazyLoading" class="loading-spinner-container" [class.desktop]="desktop">
					<mat-progress-spinner color="warn" mode="indeterminate"></mat-progress-spinner>
				</div> -->

				<!-- Loading spinner -->
				<div *ngIf="isLazyLoading || isLoading" class="app-loading-spinner-container" [class.desktop]="desktop">
					<mat-progress-spinner [color]="isLazyLoading ? 'warn' : 'primary'" mode="indeterminate"></mat-progress-spinner>
				</div>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</rr-default-app-shell>

<!-- Navigation template -->
<ng-template #renderRoutes>
	<ng-container *ngFor="let route of routes">
		<!-- Display logic between desktop & mobile -->
		<ng-container *ngIf="!route.hidden && ((!route.onlyDesktop && !route.onlyMobile) || (route.onlyDesktop && desktop) || (route.onlyMobile && !desktop))">
			<!-- URL route -->
			<a *ngIf="route.type === 1" mat-button routerLink="{{ route.url }}" routerLinkActive="active" [class.desktop]="desktop">{{ route.title }}</a>

			<!-- Actioned route -->
			<a *ngIf="route.type === 2" mat-button (click)="route.action()" [class.desktop]="desktop">{{ route.title }}</a>

			<!-- spacer -->
			<div class="button-spacer" *ngIf="route.type === 3"></div>
		</ng-container>

		<mat-divider *ngIf="!desktop"></mat-divider>
	</ng-container>
</ng-template>
