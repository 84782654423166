import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";


@Injectable({
	providedIn: "root"
})
export class RedrowCanvasProviderService {

	protected readonly document: Document;

	constructor(
		@Inject(DOCUMENT) private _document: any
	) {
		this.document = _document;
	}

	create(w: number, h: number): HTMLCanvasElement {
		const canvas = this.document.createElement("canvas");

		canvas.width = w;
		canvas.height = h;

		return canvas;
	}

}
