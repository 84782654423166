/* AUTOEXPORT:SETTING:MODULE:ApplicationShellModule */
import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from "@angular/core";
import { Router } from "@angular/router";
import {
    APP_SERVICE,
    APP_SHELL_SETTINGS,
    IAppService,
    IAppShellSettings,
    IRedrowLoadingScreenService,
    REDROW_LOADING_SCREEN_SERVICE,
    RedrowAppState,
} from "@redrow/utilities";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";

import { RedrowBaseComponent } from "../base-component";





@Component({
	selector: `rr-default-app-shell`,
	templateUrl: `./redrow-default-app-shell.component.html`,
	styleUrls: [`./redrow-default-app-shell.component.scss`]
})
export class RedrowDefaultAppShellComponent extends RedrowBaseComponent implements OnInit {



	public get appError(): BehaviorSubject<boolean> {
		return this.app.error;
	}

	public get appReady(): BehaviorSubject<boolean> {
		return this.app.ready;
	}

	public get appLoading(): BehaviorSubject<boolean> {
		return this.app.loading;
	}

	public get appStateContext(): BehaviorSubject<string> {
		return this.app.stateContext;
	}

	public get appLastError(): BehaviorSubject<any> {
		return this.app.lastError;
	}

	protected loadingSubscription: Subscription;

	constructor(
		@Inject(APP_SERVICE) protected readonly app: IAppService,
		@Optional() @Inject(APP_SHELL_SETTINGS) public readonly shellSettings: IAppShellSettings,
		@Inject(REDROW_LOADING_SCREEN_SERVICE) @Optional() protected readonly loadingScreenService: IRedrowLoadingScreenService,
		protected readonly router: Router,
		protected readonly cd: ChangeDetectorRef
	) {
		super();

	}

	public isFeatureEnabled(feature: string, defaultValue: boolean) {
		if (this.shellSettings) {
			if (defaultValue) {
				return this.shellSettings[feature] !== false;
			} else {
				return this.shellSettings[feature] === true;
			}
		}
		return defaultValue;
	}

	ngOnInit() {



		/**
		 * If the browser is supported we then want to start the loading process if requested
		 */
		if (this.isFeatureEnabled("loadingScreen", false)) {

			this.loadingSubscription = this.loadApplication().subscribe(
				null,
				err => {
					// Pass the error to the application service for processing
					this.app.handleError(err, "loading_error");
				},
				() => {
					this.onApplicationLoaded();
				}
			);

			this.ngSubscriptions.add(
				this.loadingSubscription
			);
		} else {

			/**
			 * Skip loading screen
			 */
			this.onApplicationLoaded();
		}

	}

	protected onApplicationLoaded() {

		// Check for an error state so we can stop the init function sooner
		if (this.app.state.value === RedrowAppState.ERROR) {
			return;
		}

		setTimeout(
			() => {

				/**
				 * The app is now ready
				 */
				this.app.stopLoading();

				/**
				 * Navigate to the application
				 */
				this.router.initialNavigation();

			},
			1
		);

	}

	/**
	 * Tries to load the application using the default loader
	 */
	protected loadApplication(): Observable<boolean> {

		return new Observable<boolean>(observable => {

			/**
			 * Only load the initial route once the application has loaded
			 */
			const hasLoaded = this.loadingScreenService.hasLoaded.value;

			/**
			 * Already loaded the application
			 */
			if (hasLoaded) {
				observable.next(true);
				observable.complete();
				return;
			}

			let sub: Subscription;

			/**
			 * Already loading the application
			 */
			if (this.loadingScreenService.loading.value) {
				sub = this.loadingScreenService.hasLoaded.pipe(
					/**
					 * Wait for loading to complete
					 */
					filter(x => x === true),

					/**
					 * Only execute this stream once
					 */
					take(1)
				).subscribe(
					val => observable.next(val),
					err => observable.error(err),
					() => observable.complete()
				);
			} else {
				sub = this.loadingScreenService.load().subscribe(
					val => {
						observable.next(true);
						observable.complete()
					},
					err => observable.error(err)
				);
			}

			return {

				unsubscribe() {
					if (sub && !sub.closed) {
						sub.unsubscribe();
						sub = undefined;
					}
				}

			};

		});

	}



}