import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IRedrowOAuthService, OAUTH_SERVICE } from "@redrow/utilities";
import { IContractorProfile } from "@shared/oauth/contractor-profile.interface";
import { Observable } from "rxjs";

/**
 * This service will try to log the user out if their token has expired.
 */
@Injectable({
	providedIn: "root"
})
export class TokenExpiryLogoutService implements HttpInterceptor {

	constructor(@Inject(OAUTH_SERVICE) protected readonly oauthService: IRedrowOAuthService<IContractorProfile>) { }

	/**
	 * Check the users token expiry to see if it has expired - if so we redirect the user to the logout screen.
	 */
	public logoutIfTokenExpired(): boolean {

		/**
		 * Check if our token expired
		 */
		if (this.oauthService.hasValidLogin() && this.oauthService.getAccessTokenExpiry() < new Date()) {
			this.oauthService.logout();

			// Logged out
			return true;
		}

		// Did not logout
		return false;
	}

	/**
	 * Implementation of interceptor class so we can easily hook up to http requests to check for access problems.
	 * @param req
	 * @param next
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		this.logoutIfTokenExpired()
		return next.handle(req);
	}

}
