import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import {
    ILogger,
    IScheduledTask,
    IScheduledTaskService,
    ITask,
    mixinOptionalLogging,
    OptionalLoggingCtor,
    ScheduledTaskExecutedEvent,
    ScheduledTaskExecutionErrorEvent,
    ScheduledTaskManagerEvent,
    ScheduledTaskScheduledEvent,
    ScheduledTaskStatus,
} from "@redrow/utilities";
import { BehaviorSubject, interval, merge, Observable, of, Subject } from "rxjs";
import { catchError, finalize, map, mergeMap, share, switchMap, tap } from "rxjs/operators";

import { IScheduledTaskInfo } from "./scheduled-task-info.interface";


/**
 * A logger for the scheduled task manager - provide if you require the logging output from this service.
 */
export const SCHEDULED_TASK_SERVICE_LOGGER = new InjectionToken<ILogger>("SCHEDULED_TASK_SERVICE_LOGGER");

class ScheduledTaskManagerBase {
    constructor() { }
}

const _ScheduledTaskManagerMixinBase: OptionalLoggingCtor & typeof ScheduledTaskManagerBase = mixinOptionalLogging(ScheduledTaskManagerBase);


/**
 * Implementation of the scheduled task manager interface.
 * 
 * This implementation supports scheduling tasks in the future, repeating tasks and retrying tasks a certain number of times or forever via Infinity.
 * Once observed, this task scheduler will check every second to see if there are new tasks to run.
 */
@Injectable({
    providedIn: "root"
})
export class RedrowScheduledTaskService extends _ScheduledTaskManagerMixinBase implements IScheduledTaskService {


    protected _scheduledTasks: IScheduledTask[] = [];
    protected readonly _events: Subject<ScheduledTaskManagerEvent> = new Subject<ScheduledTaskManagerEvent>();
    protected readonly _info: BehaviorSubject<IScheduledTaskInfo[]> = new BehaviorSubject<IScheduledTaskInfo[]>([]);

    constructor(
        @Optional() @Inject(SCHEDULED_TASK_SERVICE_LOGGER) protected readonly logger: ILogger
    ) {
        super();


    }

    public unschedule(task: ITask) {
        const schedule = this._scheduledTasks.find(x => x.task === task);
        if (schedule) {
            schedule.repeatEveryMs = undefined;

            // Complete the task if it has already run
            if (schedule.when <= new Date()) {
                if (schedule.status !== ScheduledTaskStatus.EXECUTING) {
                    schedule.status = ScheduledTaskStatus.COMPLETED;
                }
            }
        }
    }

    /**
     * Get information about the scheduled tasks for debugging purposes.
     */
    public getScheduledTaskInfo(): Observable<IScheduledTaskInfo[]> {
        return this._info.asObservable();
    }

    public getScheduledTasks(): IScheduledTask[] {
        return this._scheduledTasks.map(
            x => {
                return {
                    ...x
                };
            }
        );
    }

    /**
     * Find the status of a scheduled task using a task instance.
     */
    public getTaskStatus(task: ITask): ScheduledTaskStatus {
        return this._scheduledTasks.find(x => x.task === task)?.status;
    }

    /**
     * Schedule a task to run in the future.
     */
    public schedule(
        task: ITask,
        when: Date
    ) {
        return this.scheduleEvery(task, undefined, when);
    }

    /**
     * Schedule a task to run now.
     * @param task
     */
    public scheduleNow(task: ITask) {
        return this.schedule(task, new Date());
    }

    /**
     * Schedule a task to execute now & after a period of ms after that
     */
    public scheduleEveryImmediate(
        task: ITask,
        repeatEveryMs: number
    ) {
        return this.scheduleEvery(task, repeatEveryMs, new Date());
    }

    public setTaskRepeatEveryMs(task: ITask, repeatEveryMs: number | undefined) {
        const existingTask = this._scheduledTasks.find(x => x.task === task);
        if (existingTask) {
            existingTask.repeatEveryMs = repeatEveryMs;
        }
    }

    public setRetryCount(task: ITask, retryCount: number | undefined) {
        const existingTask = this._scheduledTasks.find(x => x.task === task);
        if (existingTask) {
            existingTask.initialRetryCount = retryCount;
            existingTask.retryCount = retryCount;
        }
    }

    /**
     * Schedule a task to run at some point, then repeat on some interval
     * NOTE: If the task already exists, the settings will be updated
     * NOTE: If you don't provide "when", but you do provide "repeatEveryMs" then the current time + repeatEveryMs will be used.
     * @param task
     * @param repeatEveryMs
     * @param when
     */
    public scheduleEvery(
        task: ITask,
        repeatEveryMs?: number,
        when?: Date,
        retryCount?: number
    ) {
        // Check for existing task - to overwrite
        const existingTask = this._scheduledTasks.find(x => x.task === task);
        if (existingTask) {
            existingTask.repeatEveryMs = repeatEveryMs ?? existingTask.repeatEveryMs;
            existingTask.when = when ?? existingTask.when;
            existingTask.initialRetryCount = retryCount ?? existingTask.initialRetryCount

            if (
                existingTask.status !== ScheduledTaskStatus.EXECUTING
            ) {
                existingTask.status = ScheduledTaskStatus.QUEUED;
                existingTask.when = existingTask.when ?? new Date(
                    new Date().getTime() + (existingTask.repeatEveryMs ?? 0)
                );
            }

            return;
        }

        const schedule: IScheduledTask = {
            task,
            repeatEveryMs,
            when: when ?? new Date(
                new Date().getTime() + (repeatEveryMs ?? 0)
            ),
            status: ScheduledTaskStatus.QUEUED,
            initialRetryCount: retryCount,
            retryCount
        };

        this._scheduledTasks.push(schedule);

        this._events.next(
            new ScheduledTaskScheduledEvent(task, schedule)
        );

        this.updateScheduledTaskInfo();
    }

    protected _observeObservable: Observable<ScheduledTaskManagerEvent>;

    /**
     * Observe the tasks being run by the task manager.
     * NOTE: This needs to be run at least once for the task manager to work. i.e. in your AppComponent
     */
    public observe(): Observable<ScheduledTaskManagerEvent> {
        if (this._observeObservable) {
            return this._observeObservable;
        }

        // Share the observable so there is only ever one task manager running.
        this._observeObservable = merge(
            this._observe(),
            this._events.asObservable()
        ).pipe(
            share()
        );

        return this._observeObservable;
    }

    protected shouldRetryTask(task: IScheduledTask) {
        return task.retryCount === Infinity || task.retryCount > 0;
    }

    protected decrementRetryCountForTask(task: IScheduledTask) {
        if (typeof task.retryCount === "number" && task.retryCount !== Infinity) {
            task.retryCount--;
            this.optInfo(`Task '${task.task.name}' will retry ${task.retryCount} more times.`);
        }
    }

    protected shouldTaskRun(task: IScheduledTask) {
        if (task.status === ScheduledTaskStatus.QUEUED) {
            return task.when < new Date();
        }
        if (task.status === ScheduledTaskStatus.FAILED) {
            if (task.when instanceof Date) {
                return task.when < new Date();
            }
        }
        if (task.status === ScheduledTaskStatus.CANCELLED) {
            if (task.when instanceof Date) {
                return task.when < new Date();
            }
        }
        return false;
    }

    protected _observe(): Observable<ScheduledTaskManagerEvent> {
        let anyCompleteTasks = false;

        // Check if any task should run every second
        return interval(1000).pipe(

            // Cleanup the task list every tick
            tap(() => {
                if (anyCompleteTasks) {
                    this._scheduledTasks = this._scheduledTasks.filter(x => x.status !== ScheduledTaskStatus.COMPLETED);
                    anyCompleteTasks = false;

                    this.updateScheduledTaskInfo();
                }
            }),

            // Figure out which tasks need to run
            switchMap(
                () => of(
                    ...this._scheduledTasks.filter(
                        task => this.shouldTaskRun(task)
                    )
                )
            ),

            tap(scheduledTask => this.optInfo(`Executing task '${scheduledTask.task.name}'...`)),

            // Execute the task
            mergeMap(scheduledTask => {
                const startTime = new Date().getTime();
                scheduledTask.status = ScheduledTaskStatus.EXECUTING;
                scheduledTask.lastRun = new Date();

                // Keep track of when the task was scheduled to start - so we can compare it against .when after running it
                const whenWasTheTaskScheduledToStart = scheduledTask.when;

                this.updateScheduledTaskInfo();

                const task = scheduledTask.task;
                let taskWasCancelled = true;
                let taskFailed = false;

                return scheduledTask.task.execute().pipe(

                    // Handle success
                    map(res => {

                        taskWasCancelled = false;

                        this.optInfo(`Task '${task.name}' was executed in ${(new Date().getTime()) - startTime}ms.`, res);

                        return new ScheduledTaskExecutedEvent(task, res);
                    }),

                    // Handle failure
                    catchError(err => {

                        taskWasCancelled = false;
                        taskFailed = true;

                        this.optError(`Task '${task.name}' had an error`, err);

                        return of(
                            new ScheduledTaskExecutionErrorEvent(task, err)
                        );
                    }),

                    // Repeat if needed
                    finalize(() => {

                        // Store this before making changes below
                        const whenIsTheTaskScheduledToRunNext = scheduledTask.when;

                        // If the task was cancelled then we try again in a few seconds
                        if (taskWasCancelled) {

                            scheduledTask.status = ScheduledTaskStatus.CANCELLED;

                            if (this.shouldRetryTask(scheduledTask)) {
                                this.decrementRetryCountForTask(scheduledTask);

                                scheduledTask.when = new Date(new Date().getTime() + 5000);

                                this.optInfo(`Task '${task.name}' was cancelled, retrying in 5 seconds.`);
                            } else {
                                if (scheduledTask.repeatEveryMs) {
                                    // Run on usual schedule
                                    scheduledTask.when = new Date(
                                        new Date().getTime() + scheduledTask.repeatEveryMs
                                    );
                                    scheduledTask.retryCount = scheduledTask.initialRetryCount;
                                } else {
                                    // Don't run again
                                    scheduledTask.when = undefined;
                                }
                            }
                        } else {

                            if (taskFailed) {
                                scheduledTask.status = ScheduledTaskStatus.FAILED;

                                if (this.shouldRetryTask(scheduledTask)) {
                                    this.decrementRetryCountForTask(scheduledTask);

                                    scheduledTask.when = new Date(new Date().getTime() + 5000);
                                } else {
                                    if (scheduledTask.repeatEveryMs) {
                                        // Run on usual schedule
                                        scheduledTask.when = new Date(
                                            new Date().getTime() + scheduledTask.repeatEveryMs
                                        );
                                        scheduledTask.retryCount = scheduledTask.initialRetryCount;
                                    } else {
                                        // Don't run again
                                        scheduledTask.when = undefined;
                                    }
                                }
                            } else {
                                // Re-queue the task if requested
                                if (scheduledTask.repeatEveryMs) {
                                    scheduledTask.when = new Date(
                                        new Date().getTime() + scheduledTask.repeatEveryMs
                                    );
                                    scheduledTask.status = ScheduledTaskStatus.QUEUED;
                                } else {
                                    // Otherwise we drop it
                                    scheduledTask.status = ScheduledTaskStatus.COMPLETED;
                                    anyCompleteTasks = true;
                                }
                            }

                        }

                        // If the .when property was changed during execution - someone wants to schedule the task again
                        // Must be in the future
                        if (whenIsTheTaskScheduledToRunNext.getTime() !== whenWasTheTaskScheduledToStart.getTime() && whenIsTheTaskScheduledToRunNext >= new Date()) {

                            // Requeue the task
                            if (scheduledTask.status === ScheduledTaskStatus.COMPLETED) {
                                scheduledTask.status = ScheduledTaskStatus.QUEUED;
                            }

                            // Use the .when property given by the user
                            scheduledTask.when = whenIsTheTaskScheduledToRunNext; // Restore state
                        }

                        this.updateScheduledTaskInfo();

                    })
                );
            })

        );

    }

    protected updateScheduledTaskInfo() {
        this._info.next(
            this._scheduledTasks.map(x => {
                return {
                    status: x.status,
                    taskName: x.task.name,
                    when: x.when,
                    lastRun: x.lastRun,
                    repeatEveryMs: x.repeatEveryMs
                };
            })
        );
    }

}
