import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IAppConfiguration } from "@config/external-contractor-login-app.interface";
import { CONFIGURATION_MAPPER, IConfiguration } from "@redrow/configuration";
import { RedrowImageConverterService, RedrowImageResizerService } from "@redrow/ng-services";

import { IInstructionController } from "./IInstructionController";
import { InstructionController } from "./instruction.controller";
import { SharedSettingController } from "./sharedsetting.controller";

@Injectable({
	providedIn: "root"
})
export class BuildIssueController extends InstructionController implements IInstructionController {

	constructor(protected readonly httpClient: HttpClient, protected readonly sharedSettings: SharedSettingController, @Inject(CONFIGURATION_MAPPER) protected readonly configMapper: IConfiguration<IAppConfiguration>, protected readonly imageResizer: RedrowImageResizerService, protected readonly imageConverter: RedrowImageConverterService) {
		super(`buildissue`, httpClient, sharedSettings, configMapper, imageResizer, imageConverter);
	}

}
