/* AUTOEXPORT:SETTING:MODULE:SharedComponentsModule */
import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";

import { RedrowBaseComponent } from "../../base-component";


@Component({
	selector: "rr-icon",
	template: `
		<b
			class="{{ iconset }}-{{ name }}"
			[ngStyle]="{
				'font-size.px': size,
				color: colour,
				'text-align': align,
				display: display
			}"
		></b>
	`,
})
export class RedrowIconComponent extends RedrowBaseComponent {
	@Input() name: string;
	@Input() size: number | "unset" = 30;
	@Input() colour = "unset";
	@Input() align = "inherit";
	@Input() display = "inline-block";
	@Input() iconset = "icon";

	@Output()
	rrClick = new EventEmitter();

	/**
	 * HostListener for click event on element.
	 * @param e Click event.
	 */
	@HostListener("click", ["$event"])
	onClick(e: Event) {
		(document.activeElement as any).blur();

		if (this.disabled) {
			return;
		}
		this.rrClick.emit();
	}
}
