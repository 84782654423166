import { Injectable } from "@angular/core";
import { Observable, observable, of } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class PostMessageService {

    /**
     * Listen for messages.
     */
    public listen(source: Window = window): Observable<MessageEvent> {
        if (typeof window !== "undefined") {
            return new Observable<MessageEvent>(observable => {

                // Workaround for error:
                // source.removeEventListener is not a function
                // Which happens because the window object is cleared after it has been closed
                // But the underlying message stream wants to unsubscribe from the "message" event
                // So we need to provide a custom implementation which checks if the removeEventListener function still exists
                const handler = e => observable.next(e);
                source.addEventListener("message", handler);

                return {
                    unsubscribe() {
                        // We need to check if the window closed before this unsubscribe event - otherwise we'll get a security error thrown
                        if (source.closed) return;

                        // Stop listening for messages
                        source.removeEventListener("message", handler);
                    }
                };
            });

        }
        return of(null);
    }

    /**
     * Post a message.
     * @param message The message to post
     * @param targetOrigin The origin to target
     */
    public postToOrigin<T>(message: T, targetOrigin: string) {
        if (typeof window !== "undefined") {
            window.postMessage(
                message,
                targetOrigin
            );
        }
    }

    public post<T>(message: T, target: Window, targetOrigin: string) {
        target.postMessage(message, targetOrigin);
    }

}