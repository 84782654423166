/* AUTOEXPORT:SETTING:MODULE:ApplicationShellModule */
import { Injectable } from "@angular/core";
import { IRedrowErrorHandler } from "@redrow/utilities";

@Injectable()
export class ServiceWorkerErrorHandler implements IRedrowErrorHandler {

	handleError(error: any): boolean {

		/**
		 * Ignore local service worker errors as they cannot be registered locally.
		 */
		if (error && "message" in error) {
			if ((error.message as string).startsWith("Uncaught (in promise): TypeError: Failed to register a ServiceWorker for scope ('http://localhost:4200/') with script ('http://localhost:4200/ngsw-worker.js'):")) {
				return false;
			}
		}

		return true;
	}

}