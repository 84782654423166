import { Observable, of, Subscription } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { IThrottledActionsOptions } from "./throttle-action-options";
import { IThrottledActionsState } from "./throttle-action-state";

/**
 * Perform a series of actions concurrently - limiting the number that can run in parallel
 * @param actions The actions to peform concurrently
 * @param opts Options that will change how the throttling works
 * @returns An observable that emits state objects based on the throttle settings.
 */
export function ThrottledConcurrentActions<T>(actions: Array<Observable<T>>, opts?: IThrottledActionsOptions): Observable<IThrottledActionsState<T>> {
	return new Observable<IThrottledActionsState<T>>(observable => {
		// Keep the state of actions
		const state: IThrottledActionsState<T> = {
			remaining: actions.length,
			complete: false,
			total: actions.length,
			results: [],
		};

		let sub: Subscription;

		// Zero actions check
		if (actions.length === 0) {
			state.complete = true;
			observable.next(state);
			observable.complete();
		} else {
			// create an observable which wraps the actions into one that gets concurrently run using mergeMap
			const performConcurrentActions = of(...actions).pipe(mergeMap(action => action, opts && typeof opts.concurrencyLimit === "number" ? opts.concurrencyLimit : 5));

			// Subscribe to this observable internally so we can keep track of progress
			sub = performConcurrentActions.subscribe(
				res => {
					state.remaining--;
					state.results.push(res);
					if (state.remaining <= 0) {
						state.complete = true;
						observable.next(state);
						observable.complete();
					} else {
						if (opts && opts.reportProgress === true) {
							// report progress
							observable.next(state);
						}
					}
				},
				err => {
					observable.error(err);
					observable.complete();
				}
			);
		}

		return {
			unsubscribe() {
				if (sub) {
					sub.unsubscribe();
				}
			},
		};
	});
}
