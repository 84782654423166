import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Directive, ElementRef, Inject, Input } from "@angular/core";

@Directive({
    selector: "[rrAutofocus]"
})
export class RedrowAutoFocusDirective implements AfterViewInit {
    private _host: HTMLElement;
    private _focused: Element | null;
    private _autofocus = true;

    @Input()
    set autofocus(value: boolean | string) {
        this._autofocus = coerceBooleanProperty(value);
    }

    protected readonly document: Document;

    constructor(
        private readonly element: ElementRef,
        @Inject(DOCUMENT) private _document: any,
    ) {
        if (typeof this.element.nativeElement.focus !== "function") {
            throw new Error("Html element must be focusable");
        }
        this._host = element.nativeElement;
        this.document = _document;
        this._focused = this.document.activeElement;
    }

    public ngAfterViewInit(): void {
        if (this._autofocus && this._host && this._host !== this._focused) {
            setTimeout(() => this.element.nativeElement.focus());
        }
    }
}
