import { Component, Inject, OnInit, Optional, ViewChild } from "@angular/core";
import { NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from "@angular/router";
import { IAppConfiguration } from "@config/external-contractor-login-app.interface";
import { CONFIGURATION_MAPPER, IConfiguration } from "@redrow/configuration";
import { RedrowBaseComponent } from "@redrow/material";
import { RedrowPlatformDetectionService } from "@redrow/ng-services";
import {
    redLibraryIconCancel,
    redLibraryIconMenu,
    redLibraryIconRedrowLogoRed,
    RedLibraryIconType,
} from "@redrow/ng-redlibrary-icons";
import { ICON_REGISTRY_SERVICE, IIconRegistryService, IRedrowOAuthService, OAUTH_SERVICE } from "@redrow/utilities";
import { IContractorProfile } from "@shared/oauth/contractor-profile.interface";
import { LoadingService } from "@shared/services/loading.service";
import { TokenExpiryLogoutService } from "@shared/services/token-expiry-logout.service";
import { iif, interval, of } from "rxjs";
import { delay, switchMap } from "rxjs/operators";



enum RouteType {
	URL = 1,
	ACTION = 2,
	SPACER = 3
}

enum RouteId {
	DASHBOARD = 1,
	BUILD_ISSUES = 2,
	WORK_INSTRUCTIONS = 3,
	USER_MANAGEMENT = 4,
	DOCUMENTS = 5,
	SETTINGS = 6,
	LOGOUT = 7,
	CALLOFFS = 8,
	ORDERS = 9
}

interface IRoute {
	id: RouteId;
	type: RouteType;
	title?: string;
	url?: string;
	action?: () => void;
	onlyMobile?: boolean;
	onlyDesktop?: boolean;
	hidden?: boolean;
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent extends RedrowBaseComponent implements OnInit {
	title = 'showcase';

	@ViewChild("sidenav", { static: false })
	sidenav: any;

	routes: IRoute[] = [
		{
			id: RouteId.DASHBOARD,
			type: RouteType.URL,
			title: "Dashboard",
			url: "/dashboard",
			onlyMobile: false
		},
		// {
		//	type: RouteType.URL,
		// 	title: "Call Offs",
		// 	url: "/calloffs"
		// },
		{
			id: RouteId.BUILD_ISSUES,
			type: RouteType.URL,
			title: "Build Issues",
			url: "/instructions/build",
			onlyMobile: false
		},
		{
			id: RouteId.WORK_INSTRUCTIONS,
			type: RouteType.URL,
			title: "Work Instructions",
			url: "/instructions/work",
			onlyMobile: false
		},
		{
			id: RouteId.USER_MANAGEMENT,
			type: RouteType.URL,
			title: "User Management",
			url: "/usermanagement",
			onlyMobile: false
		},
		// {
		//  id: RouteId.ORDERS,
		//	type: RouteType.URL,
		// 	title: "Orders",
		// 	url: "/orders"
		// },
		{
			id: null,
			type: RouteType.SPACER,
			onlyDesktop: true
		},

		{
			id: RouteId.DOCUMENTS,
			type: RouteType.URL,
			title: "Documents",
			url: "/documents",
			onlyMobile: false
		},
		{
			id: RouteId.SETTINGS,
			type: RouteType.URL,
			title: "Profile",
			url: "/profile",
			onlyMobile: false
		},
		{
			id: null,
			type: RouteType.SPACER,
			onlyMobile: true
		},
		{
			id: RouteId.LOGOUT,
			type: RouteType.ACTION,
			title: "Logout",
			action: () => this.logout(),
			onlyMobile: true
		}
	];

	get showMenuCloseButton(): boolean {
		return (this.sidenavOpening || this.sidenavOpen) && !this.sidenavClosing;
	}

	logout() {
		this.oauthService.logout();
	}

	getRoute(id: RouteId) {
		return this.routes.find(x => x.id === id);
	}

	isLazyLoading: boolean;
	lazyLoadingCount: number = 0;

	public isLoading: boolean = false;
	public appName: string;
	public appVersion: string;
	public showAppVersion: boolean = false;
	public _showAppVersionTick: number = 0;
	public _showAppVersionTimer: number;

	clickForAppVersion() {
		clearTimeout(this._showAppVersionTimer);
		this._showAppVersionTick++;
		if (this._showAppVersionTick > 5) {
			this.showAppVersion = !this.showAppVersion;
		}
		this._showAppVersionTimer = window.setTimeout(() => this._showAppVersionTick = 0, 750);
	}

	constructor(
		protected readonly router: Router,
		protected readonly platformDetectionService: RedrowPlatformDetectionService,
		protected readonly loadingService: LoadingService,
		@Inject(OAUTH_SERVICE) protected readonly oauthService: IRedrowOAuthService<IContractorProfile>,
		@Inject(CONFIGURATION_MAPPER) protected readonly configMapper: IConfiguration<IAppConfiguration>,
		protected readonly tokenExpiryLogoutService: TokenExpiryLogoutService,
		@Optional() @Inject(ICON_REGISTRY_SERVICE) protected readonly iconRegistry: IIconRegistryService<RedLibraryIconType>,
	) {

		super();

		const config = configMapper.get();

		this.appName = config.appName;
		this.appVersion = config.LOCAL_VERSION;

		/**
		 * Make some values available on the Window for debugging purposes
		 */
		if (typeof window !== "undefined") {
			window["redrow"] = window["redrow"] || {};
			window["redrow"].version = config.LOCAL_VERSION;
			window["redrow"].apiUrl = config.apiUrl;
			window["redrow"].environment = config.environment;
		}

		this.ngSubscriptions.add(
			platformDetectionService.observePlatform().subscribe(x => this.desktop = x === "desktop")
		);

		if (this.iconRegistry) {
			this.iconRegistry.addSvg(redLibraryIconRedrowLogoRed);
			this.iconRegistry.addSvg(redLibraryIconCancel);
			this.iconRegistry.addSvg(redLibraryIconMenu);
		}

		/**
		 * Listen to profile changes - we want to update the routes based on permissions
		 */
		this.ngSubscriptions.add(
			this.oauthService.getUserProfile().subscribe(
				user => {

					if (user) {
						this.getRoute(RouteId.BUILD_ISSUES).hidden = !user.BuildIssueApi.list;
						this.getRoute(RouteId.WORK_INSTRUCTIONS).hidden = !user.WorkInstructionApi.list;
						this.getRoute(RouteId.USER_MANAGEMENT).hidden = !user.UserManagementApi.list;
					}

				}
			)
		);

		/**
		 * Logout if our token has expired.
		 */
		this.ngSubscriptions.add(
			interval(1000).subscribe(() => this.tokenExpiryLogoutService.logoutIfTokenExpired())
		);
	}

	// protected refreshTheme() {
	// 	const theme = this.desktop ? REDROW_DESKTOP_THEME : REDROW_TABLET_THEME;
	// 	if (this.themeService.getActiveTheme() != theme) {
	// 		this.themeService.setActiveTheme(theme);
	// 	}
	// 	// themeService
	// }


	public desktop: boolean = false;
	public sidenavOpen: boolean = false;
	public sidenavOpening: boolean = false;
	public sidenavClosing: boolean = false;

	ngOnInit() {
		this.router.events.subscribe(
			event => {
				if (event instanceof NavigationStart) {
					this.sidenavOpen = false;

				}
			}
		);

		/**
		 *
		 * Listen for loading events
		 */
		this.ngSubscriptions.add(
			this.loadingService.isLoading().pipe(
				switchMap(x => iif(
					() => !x,
					of(x).pipe(delay(500)),
					of(x)
				))
			).subscribe(
				loading => setTimeout(() => this.isLoading = loading, 1)
			)
		);

		/**
		 * Listen for lazy loaded routes so we can display a loading spinner
		 */
		this.ngSubscriptions.add(
			this.router.events.subscribe(
				event => {
					if (event instanceof RouteConfigLoadStart) {
						// Start lazy loading
						this.lazyLoadingCount++;
					} else if (event instanceof RouteConfigLoadEnd) {
						// Lazy loading end
						this.lazyLoadingCount--;
					}

					this.isLazyLoading = !!this.lazyLoadingCount;
				}
			)
		);
	}

}
