import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Optional } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { RedrowPlatformDetectionType } from "./types";

/**
 * Service for detecting and observing which platform an application is running on via the browser.
 * Currently categorises platforms as @see RedrowPlatformDetectionType
 * 
 * <ng-services-platform-detection-demo></ng-services-platform-detection-demo>
 */
@Injectable({
	providedIn: "root"
})
export class RedrowPlatformDetectionService {

	// Reference to navigator interface
	protected readonly navigatorRef: Navigator;

	// BehaviorSubject so we can observe when the mobile rating chhanges
	protected readonly mobileRating: BehaviorSubject<number>;

	constructor(
		@Optional() @Inject(DOCUMENT) protected readonly _document: Document
	) {
		this.navigatorRef = _document?.defaultView?.navigator;
		this.mobileRating = new BehaviorSubject<number>(this.calculateMobileRating());
	}

	/**
	 * Observe the application's platform type
	 * @returns platform type @see RedrowPlatformDetectionType
	 */
	public observePlatform(): Observable<RedrowPlatformDetectionType> {
		return this.mobileRating.asObservable()
			.pipe(
				map(mobileRating => {

					// Determine platform
					const platform: RedrowPlatformDetectionType = (
						(mobileRating > 0.5) ?
							"mobile" :
							"desktop"
					);

					return platform;
				})
			);
	}

	/**
	 * Function that will calculate a rating that the current platform is mobile.
	 * There aren't any bullet proof solutions for working out platform so a rating of:
	 * 0 = More likely to be desktop
	 * 1 = More likely to be mobile
	 */
	protected calculateMobileRating(): number {

		// Initial rating for the platform being mobile
		let currentMobileRating: number = 0;

		// Initial amount of tests for the platform being mobile
		let currentMobileTestsPerformed: number = 0;

		if (this.isUserAgentDataInNavigator(this.navigatorRef)) {
			if (this.navigatorRef.userAgentData.mobile) {
				currentMobileRating++;
				currentMobileTestsPerformed++;
			}
			if (this.navigatorRef.userAgentData.platform.toLowerCase() !== "windows") {
				currentMobileRating++;
				currentMobileTestsPerformed++;
			}
		}
		if (this.isMaxTouchPointsInNavigator(this.navigatorRef)) {
			if (this.navigatorRef.maxTouchPoints) {
				currentMobileRating++;
				currentMobileTestsPerformed++;
			}
		}

		// Calculate and return rating based on number of tests performed
		return currentMobileTestsPerformed == 0 ? 0 : currentMobileRating / currentMobileTestsPerformed;
	}

	/**
	 * Property "userAgentData" is experimental so we want to check if this exists on the navigator interface
	 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData#browser_compatibility
	 */
	protected isUserAgentDataInNavigator(navigatorRef: any): navigatorRef is {
		userAgentData: {
			mobile: boolean;
			platform: string;
		}
	} {
		return (
			!!navigatorRef &&
			"userAgentData" in navigatorRef
		);
	}

	/**
	 * Does max touch points exist on the navigator interface
	 */
	protected isMaxTouchPointsInNavigator(navigatorRef: any): navigatorRef is {
		maxTouchPoints: number;
	} {
		return (
			!!navigatorRef &&
			"maxTouchPoints" in navigatorRef
		);
	}
}