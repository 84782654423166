import { InjectionToken } from "@angular/core";

/**
 * Provides an interface to include legacy app settings in the new configuration system so we don't break old apps
 * @param legacySettings
 */
export function LegacyAppSettings(legacySettings: Partial<LegacyAppConfig>): LegacyAppConfig {
	return legacySettings as LegacyAppConfig;
}

/* tslint:disable:interface-name */
interface LegacyAppConfig {
	LOCAL_VERSION?: string;

	// App
	AppId: string;
	AppName?: string;

	// Version
	Version: string;
	Revision: string;

	/**
	 * Which namespace should the token be stored in?
	 * @default default
	 */
	TokenNamespace?: string;

	// number of console logs to keep in buffer for debugging purposes
	// 15 by default
	ConsoleBufferLength?: number;

	// Should logs be printed to the browsers console?
	// defaults to true
	PrintToBrowserConsole?: boolean;

	// Should logs be printed to the browsers console on mobile?
	// defaults to false
	PrintToBrowserConsoleOnMobile?: boolean;

	// Defaults to true
	UseEnvironmentBackgroundColour?: boolean;

	// Http
	Timeout: {
		Time: number;
		Message: string;
	};

	OnlineCheckUrl?: string; // https://redrow.co.uk

	// API
	Api: {
		Url: string; // https://api.redrow.co.uk/

		OnlineStatusCheckUrl?: string; // /check/online
	};

	TitleConfig?: {
		// AppConfig available as the rr-toolbar component is a child of RRBaseComponent (so other things like disabled will be available)
		// TitleElement is a reference to the native element of the title section in rr-toolbar
		format: string; // default: "${AppConfig.AppName} - ${TitleElement.textContent}"

		// format used when title element is not available
		fallbackFormat: string; // default: "${AppConfig.AppName}"

		enabled: boolean; // default: true
	};

	/**
	 * When using the inbuilt logout feature, which url should the app be directed to?
	 * @default /logout
	 * @see rr-sidebar.service.ts
	 */
	LogoutUrl?: string;

	/**
	 * Settings that override rrCookieStorageService settings.
	 */
	Cookies?: {
		secure?: boolean; // defaults to true
		domain?: string; // defaults to Api.Url (if an absolute url e.g. http://www.... instead of /api/)
		path?: string; // defaults to /AppName
	};

	Debug?: boolean;

	// TODO: Inject this during app config binding
	Environment: {
		mode: "Local" | "Development" | "Test" | "Live" | "Train" | "Test 2" | "Test 3" | "Dev Azure" | "Test Azure";
		production: boolean;
		allowDebug?: false;
		API?: string;
		PublicKey?: string;
		showDebugLabels?: boolean;
		mock?: boolean;
	};

	/**
	 * Control theme behaviour for a specific app
	 */
	Theme?: {
		defaultThemeName?: string;
		blackListedThemeNames?: string[];
		whiteListedThemeNames?: string[];
	};

	// Logging settings, default to false
	enableLoggingInTest?: boolean;
	enableLoggingInLive?: boolean;

	// Define the logging level for various categories
	LogCategories?: {
		[category: string]: any;
	};
}

export const LEGACY_APP_CONFIG = new InjectionToken<LegacyAppConfig>("APP_CONFIG");
