import { AccessLevel, IRedrowTokenBase } from "@redrow/utilities";

export class RedrowTokenBase implements IRedrowTokenBase {
	protected _raw: { [key: string]: any };

	constructor(raw: any) {
		this._raw = raw;
	}

	get userId() {
		return +this._raw.user_id;
	}

	get departmentCode() {
		return this._raw.dept_code;
	}

	get regionId() {
		return +this._raw.region_id;
	}

	get accessLevel(): AccessLevel {
		return +this._raw.access_level as AccessLevel;
	}

	get outletId() {
		return +this._raw.outlet_id;
	}
}
