import { InjectionToken } from "@angular/core";

/**
 * @deprecated Please see {@page ~~/wiki/solutions/authentication/oauth-quickstart.md} for how to configure OAuth.
 */
export type ProfileClassType = (rawData: object) => void;

/**
 * @deprecated Please see {@page ~~/wiki/solutions/authentication/oauth-quickstart.md} for how to configure OAuth.
 */
export const OAUTH_PROFILE_CLASS = new InjectionToken<ProfileClassType>("OAUTH_PROFILE_CLASS");

