import { BaseExternalOAuthConfig, IOAuthClientConfig } from "@redrow/oauth";
import { AppConfigurationBase } from "./external-contractor-login-app.interface";
import buildSettings from "../../build-settings.json";

export const CONFIGURATION_BASE: AppConfigurationBase = {
	LOCAL_VERSION: `${buildSettings.pipelineBuildNumber} ${buildSettings.pipelineBuildName}`,
	appName: "Subcontractor Portal",
	Version: "1.03",
	Revision: "a",
	appId: "subby-2020"
};

export const OAUTH_BASE: IOAuthClientConfig = BaseExternalOAuthConfig({
	clientId: "contractorsportal.redrow.co.uk",
	scope: "was-subcontractors.redrow.co.uk UserManagementApi WorkInstructionApi BuildIssueApi",
	sessionChecksEnabled: false
})