import { Component, OnInit } from "@angular/core";
import { RedrowBaseComponent } from "@redrow/material";
import { DocumentController } from "@shared/api/document.controller";
import { UserMessageService } from "@shared/services/user-message-service.service";
import { iif, of, throwError } from "rxjs";
import { concatMap, delay, retryWhen } from "rxjs/operators";
import { IASiteDocument } from "src/shared/api/IASiteDocument.interface";

import { ErrorContext } from "../../shared/services/user-message-service.service";



@Component({

	templateUrl: `./documents.component.html`,
	styleUrls: [
		`./documents.component.scss`
	]
})
export class DocumentsComponent extends RedrowBaseComponent implements OnInit {

	public documents: IASiteDocument[];

	constructor(
		protected readonly documentController: DocumentController,
		protected readonly userMessageService: UserMessageService
	) {
		super();
	}

	ngOnInit() {
		this.refresh();
	}

	refresh() {
		this.loading = true;
		this.error = null;

		this.ngSubscriptions.add(
			this.documentController.listDocuments().pipe(
				retryWhen(errors => errors.pipe(
					concatMap(
						(e, i) => iif(
							() => i > 4,
							throwError(e),
							of(e).pipe(delay(500))
						)
					)
				)),
			).subscribe(
				documents => this.documents = documents,
				err => {
					this.error = "Unable to load documents right now, please try again later.";
					this.loading = false;

					this.ngSubscriptions.add(
						this.userMessageService.showErrorNotice(err, ErrorContext.LOADING).subscribe()
					);
				},
				() => this.loading = false
			)
		);
	}

	openDocument(document: IASiteDocument) {
		if (document && document.directLink) {
			window.open(document.directLink, "_blank");
		}
	}


}
