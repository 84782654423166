import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Component, ElementRef, OnDestroy } from "@angular/core";
import { redLibraryIconPerson } from "@redrow/ng-redlibrary-icons";
import { Constructor, mixinNgSubscriptions, NgSubscriptionsCtor } from "@redrow/utilities";

import { RedrowProfilePopoverComponent } from "./profile-popover.component";

class RedrowProfileComponentBase {
    constructor() { }
}

const _RedrowProfileComponentBase: Constructor<OnDestroy> & NgSubscriptionsCtor & typeof RedrowProfileComponentBase = mixinNgSubscriptions(RedrowProfileComponentBase);

/**
 * Renders a button which when clicked shows the users profile in a popover component. See {@link RedrowProfilePopoverComponent}.
 * 
 * <ng-material-components-profile-demo></ng-material-components-profile-demo>
 */
@Component({
    selector: "rr-profile",
    templateUrl: `./profile.component.html`,
    styleUrls: [
        `./profile.component.scss`
    ]
})
export class RedrowProfileComponent extends _RedrowProfileComponentBase implements OnDestroy {

    public readonly profileIcon = redLibraryIconPerson;
    protected _overlayRef: OverlayRef;

    constructor(
        protected readonly overlay: Overlay,
        protected readonly elementRef: ElementRef
    ) {
        super();


    }

    protected setupOverlay(): void {
        if (this._overlayRef) {
            return;
        }

        this._overlayRef = this.overlay.create({
            hasBackdrop: true,
            disposeOnNavigation: true,
            positionStrategy: this.overlay.position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    // Display at the top right
                    {
                        originX: "end",
                        originY: "bottom",
                        overlayX: "end",
                        overlayY: "top",

                    }
                ]),
            scrollStrategy: this.overlay.scrollStrategies.block()
        });

        this.ngSubscriptions.add(
            this._overlayRef.backdropClick().subscribe(
                () => this.hideProfile()
            )
        );
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        this._overlayRef.dispose();
    }

    public toggleProfile() {
        if (this._overlayRef?.hasAttached()) {
            this.hideProfile();
        } else {
            this.showProfile();
        }
    }

    public showProfile() {

        this.setupOverlay();

        const userProfilePortal = new ComponentPortal(RedrowProfilePopoverComponent);
        this._overlayRef.attach(userProfilePortal);

    }

    public hideProfile() {
        this._overlayRef.detach();
    }

}
