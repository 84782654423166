import { HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { CONFIGURATION_MAPPER, IConfiguration } from "@redrow/configuration";
import {
    IApplicationConfig,
    IDiscoveryDocument,
    ILogger,
    IOAuthError,
    IOAuthIdentityToken,
    IRedrowOAuthService,
    IRedrowProfile,
} from "@redrow/utilities";
import { AuthConfig, OAuthErrorEvent, OAuthService as AngularOAuthOpenIdService } from "angular-oauth2-oidc";
import { BehaviorSubject, defer, Observable, of, Subject, Subscription, throwError } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";

import { CreateOAuthError } from "../../../private/_deprecated/functions/oauth-error-details.function";
import { IRedrowLoginOptions } from "../../../private/_deprecated/interfaces/redrow-login-options.interface";
import { OAUTH_LOGGER } from "../../../private/_deprecated/tokens/oauth-logger.token";
import {
    REFRESH_TOKEN_CHECK_BLACKLIST,
    REFRESH_TOKEN_CHECK_ENABLED,
} from "../../../private/_deprecated/tokens/refresh-token-check-blacklist.token";
import { IRedrowOauthUserProfile } from "../../../private/interfaces/oauth-user-profile.interface";
import { OAuthIdentityToken } from "../../../private/models/oauth-token-identity.model";
import { OAuthIFrameParamInjectionFlow } from "../../../private/services/oauth-iframe-param-injection-flow.service";
import { IOAuthClientConfig } from "../interfaces/application-config-with-oauth.interface";
import { IAuthConfig } from "../interfaces/oauth-config.interface";
import { OAUTH_CONFIG } from "../tokens/oauth-config.token";
import { OAUTH_PROFILE_CLASS, ProfileClassType } from "../tokens/profile.token";


// TODO
// https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/configure-custom-oauthstorage.html

const DISCOVERY_EVENTS = ["discovery_document_loaded", "discovery_document_load_error", "discovery_document_validation_error"];

/**
 * Initial implementation of oauth for frontend apps.
 * 
 * Used in Inspection Portal and Subcontractor Portal.
 * 
 * @deprecated Please see {@page ~~/wiki/solutions/authentication/oauth-quickstart.md} for how to configure OAuth.
 */
@Injectable({
	providedIn: "root",
})
// Can't extend RRBase as it injects oauth token parser/storage which use this service
export class OAuthService<ProfileInterface = IRedrowProfile> implements IRedrowOAuthService<ProfileInterface> {
	// extends RRBase {

	protected initFlag = false;

	public errors: Subject<IOAuthError>;
	public sessionEnd: Subject<void>;

	public userProfile: BehaviorSubject<ProfileInterface>;
	public discoveryDocument: BehaviorSubject<IDiscoveryDocument>;
	protected needToRefreshUserProfile = false;
	protected forceRefreshUserProfile = false;
	protected refreshingUserProfile = false;
	protected hasDiscoveryDocumentLoaded: boolean = false;
	protected loadingDiscoveryDocument: boolean = false;

	public isLoggingIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		protected readonly AngularOAuthOpenIdService: AngularOAuthOpenIdService,
		@Inject(OAUTH_LOGGER) protected readonly logger: ILogger,
		@Optional() @Inject(CONFIGURATION_MAPPER) protected readonly config: IConfiguration<IApplicationConfig & IOAuthClientConfig>,
		@Optional() @Inject(OAUTH_CONFIG) protected readonly oauthConfig: IAuthConfig,
		@Optional() protected readonly iframeFlow: OAuthIFrameParamInjectionFlow,
		@Inject(OAUTH_PROFILE_CLASS) protected readonly ProfileClass: ProfileClassType,

		@Optional() @Inject(REFRESH_TOKEN_CHECK_BLACKLIST) protected readonly refreshTokenKeyBlackList: string[],
		@Optional() @Inject(REFRESH_TOKEN_CHECK_ENABLED) protected readonly refreshTokenCheckEnabled: boolean
	) {
		this.userProfile = new BehaviorSubject<ProfileInterface>(null);
		this.discoveryDocument = new BehaviorSubject<IDiscoveryDocument>(null);
		this.errors = new Subject<IOAuthError>();
		this.sessionEnd = new Subject<void>();

		this.AngularOAuthOpenIdService.events.subscribe(async event => {
			this.logger.debug(`OAuth Event '${event.type}'`);

			if (event.type === "discovery_document_load_error" || event.type === "discovery_document_validation_error") {
				this.loadingDiscoveryDocument = false;
			}

			if (event.type === "discovery_document_loaded") {
				this.hasDiscoveryDocumentLoaded = true;
				this.loadingDiscoveryDocument = false;

				/**
				 * If we have a new discovery document - then store it here
				 */
				if ("info" in event) {
					if ((event as any).info && (event as any).info.discoveryDocument) {
						this.discoveryDocument.next((event as any).info.discoveryDocument);
					}
				}

				return;
			}

			// If the token gets silently refreshed - we want to invalidate the cached profile and get another one just in case our access has changed
			if (event.type === "silently_refreshed" || event.type === "token_refreshed") {
				if (this.hasValidLogin()) {
					await this.refreshUserProfile(true);
				}

				return;
			}

			// The user has been logged out externally - we need to restart the app
			if (event.type === "session_terminated") {
				// TODO - show modal informing the user
				// refresh app
				this.sessionEnd.next();
				return;
			}

			// Handle invalid_grant error
			if (event instanceof OAuthErrorEvent) {
				const httpError = event.reason as HttpErrorResponse;
				if (event.type === "token_refresh_error") {
					if (event.reason && httpError.status === 400 && httpError.error && httpError.error.error === "invalid_grant") {
						// This likely means the token has been removed from the backend database - with the intension of logging out the user
						this.logger.info(`User no longer has a valid refresh token - logging out the user.`);
						this.logout();
					}
				} else if (event.type === "user_profile_load_error") {
					if (event.reason && httpError.status === 401) {
						// This probably means the users access has been revoked or the application is no longer available
						this.logger.info(`User no longer has a valid refresh token - logging out the user.`);
						this.logout();
					}
				}
			}

			// Handle errors in a generic way
			if (event.type.endsWith("_error")) {
				this.errors.next(CreateOAuthError(event.type));
			}
		});
	}

	/**
	 * The purpose of this function is to keep the access_token up to date with changes made after issue.
	 * As the user profile might get updated before a refresh of the token - we can compare the identity_token
	 * with the user profile to see if anything changes - then if so we will try to refresh the access_token
	 * to hopefully keep it up to date
	 * 
	 * @argument identity The identity claims before the profile was updated
	 */
	protected checkRefreshTokenAfterProfileUpdate(identity: object) {
		if (!this.refreshTokenCheckEnabled) {
			return false;
		}

		if (!identity) {
			return false;
		}

		const userProfile = this.getCachedUserProfile();
		if (!userProfile) {
			return false;
		}

		if (!Array.isArray(this.refreshTokenKeyBlackList)) {
			this.logger.warn(`refreshTokenKeyBlackList should be a list of strings.`);
			return false;
		}

		const rawUserProfile = userProfile["_raw"];

		/**
		 * Finally compare the User Profile keys with the Identity keys
		 */
		const identityKeys = Object.keys(identity);
		for (const key of identityKeys) {

			// Ignore keys on the black list
			if (Array.isArray(this.refreshTokenKeyBlackList) && this.refreshTokenKeyBlackList.indexOf(key) > -1) {
				continue;
			}

			const a = identity[key];
			const b = rawUserProfile[key];

			let cmp = false;
			if (Array.isArray(a) || Array.isArray(b) || typeof a === "object" || typeof b === "object") {
				cmp = JSON.stringify(a) === JSON.stringify(b);
			} else {
				cmp = a === b;
			}

			// Try to compare
			if (!cmp) {
				this.logger.debug(`identity.${key} does not match profile.${key}`, a, b);
				return true;
			}

		}

		return false;

	}

	protected ensureInit(): boolean {
		if (this.initFlag) {
			return true;
		}
		this.logger.warn(
			`
				Trying to use oauth service before the init function has been called!
				As the service hasn't been configured yet this won't work.
				This is likely a legacy problem if this warning happens at the beggining of an app.
				You must pass the oauth guard or the login component before using this service.
			`
		);
		return false;
	}

	public async login(opts?: IRedrowLoginOptions): Promise<boolean> {

		// Cannot do multiple logins at the same time
		if (this.isLoggingIn.value) {
			this.logger.warn(`Trying to login multiple times!`);
			console.trace();
			return false;
		}

		this.logger.debug(`login`, opts);

		let asyncCancelTokenSub: Subscription;

		try {

			let subs: Subscription = new Subscription();
			let asyncTokenCancelled = false;

			// Allow the caller to cancel the async function based on an observable
			if (opts && opts.asyncCancelToken) {
				asyncCancelTokenSub = opts.asyncCancelToken.subscribe(
					null, null, () => {
						if (subs && !subs.closed) {
							subs.unsubscribe();
							subs = undefined;
						}
						asyncTokenCancelled = true;
					}
				);
			}

			this.isLoggingIn.next(true);

			// If the iframe flow is enabled - try inject that here now
			if (this.iframeFlow) {
				this.logger.info(`Starting iframe flow...`);
				try {
					const success = await this.iframeFlow.tryInjectParams(["code", "scope", "session_state", "state"], opts ? opts.asyncCancelToken : null);

					if (asyncTokenCancelled) {
						return false;
					}

					this.logger.info(`iframe flow: ${success}`);
				} catch (e) {
					this.logger.error(`iframe flow failed`, e);
					throw e;
				}
			}

			const result = await this.AngularOAuthOpenIdService.tryLogin(opts);

			if (asyncTokenCancelled) {
				return false;
			}

			this.logger.debug(`login result`, result);

			if (!this.AngularOAuthOpenIdService.hasValidIdToken() || !this.AngularOAuthOpenIdService.hasValidAccessToken()) {
				// If we are using the iframe flow - then fail here to stop code leakage
				if (this.iframeFlow) {
					return false;
				}

				this.logger.debug(`The client does not have a valid token, starting the login flow...`);

				// Try to login using redirects
				this.AngularOAuthOpenIdService.initLoginFlow();
				return false;
			}

			this.logger.debug(`The client has valid credentials - refreshing the user profile.`);

			if (asyncTokenCancelled) {
				return false;
			}

			// After logging in we want to get the user profile
			// Don't actually need this as a token_refreshed event is thrown causing this to happen anyway
			// await this.refreshUserProfile(true);

			return result;

		}
		catch (e) {
			throw e;
		}
		finally {

			if (asyncCancelTokenSub && !asyncCancelTokenSub.closed) {
				asyncCancelTokenSub.unsubscribe();
				asyncCancelTokenSub = undefined;
			}

			this.isLoggingIn.next(false);
		}
	}

	public async ensureDiscoveryDocumentHasLoaded(): Promise<boolean> {
		// Can't do anything until init has been called
		if (!this.initFlag) {
			return false;
		}

		// Check if the discovery document has already been loaded
		if (this.hasDiscoveryDocumentLoaded) {
			return true;
		}

		this.logger.debug(`Request to load discovery document.`);

		// If it isn't already loading - start that process now
		if (!this.loadingDiscoveryDocument) {
			this.loadingDiscoveryDocument = true;
			this.logger.debug(`Loading discovery document...`);
			try {
				await this.AngularOAuthOpenIdService.loadDiscoveryDocument();
				return true;
			} catch (e) {
				this.logger.error(`Failed to load discovery document`, e);
				throw e;
			}
		}

		// wait for a discovery document event
		return this.AngularOAuthOpenIdService.events
			.pipe(
				filter(x => DISCOVERY_EVENTS.indexOf(x.type) > -1),
				switchMap(y => {
					// If it wasn't loaded - throw an error
					if (y.type !== "discovery_document_loaded") {
						return throwError(new Error("Failed to load discovery document"));
					}

					// Otherwise return true
					return of(true);
				})
			)
			.toPromise();
	}

	public async init(): Promise<boolean> {
		if (!this.initFlag) {
			this.initFlag = true;

			this.logger.debug(`init`);

			let clientConfig: AuthConfig;
			if (this.config.get() && this.config.get().OAuthClient) {
				clientConfig = this.config.get().OAuthClient;
			}

			// Try to configure through application config
			if (clientConfig) {
				this.AngularOAuthOpenIdService.configure(clientConfig);
			} else {
				this.logger.error(
					`
						!!! Missing OAuthClient config !!!
						Did you forget to add oauth config to your app configuration?
					`
				);
			}

			this.AngularOAuthOpenIdService.customQueryParams = {};

			// If the app config is available - pass various details to the oauth server
			if (this.config.get()) {
				this.AngularOAuthOpenIdService.customQueryParams = {
					...this.AngularOAuthOpenIdService.customQueryParams,
					clientAppVersion: this.config.get().Version,
					clientAppRevision: this.config.get().Revision,
					clientAppBuild: this.config.get().LOCAL_VERSION,
				};
			}

			// Inject oauth config values
			if (this.oauthConfig) {
				if (typeof this.oauthConfig.requireOutlet === "boolean") {
					this.AngularOAuthOpenIdService.customQueryParams["clientRequiresOutlet"] = this.oauthConfig.requireOutlet;
				}

				if (typeof this.oauthConfig.requireDivision === "boolean") {
					this.AngularOAuthOpenIdService.customQueryParams["clientRequiresDivision"] = this.oauthConfig.requireDivision;
				}

				if (typeof this.oauthConfig.loginType === "string") {
					this.AngularOAuthOpenIdService.customQueryParams["clientLoginType"] = this.oauthConfig.loginType;
				}
			}

			// Make sure we have discovery document
			try {
				await this.ensureDiscoveryDocumentHasLoaded();
			} catch (e) {
				this.logger.error(`Failed to load discovery document in init`, e);
			}

			// openUri

			// Refresh token
			if (
				clientConfig &&
				clientConfig.scope
					.toLowerCase()
					.split(" ")
					.indexOf("offline_access") > -1
			) {
				this.logger.debug(`Setting up automatic silent refresh...`);
				this.AngularOAuthOpenIdService.setupAutomaticSilentRefresh();
			}
		}

		return true;
	}

	public getGrantedScopes() {
		return this.AngularOAuthOpenIdService.getGrantedScopes();
	}

	public getAccessTokenExpiry(): Date {
		return new Date(this.AngularOAuthOpenIdService.getAccessTokenExpiration());
	}

	/**
	 * Tries to get the users identity via id_token
	 */
	public getUserIdentity(): IOAuthIdentityToken {
		return new OAuthIdentityToken(this.AngularOAuthOpenIdService.getIdentityClaims());
	}

	protected setCachedUserProfile(profile: ProfileInterface) {
		if (this.userProfile.value !== profile) {
			this.userProfile.next(profile);
		}
	}

	public getCachedUserProfile() {
		return this.userProfile.value;
	}

	public async refreshUserProfile(force: boolean = false) {
		// Can't do anything until init has been called
		if (!this.initFlag) {
			return false;
		}

		if (this.refreshingUserProfile) {
			this.needToRefreshUserProfile = true;
			if (force) {
				this.forceRefreshUserProfile = true;
			}
			return false;
		}
		try {
			this.refreshingUserProfile = true;

			this.logger.debug(`Refreshing user profile...`);

			// Make sure we have a valid discovery document before loading the profile
			await this.ensureDiscoveryDocumentHasLoaded();

			// Now attempt to load the profile
			this.logger.debug(`Loading user profile...`);
			const identity = this.AngularOAuthOpenIdService.getIdentityClaims();
			const profile: IRedrowOauthUserProfile = (await this.AngularOAuthOpenIdService.loadUserProfile()) as IRedrowOauthUserProfile;
			if (profile?.info) {
				this.setCachedUserProfile(new this.ProfileClass(profile.info));

				// Check if the access_token doesn't match the user profile
				if (this.checkRefreshTokenAfterProfileUpdate(identity)) {
					this.logger.info(`User profile doesn't match the access_token - trying to refresh token`);
					await this.AngularOAuthOpenIdService.refreshToken();
				}
			}
		} catch (err) {
			this.logger.error(`Failed to load user profile`, err);
		} finally {
			this.refreshingUserProfile = false;



			if (this.needToRefreshUserProfile) {
				setTimeout(() => {
					if (this.needToRefreshUserProfile) {
						this.needToRefreshUserProfile = false;

						// Only refresh if we failed to get the user profile before
						if (this.userProfile.value === null || typeof this.userProfile.value === "undefined" || this.forceRefreshUserProfile) {
							this.forceRefreshUserProfile = false;
							this.refreshUserProfile();
						}
					}
				}, 100);
			}
		}
		return true;
	}

	/**
	 * Tries to get the users profile using the userinfo endpoint
	 * @param force Set to true if you want to force the userinfo endpoint to be hit - ignore any cached value
	 */
	public getUserProfile(force: boolean = false): BehaviorSubject<ProfileInterface> {
		// Silent refresh
		if (this.userProfile.value === null || typeof this.userProfile.value === "undefined") {
			this.refreshUserProfile(force);
		}

		return this.userProfile;
	}

	/**
	 * Check if the current user credentials are valid
	 */
	public hasValidLogin() {
		this.logger.debug("this.AngularOAuthOpenIdService.hasValidIdToken()", this.AngularOAuthOpenIdService.hasValidIdToken());
		this.logger.debug("this.AngularOAuthOpenIdService.hasValidAccessToken()", this.AngularOAuthOpenIdService.hasValidAccessToken());
		if (!this.AngularOAuthOpenIdService.hasValidIdToken() || !this.AngularOAuthOpenIdService.hasValidAccessToken()) {
			return false;
		}

		return true;
	}

	public logout(noRedirectToLogoutPage: boolean = false) {
		this.logger.debug(`Logging out...`);
		return this.AngularOAuthOpenIdService.logOut(noRedirectToLogoutPage);
	}

	/**
	 * Tries to refresh the users token so that their outlet is the same as the chosen one.
	 * Some implementation details which might be useful to know:
	 * - Will use any existing refresh_token to inidate a request to the oauth server in the background
	 * - The outlet id must be within the currently logged in region - to change region & outlet the user must login again
	 */
	public changeOutlet(outletId: number): Observable<boolean> {
		/**
		 * Sanity check - outletId should be a number & not be less than 0
		 */
		if (typeof outletId !== "number" || outletId < 0) {
			return of(false);
		}

		this.AngularOAuthOpenIdService.customQueryParams["changeOutletId"] = outletId;

		return defer(() => this.AngularOAuthOpenIdService.refreshToken()).pipe(
			catchError(err => {
				// Stop requesting a change in outlet
				delete this.AngularOAuthOpenIdService.customQueryParams["changeOutletId"];

				return of(false);
			}),
			switchMap(obj => {
				// Stop requesting a change in outlet
				delete this.AngularOAuthOpenIdService.customQueryParams["changeOutletId"];

				this.logger.debug(`changeOutlet`, obj);

				return of(true);
			})
		);
	}
}
