<ip-page-shell [loading]="loading" [error]="error">
	<!-- PAGE CONTENT-->

	<div class="profile-content">
		<h2>{{ editMode ? "Edit" : "View" }} Profile</h2>

		<form id="profile-form" [formGroup]="form" (ngSubmit)="saveProfile()" class="edit-profile-form">
			<!-- Forename -->
			<mat-form-field filter-field-item appearance="outline">
				<mat-label>Forename</mat-label>
				<input #forenameInput [maxLength]="100" type="text" matInput formControlName="forename" />

				<!-- VALIDATION ERRORS -->
				<mat-error *ngIf="f?.forename?.errors?.required"> Forename is required </mat-error>

				<mat-error *ngIf="f?.forename?.errors?.minlength"> Forename too short </mat-error>

				<mat-error *ngIf="f?.forename?.errors?.pattern"> Forename must only contain characters from the alphabet </mat-error>

				<mat-hint *ngIf="editMode" align="end">{{ forenameInput?.value?.length || 0 }}/100</mat-hint>
			</mat-form-field>

			<!-- Surname -->
			<mat-form-field filter-field-item appearance="outline">
				<mat-label>Surname</mat-label>
				<input #surnameInput [maxLength]="100" type="text" matInput formControlName="surname" />

				<!-- VALIDATION ERRORS -->
				<mat-error *ngIf="f?.surname?.errors?.required"> Surname is required </mat-error>

				<mat-error *ngIf="f?.surname?.errors?.minlength"> Surname too short </mat-error>

				<mat-error *ngIf="f?.surname?.errors?.pattern"> Surname must only contain characters from the alphabet </mat-error>

				<mat-hint *ngIf="editMode" align="end">{{ surnameInput?.value?.length || 0 }}/100</mat-hint>
			</mat-form-field>

			<!-- E-Mail address -->
			<mat-form-field filter-field-item appearance="outline">
				<mat-label>E-Mail Address</mat-label>
				<input #emailAddressInput [maxLength]="100" type="text" matInput formControlName="email" />

				<!-- VALIDATION ERRORS -->
				<mat-error *ngIf="f?.email?.errors?.required"> Email is required </mat-error>

				<mat-error *ngIf="f?.email?.errors?.email"> Invalid email </mat-error>

				<mat-hint *ngIf="editMode && emailAddressInput?.disabled === false" align="end">{{ emailAddressInput?.value?.length || 0 }}/100</mat-hint>
			</mat-form-field>
		</form>

		<div class="buttons">
			<button *ngIf="!editMode" mat-stroked-button (click)="editProfile()">EDIT PROFILE</button>
			<button *ngIf="editMode" mat-stroked-button (click)="cancelProfileChange()">CANCEL</button>
			<button *ngIf="editMode" [disabled]="!form || !form.touched" mat-stroked-button type="submit" form="profile-form">SAVE</button>
		</div>
	</div>

	<!-- Security section -->
	<div class="security-content">
		<h2>Security</h2>
		<button mat-stroked-button (click)="changePassword()">CHANGE PASSWORD</button>
	</div>
</ip-page-shell>
