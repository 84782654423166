export interface IPermissionArea {
	description: string;
}

export interface IMinimalPermissionDTO {
	id: number;
	areaMappedId: number;
	area?: IPermissionArea;
	description: string;
	dependsOn?: number;
}

export interface IMinimalPermissionsDTO {
	permissions: IMinimalPermissionDTO[];
	areas: { [id: number]: string };

}

export class MinimalPermissionsDTO implements IMinimalPermissionsDTO {

	permissions: IMinimalPermissionDTO[];
	areas: { [id: number]: string };

	constructor(data: IMinimalPermissionsDTO) {
		Object.assign(this, data);

		let mappedAreas: { [id: number]: IPermissionArea } = {};
		for (const key of Object.keys(this.areas)) {
			mappedAreas[key] = {
				description: this.areas[key]
			};
		}

		this.permissions.forEach(p => {
			p.area = mappedAreas[p.areaMappedId];
		});
	}
}
