<!-- Loading -->
<ng-container *ngIf="loading">
	<ng-container ipLoading="pageShell"></ng-container>
</ng-container>

<!-- Error message -->
<ng-container *ngIf="!loading && error">
	<p>
		{{ error }}
	</p>
</ng-container>

<!-- Page content -->
<ng-container *ngIf="!loading && !error">
	<ng-content></ng-content>
</ng-container>
