import { ContentChildren, Directive, ElementRef, forwardRef, Host, QueryList } from "@angular/core";
import {
    CanvasRendererType,
    DOM_ELEMENT_RENDERER,
    ICanvasRendererContext,
    IRenderable,
    IRenderTarget,
    IRenderTargetViewport,
    mixinNgSubscriptions,
    NgSubscriptionsCtor,
    PEN_PATH_RENDERER,
    RENDER_TARGET,
    RENDERABLE,
} from "@redrow/utilities";

import { RedrowCanvasDomElementRendererService } from "../../services/renderers/canvas-dom-element-renderer.service";
import { RedrowCanvasPenPathRenderer } from "../../services/renderers/canvas-pen-path-renderer.service";


class RedrowCanvasRenderTargetDirectiveBase {
    constructor() { }
}

const _RedrowCanvasRenderTargetDirectiveMixinBase: NgSubscriptionsCtor & typeof RedrowCanvasRenderTargetDirectiveBase = mixinNgSubscriptions(RedrowCanvasRenderTargetDirectiveBase);


/**
 * Helper directive to allow dom elements to be rendered onto a canvas.
 * 
 * <ng-services-canvas-render-target-demo></ng-services-canvas-render-target-demo>
 */
@Directive({
    selector: "canvas[rr-render-target]",
    exportAs: "rrCanvasRenderTarget",
    inputs: [

    ],
    host: {

    },
    providers: [
        // Provide target
        {
            provide: RENDER_TARGET,
            useExisting: forwardRef(() => RedrowCanvasRenderTargetDirective)
        },

        // Provide renderers
        {
            provide: PEN_PATH_RENDERER,
            useClass: RedrowCanvasPenPathRenderer
        },

        {
            provide: DOM_ELEMENT_RENDERER,
            useClass: RedrowCanvasDomElementRendererService
        }
    ]
})
export class RedrowCanvasRenderTargetDirective extends _RedrowCanvasRenderTargetDirectiveMixinBase implements IRenderTarget {

    @ContentChildren(RENDERABLE, { descendants: true })
    renderableChildren: QueryList<IRenderable>;


    constructor(
        @Host() protected readonly elementRef: ElementRef<HTMLCanvasElement>
    ) {
        super();
    }

    getRendererContext<ContextType>(rendererType: CanvasRendererType): ContextType {
        if (rendererType === CanvasRendererType.CANVAS) {
            const context: ICanvasRendererContext = {
                canvas: this.elementRef.nativeElement,
                context2d: this.elementRef.nativeElement.getContext("2d")
            };
            return context as any;
        }
        return undefined;
    }

    getViewport(): IRenderTargetViewport {
        return {
            x: 0,
            y: 0,
            width: this.elementRef.nativeElement.width,
            height: this.elementRef.nativeElement.height
        };
    }

    public clearViewport() {
        const canvas = this.elementRef.nativeElement;
        const context = canvas.getContext("2d");
        const viewport = this.getViewport();

        context.clearRect(viewport.x, viewport.y, viewport.width, viewport.height);
    }

    render(): void {

        this.clearViewport();

        if (!this.renderableChildren) return;
        for (const renderable of this.renderableChildren) {
            if (!renderable) continue;
            renderable.onRender();
        }
    }

}