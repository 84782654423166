import { Inject, Injectable, Optional } from "@angular/core";
import { LogLevel } from "../../enums/log-level.enum";
import { ILoggerDestination } from "../../interfaces/logger-destination.interface";
import { ILoggerFormatter } from "../../interfaces/logger-formatter.interface";
import { LOGGER_FORMAT } from "../../tokens/logger-formatter.token";

@Injectable({
	providedIn: "root",
})
export class ConsoleLogger implements ILoggerDestination {
	constructor(@Optional() @Inject(LOGGER_FORMAT) protected readonly formatter: ILoggerFormatter) {}

	log(level: LogLevel, category: string, ...args: any[]): void {
		/**
		 * Use a formatter if there is one available.
		 */
		if (this.formatter) {
			const out = this.formatter.format(level, category, ...args);
			if (out) {
				console.log(...out);
			}
			return;
		}

		// Otherwise fallback to raw output
		console.log(level, category, ...args);
	}
}
