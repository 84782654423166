import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IAppConfiguration } from "@config/external-contractor-login-app.interface";
import { CONFIGURATION_MAPPER, IConfiguration } from "@redrow/configuration";
import { IOAuthClientConfig } from "@redrow/oauth";
import { Observable } from "rxjs";

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {

	constructor(
		@Inject(CONFIGURATION_MAPPER) protected readonly config: IConfiguration<IAppConfiguration>
	) { }

	shouldSend(url: string, config: IAppConfiguration | IOAuthClientConfig) {

		// Send to application api
		if ("apiUrl" in config) {
			if (url.startsWith((config as IAppConfiguration).apiUrl)) {
				return true;
			}
		}

		// Send to oauth
		if ("OAuthClient" in config) {
			if (url.startsWith((config as IOAuthClientConfig).OAuthClient.issuer)) {
				return true;
			}
		}

		return false;
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		// Only send to API
		const c = this.config.get();
		if (!this.shouldSend(req.url, c)) {
			return next.handle(req);
		}

		// Add headers to let the server know which client version we're running
		req = req.clone({
			setHeaders: {
				"App-Id": c.appId,
				"App-Version": c.LOCAL_VERSION.substr(0, 10)
			}
		});

		return next.handle(req);
	}

}
