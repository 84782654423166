import { Inject, Injectable } from "@angular/core";
import { IAppConfiguration } from "@config/external-contractor-login-app.interface";
import { CONFIGURATION_MAPPER, IConfiguration } from "@redrow/configuration";
import { ILoggerDestination, ILoggerFilter, LogLevel } from "@redrow/logging";
import { RedrowEnvironmentType } from "@redrow/utilities";

/**
 * Control which logs get filtered per environment.
 */
@Injectable({ providedIn: "root" })
export class LogFilterService implements ILoggerFilter {

	protected readonly env: RedrowEnvironmentType;

	constructor(
		@Inject(CONFIGURATION_MAPPER) protected readonly config: IConfiguration<IAppConfiguration>
	) {
		this.env = this.config.get().environment;
	}

	shouldFilter(level: LogLevel, category: string, logger: ILoggerDestination, ...args: any[]): boolean {

		// All logs show on local
		if (this.env === "Local") return false;

		// Otherwise we filter out debug logs
		if (level <= LogLevel.Debug) return true;

		// Filter everything in live
		if (this.env === "Live") return true;

		return false;
	}

}
