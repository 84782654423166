<div class="syncdata-group-row">
	<div class="syncdata-group-name">
		{{ friendlyName }}
	</div>
	<div class="syncdata-group-icon" right>
		<ng-container *ngIf="showWarning">
			<rr-icon class="warning" size="unset" name="warning"></rr-icon>
		</ng-container>
		<ng-container *ngIf="showComplete">
			<rr-icon right size="unset" name="tick"></rr-icon>
		</ng-container>
		<ng-container *ngIf="inProgress">
			<rr-loader right></rr-loader>
		</ng-container>
	</div>
</div>
