import { ChangeDetectorRef, Directive, Input, OnChanges, SimpleChanges } from "@angular/core";

@Directive({
	exportAs: "highlightUnopened",
	selector: "[highlightUnopened]"
})
export class HighlightUnopened implements OnChanges {

	state: boolean;

	@Input("highlightUnopened") inputValue: string;

	constructor(
		protected readonly changeDetectorRef: ChangeDetectorRef
	) { }

	/*
	* Checks if the provided string is not "-" and there are more than 0 unopened 
	* instructions to determine if highlight should be applied to element
	*/
	ngOnChanges(changes: SimpleChanges): void {
		if(!!this.inputValue) {
			let trimmedValue = this.inputValue.trim();
			if (trimmedValue !== "-" && !trimmedValue.startsWith("0")) {
				this.state = true;
			} else {
				this.state = false;
			}
		}
		this.changeDetectorRef.detectChanges();
	}
}