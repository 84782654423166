import { IUserManagementPermission } from "./user-management.interface";

export class UserManagementPermission implements IUserManagementPermission {

	constructor(flags: number) {
		this.list = (flags & 1) === 1;
		this.invite = (flags & 2) === 2;
		this.update = (flags & 4) === 4;


	}
	list: boolean;
	invite: boolean;
	update: boolean;



}
