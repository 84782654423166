import { ILogger, mixinOptionalLogging, OptionalLoggingCtor } from "@redrow/utilities";
import { OAuthLogger } from "angular-oauth2-oidc";

const _OAuthOptionalLoggerMixin: OptionalLoggingCtor = mixinOptionalLogging(class _ { });

/**
 * Implementation of OAuthLogger which has optional logging support.
 */
export class OAuthOptionalLogger extends _OAuthOptionalLoggerMixin implements OAuthLogger {

    constructor(
        public readonly logger: ILogger
    ) {
        super();
    }

    debug(message?: any, ...optionalParams: any[]): void {
        this.optDebug(message, ...optionalParams);
    }
    info(message?: any, ...optionalParams: any[]): void {
        this.optInfo(message, ...optionalParams);
    }
    log(message?: any, ...optionalParams: any[]): void {
        this.optInfo(message, ...optionalParams);
    }
    warn(message?: any, ...optionalParams: any[]): void {
        this.optWarn(message, ...optionalParams);
    }
    error(message?: any, ...optionalParams: any[]): void {
        this.optError(message, ...optionalParams);
    }

}

/**
 * Provide OAuthLogger with support for optional logging.
 * @param logger 
 * @returns 
 */
export function OAuthLoggerFactory(logger: ILogger) {
    return new OAuthOptionalLogger(logger);
}

