import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Host,
    Inject,
    Input,
    OnChanges,
    Optional,
    QueryList,
    ViewChildren,
} from "@angular/core";
import {
    COMMAND_HISTORY,
    ICommandHistory,
    IRenderable,
    IRenderableBoundingBox,
    IRendererPathOptions,
    mixinNgSubscriptions,
    NgSubscriptionsCtor,
    RENDERABLE,
    RendererPathPoints,
} from "@redrow/utilities";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, throttleTime } from "rxjs/operators";

import { IRedrowPointerMove, RedrowPointerMoveDirective } from "../events/index";



class RedrowDrawablePathComponentBase {
    constructor() { }
}

const _RedrowDrawablePathComponentMixinBase: NgSubscriptionsCtor & typeof RedrowDrawablePathComponentBase = mixinNgSubscriptions(RedrowDrawablePathComponentBase);



/**
 * Use this directive to allow users to draw using their mouse/finger onto a render target.
 * Input is captured using RedrowPointerMoveDirective so you must bind that directive to your canvas.
 * 
 * <ng-services-canvas-render-target-demo></ng-services-canvas-render-target-demo>
 */
@Component({
    template: `
        <rr-pen-path *ngFor="let path of paths" [path]="path" [path-options]="options"></rr-pen-path>
        <rr-pen-path *ngIf="currentPath" [path]="currentPath" [path-options]="options"></rr-pen-path>
    `,
    selector: "rr-drawable-pen-path",
    exportAs: "rrDrawablePenPath",
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: [

    ],
    host: {

    },
    providers: [
        {
            provide: RENDERABLE,
            useExisting: forwardRef(() => RedrowDrawablePathComponent),
            multi: true
        }
    ]
})
export class RedrowDrawablePathComponent extends _RedrowDrawablePathComponentMixinBase implements IRenderable, AfterViewInit, OnChanges {

    @ViewChildren(RENDERABLE)
    pathRenderables: QueryList<IRenderable>;

    /**
     * How many pixels should we wait before plotting another point?
     */
    @Input("pointer-movement-threshold")
    pointerMovementThreshold: number = 20;

    /**
     * How many milliseconds to wait before accepting new pointer movement events?
     */
    @Input("pointer-movement-time")
    pointerMovementTime: number = 10;

    constructor(
        @Host() protected readonly pointerMoveDirective: RedrowPointerMoveDirective,
        protected readonly cd: ChangeDetectorRef,

        // Support for command history
        @Optional() @Inject(COMMAND_HISTORY) protected readonly commandHistory: ICommandHistory
    ) {
        super();

        // Pointer down
        this.ngSubscriptions.add(
            this.pointerMoveDirective.onPointerMoveStart.subscribe(
                e => this._penStart(e.currentOffsetX, e.currentOffsetY)
            )
        );

        // Pointer move
        this.ngSubscriptions.add(
            this.pointerMoveDirective.onPointerMove.pipe(
                throttleTime(this.pointerMovementTime),
                filter(x => this._hasPointerMovedEnough(x))
            ).subscribe(
                e => this._penMove(e.currentOffsetX, e.currentOffsetY)
            )
        );

        // Pointer end
        this.ngSubscriptions.add(
            this.pointerMoveDirective.onPointerMoveEnd.subscribe(
                e => this._penEnd(e.currentOffsetX, e.currentOffsetY)
            )
        );
    }

    protected _hasPointerMovedEnough(newPoint: IRedrowPointerMove) {
        if (!this.currentPath || this.currentPath.length === 0) return true;
        const lastPoint = this.currentPath[this.currentPath.length - 1];
        return Math.abs(
            lastPoint.x - newPoint.currentOffsetX
        ) > this.pointerMovementThreshold || Math.abs(
            lastPoint.y - newPoint.currentOffsetY
        ) > this.pointerMovementThreshold;
    }

    ngOnChanges() {
        this.dirty = true;
    }

    getBoundingBox(): IRenderableBoundingBox {
        let left = undefined;
        let right = undefined;
        let bottom = undefined;
        let top = undefined;
        for (let point of this.pathRenderables) {
            const bb = point.getBoundingBox();
            left = left ? Math.min(left, bb.x) : bb.x;
            right = right ? Math.max(right, bb.x + bb.width) : bb.x + bb.width;
            top = top ? Math.min(top, bb.y) : bb.y;
            bottom = bottom ? Math.max(bottom, bb.y + bb.height) : bb.y + bb.height;
        }
        return {
            x: left,
            y: top,
            width: right - left,
            height: bottom - top
        };
    }
    onRender(): void {

        this.dirty = false;

        if (!this.pathRenderables) return;

        for (let r of this.pathRenderables) {
            r.onRender();
        }
    }

    // Dirty by default
    protected readonly _dirty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get dirty(): boolean {
        return this._dirty.value;
    }

    set dirty(newValue: boolean) {
        if (this._dirty.value !== newValue) {
            this._dirty.next(newValue);
            if (newValue) {
                this.cd.markForCheck();
            }
        }
    }

    dirtyChange(): Observable<boolean> {
        return this._dirty.asObservable();
    }

    ngAfterViewInit() {
        if (this.pathRenderables) {
            this.ngSubscriptions.add(
                this.pathRenderables.changes.subscribe(
                    () => this.dirty = true
                )
            );
        }
    }

    protected _penStart(x: number, y: number) {
        this.currentPath = [{
            x, y
        }];
    }

    protected _penMove(x: number, y: number) {
        this.currentPath.push({
            x, y
        });
        this.dirty = true;
    }

    protected _penEnd(x: number, y: number) {

        this.currentPath.push({
            x, y
        });
        const p = this.currentPath;
        this.currentPath = undefined;
        this.addPath(p);
    }

    public addPath(path: RendererPathPoints) {

        if (this.commandHistory) {
            this.commandHistory.executeCommand({
                name: "Add Path",
                execute: () => {
                    this.paths.push(path);
                    this.dirty = true;
                },
                undo: () => {
                    this.paths.pop();
                    this.dirty = true;
                }
            });
            return;
        }

        this.paths.push(path);
        this.dirty = true;

        this.cd.markForCheck();

    }

    @Input("path-options")
    options: IRendererPathOptions;

    paths: RendererPathPoints[] = [];
    currentPath: RendererPathPoints;




}