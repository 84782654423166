import { GUID_EMPTY, GUID_FORMAT } from "../constants/guid.constant";
import { StringFormat } from "../functions/string/string-format.function";
import { IGuid } from "../interfaces/guid.interface";

/**
 * Helper class for working with Guid's.
 */
export class Guid implements IGuid {
	private _id: string;

	/**
	 * Returns an empty GUID.
	 */
	static empty(): Guid {
		return new Guid(GUID_EMPTY);
	}

	/**
	 * Creates a new GUID.
	 */
	static newGuid(): Guid {
		try {
			// Use new crypto version - more secure
			return new Guid(StringFormat("${0}${1}-${2}-${3}-${4}${5}${6}", [...window.crypto.getRandomValues(new Uint16Array(7))].map(x => x.toString(16).padStart(4, "0"))));
		} catch (e) {
			return new Guid(`${Guid.s4()}${Guid.s4()}-${Guid.s4()}-${Guid.s4()}-${Guid.s4()}-${Guid.s4()}${Guid.s4()}${Guid.s4()}`);
		}
	}

	public static generateRandomGuidString(componentLength: number = 8) {
		return Array(componentLength)
			.fill(Guid.s4())
			.join("-");
	}

	static newGuidString(): string {
		return Guid.newGuid().toString();
	}

	private static s4(): string {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}

	/**
	 * Creates a new GUID from a string or clones existing guid.
	 */
	constructor(id: string) {
		if (GUID_FORMAT.test(id)) {
			this._id = GUID_FORMAT.exec(id.toLowerCase())[1];
			return;
		}

		throw new Error(`Invalid GUID ${id}`);
	}

	/**
	 * Compares if two GUID's are equal.
	 */
	equals(value: string | IGuid): boolean {
		try {
			return this._id === new Guid(typeof value === "string" ? value : value.valueOf()).valueOf();
		} catch (err) {
			return false;
		}
	}

	/**
	 * Converts the GUID to a formatted string.
	 *
	 * Formats:
	 *   n = 00000000000000000000000000000000
	 *   d = 00000000-0000-0000-0000-000000000000 (default)
	 *   b = \{00000000-0000-0000-0000-000000000000\}
	 */
	toString(format: string = "d"): string {
		switch (format.toLowerCase()) {
			case "n":
				return this._id.replace("-", "");
			case "b":
				return `{${this._id}}`;
			default:
				return this._id;
		}
	}

	/**
	 * Returns the underlying value.
	 */
	valueOf(): string {
		return this._id;
	}
}
