/**
 * Simple class which offers a way to provide an image along with a thumbnail.
 * The thumbnail is optional and if not provided should be generated by the component consuming the attachment.
 */

export class ImageAttachment {
	protected readonly _thumbnailSrc: string;
	protected readonly _fullSrc: string;

	constructor(src: string, thumbnailSrc: string = undefined) {
		this._fullSrc = src;
		this._thumbnailSrc = thumbnailSrc;
	}


	public hasThumbnail(): boolean {
		return !!this._thumbnailSrc;
	}


	public getSource(): string {
		return this._fullSrc;
	}


	public getThumbnailSource(fallbackToSource: boolean = false): string {
		if (!this.hasThumbnail() && fallbackToSource) {
			return this.getSource();
		}
		return this._thumbnailSrc;
	}


}
