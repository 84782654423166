import { Inject, Injectable } from "@angular/core";
import { deepMergeConfiguration } from "../functions/deep-merge-configuration.function";
import { IConfiguration } from "../interfaces/configuration.interface";
import { CONFIGURATION } from "../tokens/configuration.token";
import { MergeConfiguration } from "../types/configuration-merge.type";

/**
 * Default implementation of the IConfiguration interface which uses injected value pairs put into CONFIGURATION token
 */
@Injectable()
export class DeepMergeConfiguration<TConfiguration, T1, T2 = undefined, T3 = undefined, T4 = undefined>
	implements IConfiguration<TConfiguration> {

	constructor(
		@Inject(CONFIGURATION) protected readonly configuration: MergeConfiguration<TConfiguration, T1, T2, T3, T4>
	) {
		this.mergedConfiguration = deepMergeConfiguration<TConfiguration, T1, T2, T3, T4>(configuration);
	}

	protected readonly mergedConfiguration: TConfiguration;

	public get(): TConfiguration {
		return this.mergedConfiguration;
	}
}
