import { Inject, Injectable, Optional } from "@angular/core";
import {
    AppAuthenticationServiceErrorEvent,
    AppAuthenticationServiceEventObservable,
    AppAuthenticationServiceEventSubject,
    AppAuthenticationServiceLoginEvent,
    AppAuthenticationServiceLoginExpiredEvent,
    AppAuthenticationServiceLogoutEvent,
    AppAuthenticationServicePreLoginEvent,
    AppAuthenticationServicePreLogoutEvent,
    AppAuthenticationServiceUserProfileEvent,
    IAppAuthenticationService,
    ILogger,
    mixinNgSubscriptions,
    mixinOptionalLogging,
    NgSubscriptionsCtor,
    OptionalLoggingCtor,
} from "@redrow/utilities";
import { AuthConfig, OAuthErrorEvent, OAuthInfoEvent, OAuthService } from "angular-oauth2-oidc";
import { Subject } from "rxjs";

import {
    IRedrowOAuthAuthenticationServiceOptions,
} from "../../public/interfaces/oauth-authentication-service-options.interface";
import { REDROW_OAUTH_AUTHENTICATION_SERVICE_LOGGER } from "../../public/tokens/oauth-authentication-service-logger.token";
import { REDROW_OAUTH_AUTHENTICATION_SERVICE_OPTIONS } from "../../public/tokens/oauth-authentication-service-options.token";
import { ANGULAR_OAUTH_OIDC_SERVICE } from "../tokens/angular-oauth-oidc-service.token";
import { IRedrowOauthUserProfile } from "../interfaces/oauth-user-profile.interface";

enum RedrowOAuthAuthenticationServiceState {
    NOT_AUTHENTICATED,
    AUTHENTICATING,
    AUTHENTICATED,
    LOGGING_OUT
}


const _RedrowOAuthAuthenticationServiceMixin: NgSubscriptionsCtor & OptionalLoggingCtor = mixinNgSubscriptions(mixinOptionalLogging(class _ { }));

/**
 * Implementation of {@link IAppAuthenticationService} which uses an OAuth server to authenticate the user.
 * NOTE: Expected to only have one instance per application.
 * 
 * Supported authentication events:
 * - {@link @redrow/utilities!AppAuthenticationServiceErrorEvent}
 * - {@link @redrow/utilities!AppAuthenticationServiceLoginEvent}
 * - {@link @redrow/utilities!AppAuthenticationServiceLoginExpiredEvent}
 * - {@link @redrow/utilities!AppAuthenticationServicePreLoginEvent}
 * - {@link @redrow/utilities!AppAuthenticationServicePreLogoutEvent}
 * - {@link @redrow/utilities!AppAuthenticationServiceLogoutEvent}
 * - {@link @redrow/utilities!AppAuthenticationServiceUserProfileEvent}
 */
@Injectable()
export class RedrowOAuthAuthenticationService extends _RedrowOAuthAuthenticationServiceMixin implements IAppAuthenticationService {

    /**
     * A stream of authentication events.
     */
    protected readonly _events: AppAuthenticationServiceEventSubject = new Subject();

    /**
     * The current state of authentication.
     */
    protected _state: RedrowOAuthAuthenticationServiceState = RedrowOAuthAuthenticationServiceState.NOT_AUTHENTICATED;

    /**
     * Cached oauth configuration.
     */
    protected _oauthConfig: AuthConfig;

    /**
     * State for keeping track of the initial profile request - so that we don't load it twice at login BUT reload it when the token is refreshed.
     */
    protected _userProfileRequestInFlight: boolean = false;

    constructor(
        @Inject(ANGULAR_OAUTH_OIDC_SERVICE) protected readonly oauthService: OAuthService,
        @Inject(REDROW_OAUTH_AUTHENTICATION_SERVICE_OPTIONS) protected options: IRedrowOAuthAuthenticationServiceOptions,
        @Optional() @Inject(REDROW_OAUTH_AUTHENTICATION_SERVICE_LOGGER) protected readonly logger: ILogger
    ) {
        super();

        const defaultConfig = new AuthConfig();
        this._oauthConfig = {
            clientId: options.clientId,
            customQueryParams: {

                // Forward various configuration to the OAuth client (login screen)
                clientAppVersion: options.appVersion,
                clientAppRevision: options.appRevision,
                clientAppBuild: options.appBuild,
                clientRequiresOutlet: options.requireOutlet === true,
                clientRequiresDivision: options.requireDivision !== false, // default to true unless otherwise specified

                ...(
                    typeof options.loginType === "string"
                        ? {
                            clientLoginType: options.loginType
                        } : {}
                )
            },
            issuer: options.issuer,
            openUri: options.openUri ?? defaultConfig.openUri,
            redirectUri: options.redirectUri ?? `${window.location.origin}/oauth/callback.html`,
            postLogoutRedirectUri: options.postLogoutRedirectUri ?? `${window.location.origin}/`,
            scope: options.scopes.join(" "),
            requireHttps: options.issuer.startsWith("https://"),
            oidc: true,
            requestAccessToken: true,
            responseType: options.responseType ?? "code",
        };
        this.oauthService.configure(this._oauthConfig);

        // Keep track of token expiry and refresh the token if offline_access is set
        this.setupRefreshTokenCheck();

        // Listen to internal oauth service to react to various events
        this.ngSubscriptions.add(
            this.oauthService.events.subscribe(
                e => {
                    this.optDebug(`oauthService event`, e);

                    // Raise oauth error events as authentication errors
                    if (e instanceof OAuthErrorEvent) {

                        // Handle token refresh error
                        // Not much we can do here but log the user out
                        if (e.type === "token_refresh_error") {
                            this.logoutAfterTokenExpiry();
                        }

                        // Handle authentication error
                        this._events.next(new AppAuthenticationServiceErrorEvent(new Error(e.type)))

                    } else if (e.type === "token_received") {

                        // If we receive a token - we fire the login event if it hasn't been fired already
                        this.handleLoginEvent();

                    } else if (e.type === "logout") {

                        // Handle logout
                        this._events.next(new AppAuthenticationServiceLogoutEvent());
                        this._state = RedrowOAuthAuthenticationServiceState.NOT_AUTHENTICATED;

                    } else if (e.type === "token_expires" && e instanceof OAuthInfoEvent) {

                        // Check for the access token expiring to logout the user
                        if (e.info === "access_token") {
                            this._events.next(new AppAuthenticationServiceLoginExpiredEvent());

                            // If not configured to refresh token automatically - then we must logout when our token expires
                            if (!this.hasOfflineAccess()) {
                                this.logoutAfterTokenExpiry();
                            }
                        }

                    } else if (e.type === "token_refreshed") {

                        this.handleTokenRefresh();

                    }
                }
            )
        );
    }

    protected logoutAfterTokenExpiry() {
        this.optDebug(`logoutAfterTokenExpiry`);

        this._events.next(new AppAuthenticationServicePreLogoutEvent());

        // Clear access tokens & raise logout event
        this.oauthService.logOut();
    }

    /**
     * Check if the offline_access scope was requested.
     * Meaning we will be refreshing a short lived token from time to time.
     * @returns
     */
    protected hasOfflineAccess() {
        return this.options.scopes.indexOf("offline_access") > -1;
    }

    protected setupRefreshTokenCheck() {
        // We only need to setup a refresh token check if the client has requested offline_access
        if (!this.hasOfflineAccess()) return;

        this.optDebug(`Offline access requested. Setting up automatic silent token refresh.`);
        this.oauthService.setupAutomaticSilentRefresh();
    }

    protected loadUserProfile() {
        if (this._userProfileRequestInFlight) return;

        if (this.options.shouldLoadUserProfile === false) {
            return;
        }

        // Trigger user profile to load on token refresh - as it might have changed.
        this._userProfileRequestInFlight = true;
        this.oauthService.loadUserProfile().then(
            (x: IRedrowOauthUserProfile) => this._events.next(new AppAuthenticationServiceUserProfileEvent(x.info))
        ).finally(
            () => this._userProfileRequestInFlight = false
        )
    }

    protected handleTokenRefresh() {

        this.optDebug(`handleTokenRefresh`);
        this.loadUserProfile();

    }

    protected handleLoginEvent() {
        if (this._state === RedrowOAuthAuthenticationServiceState.AUTHENTICATED) return;

        // Handle login
        this._events.next(new AppAuthenticationServiceLoginEvent());
        this._state = RedrowOAuthAuthenticationServiceState.AUTHENTICATED;

        // Initial load of user profile
        this.loadUserProfile();
    }

    /**
     * Reconfigure parts of the authentication service.
     * @param opts 
     */
    public reconfigure(opts: Pick<IRedrowOAuthAuthenticationServiceOptions, "openUri"> & Pick<IRedrowOAuthAuthenticationServiceOptions, "loginFlowParams">) {

        this._configure(opts);

        this.options = {
            ...this.options,
            ...opts
        };
    }

    protected _configure(config: AuthConfig) {

        // WORKAROUND for revoctionUrl being cleared when reconfiguring oauthService
        let existingConfig = Object.keys(new AuthConfig()).reduce((obj, key) => {
            obj[key] = this.oauthService[key];
            return obj;
        }, {});

        this._oauthConfig = {
            ...this._oauthConfig,
            ...config
        };

        this.oauthService.configure({
            ...existingConfig,
            ...this._oauthConfig
        });
    }

    public setCustomQueryParam(
        key: string,
        value: any
    ) {
        this._configure({
            customQueryParams: {
                ...this._oauthConfig.customQueryParams,
                [key]: value
            }
        });
    }

    public deleteCustomQueryParam(
        key: string
    ) {
        if (key in this._oauthConfig.customQueryParams) return;

        const newParams = {
            ...this._oauthConfig.customQueryParams
        };
        delete newParams[key];

        this._configure({
            customQueryParams: newParams
        });
    }

    observeAppAuthenticationEvents(): AppAuthenticationServiceEventObservable {
        return this._events.asObservable();
    }

    /**
     * Actually start the authentication process.
     */
    protected async _authenticate() {
        this._events.next(new AppAuthenticationServicePreLoginEvent());
        this.oauthService.loadDiscoveryDocumentAndTryLogin().then((_) => {
            if (!this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
                // Try to login
                this.oauthService.initLoginFlow(undefined, this.options.loginFlowParams);
            } else {
                // Already logged in
                this.handleLoginEvent();
            }
        });
    }

    authenticate() {

        if (this._state !== RedrowOAuthAuthenticationServiceState.NOT_AUTHENTICATED) return;

        this._state = RedrowOAuthAuthenticationServiceState.AUTHENTICATING;
        this._authenticate().catch(
            err => {
                this._events.next(new AppAuthenticationServiceErrorEvent(err));
                this._state = RedrowOAuthAuthenticationServiceState.NOT_AUTHENTICATED;
            }
        );

    }

    logout() {
        if (this._state !== RedrowOAuthAuthenticationServiceState.AUTHENTICATED) return;

        this._state = RedrowOAuthAuthenticationServiceState.LOGGING_OUT;
        this._events.next(new AppAuthenticationServicePreLogoutEvent());
        this.oauthService.revokeTokenAndLogout().catch(
            err => {
                this._events.next(new AppAuthenticationServiceErrorEvent(err));

                if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
                    this._state = RedrowOAuthAuthenticationServiceState.AUTHENTICATED;
                } else {
                    this._state = RedrowOAuthAuthenticationServiceState.NOT_AUTHENTICATED;
                }
            }
        );
    }
}

