import { DOCUMENT } from "@angular/common";
import { Directive, HostListener, Inject, OnDestroy, Output, Renderer2 } from "@angular/core";
import { defer, EMPTY, iif, Observable, of, Subject } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import {
	CanDisableCtor,
	mixinCanDisable,
	mixinNgSubscriptions,
	NgSubscriptionsCtor,
	RedrowMimeType
} from "@redrow/utilities";

import { RedrowCanvasConverterService } from "../../services/canvas";
import { RedrowCaptureImageFromStreamControlService } from "./services";

class RedrowCaptureImageFromStreamActionDirectiveBase {
	constructor() { }
}

const _RedrowCaptureImageFromStreamActionDirectiveMixinBase: CanDisableCtor & NgSubscriptionsCtor & typeof RedrowCaptureImageFromStreamActionDirectiveBase = mixinCanDisable(mixinNgSubscriptions(RedrowCaptureImageFromStreamActionDirectiveBase));

/**
 * A directive which can be attached to a DOM element which captures an image from the media stream and emits the image as a blob.
 */
@Directive({
	selector: "[capture-image-from-stream-action]",
	exportAs: "rrCaptureImageFromStreamAction",
	inputs: [
		"disabled"
	],
	providers: [
		RedrowCaptureImageFromStreamControlService
	]
})
export class RedrowCaptureImageFromStreamActionDirective extends _RedrowCaptureImageFromStreamActionDirectiveMixinBase implements OnDestroy {

	protected readonly _document: Document;
	protected readonly _captureImageZIndex: number = 100000;

	@HostListener("click", ["$event"])
	public onHostClick(event: MouseEvent) {
		if (this.disabled) return;

		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.initCaptureImage();
	}

	@Output()
	get imported(): Observable<Blob> {
		return this._importedEventObserver;
	}

	protected readonly _importedEvent: Subject<Blob> = new Subject<Blob>();
	private readonly _importedEventObserver: Observable<Blob> = this._importedEvent.asObservable();

	/**
	 * Required for capturing image from user media
	 */

	protected isStreaming: boolean = false;

	// Video feed of camera
	protected _videoFeedElement: HTMLVideoElement;

	// Container for video canvas
	protected _videoCanvasPreviewContainer: HTMLDivElement;
	protected _takeImageElement: HTMLDivElement;
	protected _cancelElement: HTMLDivElement;


	// Video canvas for drawing a snapshot of the video feed
	protected _videoCanvasElement: HTMLCanvasElement;
	protected _videoCanvasContext: CanvasRenderingContext2D;
	protected _pendingVideoAnimationFrame?: number;

	// Canvas for drawing our captured image
	protected _imageCanvasElement: HTMLCanvasElement;

	constructor(
		@Inject(DOCUMENT) protected readonly document: any,
		protected readonly renderer: Renderer2,
		protected readonly redrowCaptureImageFromStreamControlService: RedrowCaptureImageFromStreamControlService,
		protected readonly redrowCanvasConverterService: RedrowCanvasConverterService
	) {
		super();
		this._document = document;
	}

	ngOnDestroy(): void {
		this.clearNgSubscriptions();
		this.destroyCaptureImage();
	}

	protected hasGetUserMedia(): boolean {
		return !!(
			navigator.mediaDevices.getUserMedia
		);
	}

	protected destroyCaptureImage(): void {
		this.isStreaming = false;
		// Image canvas
		if (!!this._imageCanvasElement) {
			this._imageCanvasElement = null;
		}
		// Video canvas
		if (!!this._videoCanvasElement) {
			this._videoCanvasElement = null;
		}
		// Take image video canvas preview container control
		if (!!this._takeImageElement) {
			this._takeImageElement = null;
		}
		// Cancel video canvas preview container control
		if (!!this._cancelElement) {
			this._cancelElement = null;
		}
		// Video canvas preview container
		if (!!this._videoCanvasPreviewContainer) {
			this.renderer.removeChild(this._document.body, this._videoCanvasPreviewContainer);
			this._videoCanvasPreviewContainer = null;
		}
		// Video feed
		if (!!this._videoFeedElement) {
			if (!!this._videoFeedElement.srcObject && this._videoFeedElement.srcObject instanceof MediaStream) {
				this._videoFeedElement.srcObject.getVideoTracks().forEach(videoTracks => {
					if (videoTracks.readyState == "live") {
						videoTracks.stop();
					}
				});
			}
			this.renderer.removeChild(this._document.body, this._videoFeedElement);
			this._videoFeedElement = null;
		}
		cancelAnimationFrame(this._pendingVideoAnimationFrame);
	}

	/**
	 * Set up video feed which is the source of where our image data is coming from
	 * @param mediaStream
	 */
	protected initVideoFeed(mediaStream: MediaStream): void {
		this._videoFeedElement = this.renderer.createElement("video");
		this._videoFeedElement.srcObject = mediaStream;
		this.renderer.setStyle(this._videoFeedElement, "position", "absolute");
		this.renderer.setStyle(this._videoFeedElement, "z-index", `${this._captureImageZIndex - 2}`);
		this.renderer.appendChild(this._document.body, this._videoFeedElement);
		this._videoFeedElement.play();
	}

	/**
	 * Set up video canvas preview container to contain the video canvas we are showing
	 */
	protected initVideoCanvasPreviewContainer(): void {
		this._videoCanvasPreviewContainer = this.renderer.createElement("div");
		this.renderer.setStyle(this._videoCanvasPreviewContainer, "position", "fixed");
		this.renderer.setStyle(this._videoCanvasPreviewContainer, "top", "0px");
		this.renderer.setStyle(this._videoCanvasPreviewContainer, "left", "0px");
		this.renderer.setStyle(this._videoCanvasPreviewContainer, "right", "0px");
		this.renderer.setStyle(this._videoCanvasPreviewContainer, "bottom", "0px");
		this.renderer.setStyle(this._videoCanvasPreviewContainer, "background", "#000000");
		this.renderer.setStyle(this._videoCanvasPreviewContainer, "z-index", this._captureImageZIndex);
		this.renderer.appendChild(this._document.body, this._videoCanvasPreviewContainer);
	}

	/**
	 * Set up take image control for video canvas preview container
	 */
	protected initTakeImageControl(): void {
		this._takeImageElement = this.redrowCaptureImageFromStreamControlService.getTakeImageControlElement();
		this.renderer.appendChild(this._videoCanvasPreviewContainer, this._takeImageElement);
		this.handleTakeImageClick();
	}

	/**
	 * Set up cancel control for video canvas preview container
	 */
	protected initCancelControl(): void {
		this._cancelElement = this.redrowCaptureImageFromStreamControlService.getCancelControlElement();
		this.renderer.appendChild(this._videoCanvasPreviewContainer, this._cancelElement);
		this._cancelElement.addEventListener("click", () => {
			this.clearNgSubscriptions();
			this.destroyCaptureImage();
		})
	}

	/**
	 * Set up video canvas which is used to draw a snapshot of our video on keyframe animation
	 */
	protected initVideoCanvas(): void {
		this._videoCanvasElement = this.renderer.createElement("canvas");
		this._videoCanvasContext = this._videoCanvasElement.getContext("2d");
		this.renderer.appendChild(this._videoCanvasPreviewContainer, this._videoCanvasElement);
	}

	/**
	 * Set up video canvas
	 */
	protected initImageCanvas(): void {
		this._imageCanvasElement = this.renderer.createElement("canvas");
		this.renderer.setStyle(this._imageCanvasElement, "z-index", `${this._captureImageZIndex - 1}`);
		this.renderer.setStyle(this._imageCanvasElement, "position", "absolute");
		this.renderer.setStyle(this._imageCanvasElement, "width", this._videoFeedElement.clientWidth);
		this.renderer.setStyle(this._imageCanvasElement, "height", this._videoFeedElement.clientHeight);
	}

	/**
	 * Render the video canvas on request animation frame,
	 * setting properties for scaling the dimentions of the canvas as this is what is displayed on screen
	 */
	protected renderVideoCanvas(): void {
		const containerWidth: number = this._videoCanvasElement.parentElement.clientWidth;
		const containerHeight: number = this._videoCanvasElement.parentElement.clientHeight;
		const videoWidth: number = this._videoFeedElement.clientWidth;
		const videoHeight: number = this._videoFeedElement.clientHeight;
		this._videoCanvasElement.width = containerWidth;
		this._videoCanvasElement.height = containerHeight;

		const widthRatio: number = containerWidth / videoWidth;
		const heightRatio: number = containerHeight / videoHeight;
		const scaleRatio: number = Math.min(widthRatio, heightRatio);

		const renderOffsetX: number = (containerWidth - (videoWidth * scaleRatio)) / 2;
		const renderOffsetY: number = (containerHeight - (videoHeight * scaleRatio)) / 2;

		this._videoCanvasContext.drawImage(this._videoFeedElement, 0, 0, videoWidth, videoHeight, renderOffsetX, renderOffsetY, videoWidth * scaleRatio, videoHeight * scaleRatio);
		this._pendingVideoAnimationFrame = requestAnimationFrame(() => this.renderVideoCanvas());
	}

	/**
	 * Handle what happens when the video feed starts to play
	 * We want to initialise our canvas elenents to alow us to capture data from our feed to display on screen
	 */
	protected handleVideoElementOnPlay(): void {
		this._videoFeedElement.addEventListener(
			"canplay",
			() => {
				if (!this.isStreaming) {

					this.initVideoCanvas();
					this.initImageCanvas();

					this.initTakeImageControl();

					this.isStreaming = true;

					this.renderVideoCanvas();
				}
			},
			false
		);
	}

	/**
	 * Handle taking an image of the video feed on click
	 */
	protected handleTakeImageClick(): void {
		this._takeImageElement.addEventListener(
			"click",
			(event) => {
				if (event) {
					event.preventDefault();
					event.stopPropagation();
				}

				const feedWidth = this._videoFeedElement.clientWidth;
				const feedHeight = this._videoFeedElement.clientHeight;
				this._imageCanvasElement.width = feedWidth;
				this._imageCanvasElement.height = feedHeight;

				const context = this._imageCanvasElement.getContext("2d");
				context.drawImage(this._videoFeedElement, 0, 0, feedWidth, feedHeight);

				this.handleImageBlobImport(this._imageCanvasElement);

			}
		);
	}

	/**
	 * Handle importing and image blob from an image canvas
	 * and destroy capture imnage instance
	 * @param imageCanvas canvas to convert to a blob and import
	 */
	protected handleImageBlobImport(imageCanvas: HTMLCanvasElement): void {
		this.ngSubscriptions.add(
			this.redrowCanvasConverterService.toBlob(
				imageCanvas,
				{
					mimeType: RedrowMimeType.Image_Jpeg,
					quality: 1
				}
			)
				.pipe<Blob>(
					catchError(() => of(null))
				)
				.subscribe((imageBlob: Blob) => {
					if (!!imageBlob) this._importedEvent.next(imageBlob);
					this.destroyCaptureImage();
				})
		);
	}

	/**
	 * Initialise subscription to media stream and set up events to handle displaying the stream on screen
	 * and to add controls to capture an image
	 */
	protected initCaptureImage(): void {
		this.clearNgSubscriptions();

		this.ngSubscriptions.add(
			iif(
				() => this.hasGetUserMedia(),
				defer(() => navigator.mediaDevices.getUserMedia({
					video: {
						width: {
							ideal: 2048
						},
						height: {
							ideal: 2048
						},
						facingMode: "environment"
					}
				})),
				EMPTY
			)
				.pipe(
					tap(mediaStream => {
						// Clean up video HTML element before creating new one
						this.destroyCaptureImage();
						// Initialise elements and events for capturing an image
						this.initVideoFeed(mediaStream);
						this.initVideoCanvasPreviewContainer();
						this.initCancelControl();
						this.handleVideoElementOnPlay();
					})
				)
				.subscribe()
		);
	}
}