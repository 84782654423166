import { Injectable } from "@angular/core";
import { defer, from, Observable } from "rxjs";
import { finalize, switchMap } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class RedrowImageConverterService {

	protected _fromString(str: string): Promise<HTMLImageElement> {

		// Feature detect decode function
		const img = new Image;

		// Prefer using decode() function
		// https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/decode
		// https://medium.com/dailyjs/image-loading-with-image-decode-b03652e7d2d2
		if (typeof img.decode === "function") {
			img.src = str;
			return img.decode().then(
				() => img
			);
		}

		// Fallback to onload event
		return new Promise<HTMLImageElement>(
			(resolve, reject) => {

				// Bind onload event
				img.onload = () => {
					resolve(img);
				}

				// Bind error event
				img.onerror = (evt, src, ln, col, err) => {
					reject(err);
				}

				// Switch the source after event bindings
				img.src = str;


			}
		);
	}

	/**
	 * To image
	 */

	/**
	 * Convert base64 string to HTMLImageElement
	 * @param base64 string to convert
	 * @returns HTMLImageElement
	 */
	fromBase64(base64: string): Observable<HTMLImageElement> {
		return defer(() => from(this._fromString(base64)));
	}

	/**
	 * Convert object URL to HTMLImageElement
	 * @param objectUrl object URL to convert
	 * @returns HTMLImageElement
	 */
	fromUrl(objectUrl: string): Observable<HTMLImageElement> {
		return defer(() => from(this._fromString(objectUrl)));
	}

	/**
	 * Convert image blob to HTMLImageElement
	 * @param imageBlob blob to convert
	 * @returns HTMLImageElement
	 */
	fromBlob(imageBlob: Blob): Observable<HTMLImageElement> {

		return defer(() => {

			// Variable setup
			let objectUrl: string;

			return defer(() => {
				// Create object URL
				objectUrl = URL.createObjectURL(imageBlob);

				// HTML image element
				return this.fromUrl(objectUrl);
			})
				.pipe(

					// Revoke object URL on complete or error
					finalize(() => URL.revokeObjectURL(objectUrl))

				);
		});
	}

	/**
	 * From image
	 */

	toBlob(image: HTMLImageElement): Observable<Blob> {
		return from(fetch(image.src))
			.pipe(
				switchMap(response => response.blob())
			)
	}
}