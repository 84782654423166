import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HighlightUnopened } from "./highlight-unopened.directive";

import { InstructionOpened } from "./instruction-opened.directive";
import { LoadingDirective } from "./loading-directive.directive";

const EXPORT_DIRECTIVE = [
	LoadingDirective,
	InstructionOpened,
	HighlightUnopened
];

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [...EXPORT_DIRECTIVE],
	exports: [...EXPORT_DIRECTIVE]
})
export class SharedDirectiveModule { }
