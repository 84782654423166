import { Component, Input } from "@angular/core";

@Component({
	selector: "ip-modal-loader",
	templateUrl: `./modal-loader.component.html`
})
export class ModalLoaderComponent {
	@Input()
	public loading: boolean;
}
