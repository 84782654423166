/* AUTOEXPORT:SETTING:MODULE:ApplicationShellModule */
import { Injectable } from "@angular/core";
import { IAppService, RedrowAppState } from "@redrow/utilities";
import { BehaviorSubject } from "rxjs";

import { ErrorWithContext } from "../classes/error-with-context.error";


@Injectable()
export class RedrowDefaultAppService implements IAppService {
	/**
     * Provides a high level view of the application state.
     */
	public readonly state: BehaviorSubject<RedrowAppState>;
    /**
     * Provides further context information about the high level app state.
     */
	public readonly stateContext: BehaviorSubject<string>;
    /**
     * Stores the last error that occurred in the app
     */
	public readonly lastError: BehaviorSubject<any>;
    /**
     * Mainly useful for html syntax, but is true when AppService.state === AppState.Ready
     */
	public readonly ready: BehaviorSubject<boolean>;
    /**
     * Mainly useful for html syntax, but is true when AppService.state === AppState.Loading
     */
	public readonly loading: BehaviorSubject<boolean>;
    /**
     * Mainly useful for html syntax, but is true when AppService.state === AppState.Error
     */
	public readonly error: BehaviorSubject<boolean>;

	public handleError(err: any, context?: string) {
		// Only accept the first error recorded
		if (this.state.value !== RedrowAppState.ERROR) {

			// Read the context from the error
			if (err instanceof ErrorWithContext) {
				context = err.context;
			}

			this.lastError.next(err);
			this.stateContext.next(context);
			this.state.next(RedrowAppState.ERROR);
		}
	}

	public stopLoading(): void {
		this.state.next(RedrowAppState.READY);
	}

	constructor() {

		this.state = new BehaviorSubject<RedrowAppState>(RedrowAppState.LOADING);
		this.stateContext = new BehaviorSubject<string>(null);
		this.lastError = new BehaviorSubject<any>(false);
		this.ready = new BehaviorSubject<boolean>(false);
		this.loading = new BehaviorSubject<boolean>(false);
		this.error = new BehaviorSubject<boolean>(false);

		this.state.subscribe(
			newState => {
				this.ready.next(newState === RedrowAppState.READY);
				this.loading.next(newState === RedrowAppState.LOADING);
				this.error.next(newState === RedrowAppState.ERROR);
			}
		);
	}



}