import { ErrorHandler, Inject, Injectable, Optional } from "@angular/core";
import {
    APP_AUTHENTICATION_SERVICE,
    AppAuthenticationServicePreLoginEvent,
    AppAuthenticationServicePreLogoutEvent,
    Guid,
    IAppAuthenticationService,
    ILogger,
    mixinNgSubscriptions,
    mixinOptionalLogging,
    NgSubscriptionsCtor,
    OptionalLoggingCtor,
    UpdateQueryString,
} from "@redrow/utilities";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { filter, share, take } from "rxjs/operators";

import { decrypt } from "../../private/functions/tiny-decrypt.function";
import { RedrowOAuthAuthenticationService } from "../../private/services/oauth-app-authentication.service";
import { IFrameHandlerService } from "../../private/services/oauth-iframe-handler.service";
import { ANGULAR_OAUTH_OIDC_SERVICE } from "../../private/tokens/angular-oauth-oidc-service.token";
import { RedrowOAuthAuthenticationIframeServiceError } from "../errors/oauth-authentication-iframe-service.error";
import { REDROW_OAUTH_IFRAME_SERVICE_LOGGER } from "../tokens/oauth-iframe-service-logger.token";



interface IExpectedCallbackResult {
    key: string;
    href: string;
    error?: string;
}



const _RedrowOAuthIframeServiceMixin: NgSubscriptionsCtor & OptionalLoggingCtor = mixinNgSubscriptions(mixinOptionalLogging(class _ { }));



/**
 * Extension for {@link RedrowOAuthAuthenticationService} which allows sign in to occur within an iframe instead of a new tab.
 * 
 * To use it in your app simply inject this service into your app component and call the bind method:
 * ```typescript
 * 
 *  constructor(
 *      // ...
 *      protected readonly redrowOAuthAuthenticationIframeService: RedrowOAuthAuthenticationIframeService
 *      // ...
 *  ) {
 * 
 *  // Bind to the oauth authentication service to use iframe flow.
 *  this.redrowOAuthAuthenticationIframeService.bind();
 * }
 * 
 * ```
 * 
 * NOTE: Make sure you handle the {@link RedrowOAuthAuthenticationIframeServiceError} error in your {@link ErrorHandler}.
 */
@Injectable({
    providedIn: "root"
})
export class RedrowOAuthAuthenticationIframeService extends _RedrowOAuthIframeServiceMixin {

    // Has the service been bound yet?
    protected _bound: boolean = false;

    constructor(
        protected readonly iframeHandler: IFrameHandlerService,
        @Inject(APP_AUTHENTICATION_SERVICE) protected readonly appAuthenticationService: IAppAuthenticationService,
        @Inject(ANGULAR_OAUTH_OIDC_SERVICE) protected readonly oauthService: OAuthService,
        @Optional() @Inject(REDROW_OAUTH_IFRAME_SERVICE_LOGGER) protected readonly logger: ILogger,
        protected readonly errorHandler: ErrorHandler
    ) {
        super();
    }

    /**
     * Bind the iframe service to the oauth authentication service
     */
    public bind() {

        if (this._bound) return;

        if (!(this.appAuthenticationService instanceof RedrowOAuthAuthenticationService)) {
            throw new Error(`Expected APP_AUTHENTICATION_SERVICE to be instanceof ${RedrowOAuthAuthenticationService.name}`);
        }
        const authService: RedrowOAuthAuthenticationService = this.appAuthenticationService;

        // Reconfigure the login service just before it authenticates the user, injecting the iframe flow.
        this.ngSubscriptions.add(
            authService.observeAppAuthenticationEvents().pipe(
                filter(x => x instanceof AppAuthenticationServicePreLoginEvent || x instanceof AppAuthenticationServicePreLogoutEvent)
            ).subscribe(
                e => {

                    const isLoginEvent = e instanceof AppAuthenticationServicePreLoginEvent;

                    // Inject params into the login url so can identify the iframe later on in communication
                    const expectedPrefix = Guid.newGuidString();
                    this.optDebug(`expectedPrefix: ${expectedPrefix}`);

                    // Reconfigure the oauth service by changing the openUri call to use this service instead
                    // Hijacking the login/logout url navigation & putting it inside an iframe instead.
                    authService.reconfigure({
                        loginFlowParams: {
                            iframeFlow: true,
                            iframePrefix: expectedPrefix,
                        },

                        // Override the openUri function so it opens in an iframe instead of a new tab
                        openUri: isLoginEvent ? (uri) => this.generateLoginOpenUriFn(expectedPrefix)(uri) : (uri) => this.generateLogoutOpenUriFn(expectedPrefix)(uri)
                    });

                }
            )
        );

        this._bound = true;
    }

    /**
     * Set a request session key for use by the callback/post-logout.html scripts
     * @param key 
     * @param value 
     * @returns 
     */
    protected setRequestParam(key: string, value: any) {
        return sessionStorage.setItem(`OCB_${key}`, JSON.stringify(value));
    }

    /**
     * Get a request session key passed back by callback/post-logout/html
     * @param key 
     * @returns 
     */
    protected getRequestParam<T>(key: string): T {
        return JSON.parse(sessionStorage.getItem(`OCB_${key}`));
    }

    /**
     * Clear request params shared with callback/post-logout.html
     */
    protected clearRequestParams() {
        let counter = 0;
        this.optDebug(`clearRequestParams`);
        while (counter < sessionStorage.length) {
            const key = sessionStorage.key(counter);
            if (key.startsWith("OCB_")) {
                sessionStorage.removeItem(key);
                counter = 0;
                continue;
            }
            counter++;
        }
    }

    /**
     * Generate a function to replace openUri that will handle the logout navigation within an iframe.
     * @param expectedPrefix
     * @returns 
     */
    protected generateLogoutOpenUriFn(expectedPrefix: string): AuthConfig["openUri"] {

        // Check if the app is configured to logout within an iframe
        const isUsingIframeLogout = this.oauthService.postLogoutRedirectUri.endsWith("post-logout.html");
        if (!isUsingIframeLogout) {
            // Do a normal redirect
            return (logoutUrl: string) => location.href = logoutUrl;
        }

        // Setup an iframe for the logout
        return (logoutUrl: string) => {

            this.optDebug(`Generated logout url: ${logoutUrl}`);
            this.optDebug(`Expected prefix: ${expectedPrefix}`);

            // Pass in the expected prefix
            this.setRequestParam("id", expectedPrefix);

            // Create the iframe and listen for a response
            this.iframeHandler
                .createListen<IExpectedCallbackResult>(logoutUrl, `${expectedPrefix}:`, {
                    fullscreen: true,
                    id: "oauth-logout-iframe",
                    timeout: 10000, // 10 second timeout
                    hideUntilReady: true,
                })
                .pipe(
                    share(),
                    take(1)
                ).subscribe(
                    result => {

                        // Cleanup and reload the app
                        this.clearRequestParams();
                        this.optInfo(`Logout success!`);
                        location.reload();

                    },
                    err => this.errorHandler.handleError(new RedrowOAuthAuthenticationIframeServiceError(err))
                );

        };
    }

    /**
     * Generate an openUri function that will allow the user to login within an iframe.
     * @param expectedPrefix
     * @returns 
     */
    protected generateLoginOpenUriFn(expectedPrefix: string): AuthConfig["openUri"] {
        return (loginUrl: string) => {
            const requiredParams = ["code", "scope", "session_state", "state"];

            this.optDebug(`Generated login url: ${loginUrl}`);
            this.optDebug(`Expected prefix: ${expectedPrefix}`);
            this.optDebug(`Required params: ${requiredParams}`);

            // Store session params to pass onto iframe
            this.setRequestParam("id", expectedPrefix);
            this.setRequestParam("required", requiredParams);

            let result: IExpectedCallbackResult = undefined;

            // Create the iframe and listen for a response
            this.iframeHandler
                .createListen<IExpectedCallbackResult>(loginUrl, `${expectedPrefix}:`, {
                    fullscreen: true,
                    id: "oauth-iframe",
                    timeout: 10000, // 10 second timeout
                    hideUntilReady: true, // Don't show the iframe until the login screen has loaded
                })
                .pipe(
                    share(),
                    take(1)
                ).subscribe(
                    result => {

                        try {
                            // Check for valid object
                            if (!("href" in result && "key" in result)) {
                                throw new Error("Missing 'key' or 'href' in iframe response.");
                            }

                            // Catch errors thrown by the iframe
                            if (result.error) {
                                throw new Error(`IFrame error: ${result.error}`);
                            }

                            this.logger.debug(`iframe result:`, { ...result });

                            // Extract encryption key from session storage
                            const encryptionKey = this.getRequestParam<string>(result.key);

                            // Remove the encryption key straight away
                            sessionStorage.removeItem(result.key);

                            this.logger.debug(`encryption key: ${encryptionKey}`);

                            // Try to decrypt the payload
                            const href = decrypt(atob(result.href), encryptionKey);

                            this.logger.debug(`href: ${href}`);

                            // Extract params from href
                            const outParams = {};

                            // Check origin
                            const url = new URL(href);
                            if (url.origin !== location.origin) {
                                throw new Error("ORIGIN MISMATCH");
                            }

                            // Extract params from result
                            const urlParams = new URLSearchParams(url.search);
                            urlParams.forEach((value, key) => {
                                outParams[key] = value;
                            });

                            this.logger.debug(`outParams:`, outParams);

                            // Push a history state with the code in so that it can be read later on by frontend auth implementation
                            let newUri = window.location.pathname;
                            for (const key of Object.keys(outParams)) {
                                newUri = UpdateQueryString(key, outParams[key], newUri);
                            }

                            history.pushState(
                                null,
                                "",
                                newUri
                            );
                        } finally {
                            // Cleanup
                            this.clearRequestParams();
                        }

                        // Now the code is in our url - we call the tryLogin oauth function to read it and clear it out
                        this.oauthService.tryLogin();

                    },
                    err => this.errorHandler.handleError(new RedrowOAuthAuthenticationIframeServiceError(err))
                );
        }
    }

}


