import { Inject, Injectable } from "@angular/core";
import { WindowService, WindowServiceEventType } from "@redrow/ng-services";
import { IChangePasswordEvent, IChangePasswordEventType, UpdateQueryString } from "@redrow/utilities";
import { Observable, of, throwError } from "rxjs";
import { filter, map, switchMap, take, tap } from "rxjs/operators";

import { IExternalOauthOpenIdConfiguration } from "../../private/interfaces/external-oauth-openid-configuration.interface";
import { IOpenIdConfigurationRepository } from "../../private/interfaces/openid-configuration-repository.service";
import { OAuthOpenIdConfigurationService } from "../../private/services/oauth-openid-configuration.service";
import { IRedrowOAuthAuthenticationServiceOptions } from "../interfaces/oauth-authentication-service-options.interface";
import { REDROW_OAUTH_AUTHENTICATION_SERVICE_OPTIONS } from "../tokens/oauth-authentication-service-options.token";

export interface IChangePasswordOptions {

    // Prefill the change password screen with a specific email address
    emailAddress?: string;

    // passed to window.open - see https://www.w3schools.com/jsref/met_win_open.asp
    // Allows you to control things like the window size
    features?: string;

    // Specify the window name/target
    windowName?: "_blank" | "_parent" | "_self" | "_top" | string;

    // How long to wait for the change password window to be ready
    // defaults to 10 seconds
    timeout?: number;
}


/**
 * 
 * This service is responsible for creating a popup that will allow the user to change their password.
 * 
 * Currently only really applies to the external oauth as you cannot change your password via internal oauth.
 * 
 * Used in Subcontractor Portal.
 * 
 */
@Injectable({
    providedIn: "root"
})
export class OAuthChangePasswordService {

    constructor(
        protected readonly windowService: WindowService,
        @Inject(OAuthOpenIdConfigurationService) protected readonly openIdConfigurationRepositoryService: IOpenIdConfigurationRepository<IExternalOauthOpenIdConfiguration>,
        @Inject(REDROW_OAUTH_AUTHENTICATION_SERVICE_OPTIONS) protected readonly authServiceOptions: IRedrowOAuthAuthenticationServiceOptions
    ) { }

    /**
     * Attempt to open a popup which would allow the user to change their password.
     * @param emailAddress Optional email address to prefill the change password form with.
     */
    public changePassword(opts?: IChangePasswordOptions): Observable<IChangePasswordEvent> {

        return this.openIdConfigurationRepositoryService.observeOpenIdConfiguration().pipe(

            take(1),

            // Then create a window using the values collected
            switchMap(
                doc => {

                    // Start with the base password change url
                    let url = doc.redrow_external_changepassword_endpoint;
                    url = UpdateQueryString("client", this.authServiceOptions.clientId, url);

                    // Prefill the email address
                    if (opts && opts.emailAddress) {
                        url = UpdateQueryString("email", opts.emailAddress, url);
                    }

                    // Default size
                    let features = "status=no,menubar=no,location=no,top=300,left=300,width=600,height=825,resizable=no,scrollbars=no";

                    // Feature overrides
                    if (opts && opts.features) {
                        // Split into key value pairs
                        let obj = {};
                        let f = features.split(",");
                        for (const pair of f) {
                            const p = pair.split("=");
                            obj[p[0]] = p.length > 1 ? p[1] : null;
                        }
                        // Overwrite provided values
                        f = opts.features.split(",");
                        for (const pair of f) {
                            const p = pair.split("=");
                            obj[p[0]] = p.length > 1 ? p[1] : null;
                        }
                        // Join back together
                        features = Object.keys(obj).map(key => `${key}=${obj[key]}`).join(",");
                    }



                    return this.windowService.open<IChangePasswordEvent>({
                        url: url,
                        windowName: opts && opts.windowName ? opts.windowName : "_blank", // default to new window
                        features: features,
                        timeout: opts && opts.timeout ? opts.timeout : 10000
                    }).pipe(


                        // Handle error events
                        switchMap(e => e.type === WindowServiceEventType.FAILED_TO_OPEN ? throwError(new Error("Failed to open window")) : of(e)),

                        // Only pass on message events
                        filter(e => e.type === WindowServiceEventType.MESSAGE),

                        // We only want the data
                        map(e => e.data),

                        // Handle error
                        switchMap(e => e.type === IChangePasswordEventType.ERROR ? throwError(new Error(e.errorMessage)) : of(e)),

                        // Handle other events
                        tap(e => {
                            if (e.type === IChangePasswordEventType.READY) {
                                // ready!
                            }
                        }),

                        // Only output on success
                        filter(e => e.type === IChangePasswordEventType.SUCCESS),

                        // Only expecting a single response
                        take(1)
                    );

                }
            )
        );

    }

}