import { Injectable } from "@angular/core";
import { LogLevel, LogLevelText } from "../../enums/log-level.enum";
import { ILoggerFormatter } from "../../interfaces/logger-formatter.interface";

/**
 * Format text for console output that looks pretty!
 */
@Injectable({
	providedIn: "root",
})
export class FancyLoggerFormat implements ILoggerFormatter {
	/**
	 * Formats like [category] (level) ...
	 */
	format(level: LogLevel, category: string, ...args: any[]): any[] {
		return [`%c[${category}] (${LogLevelText[level]})%c `, this._getLogLevelToStyling(level), "color: auto; background-color: none; padding: none; border-radius: none; width: auto; font-weight: normal;", ...args];
	}

	protected _getLogLevelToStyling(level: LogLevel) {
		const style = (color?: string, backgroundColor?: string) => {
			return `color: ${color || "auto"}; background-color: ${backgroundColor || "none"}; padding: 2px 5px border-radius: 5px; width: 125px; font-weight: bold;`;
		};
		switch (level) {
			case LogLevel.Debug:
				return style("#F8F8F8", "#BCBCBC");
				break;
			case LogLevel.Info:
				return style("#F8F8F8", "#0E4EA5");
				break;
			case LogLevel.Warn:
				return style("#F8F8F8", "#F49300");
				break;
			case LogLevel.Error:
				return style("#FFFFFF", "#CA0928");
				break;
			case LogLevel.Fatal:
				return style("#FFFFFF", "#1B1B1B");
				break;
		}
		return style();
	}
}
