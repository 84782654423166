/* AUTOEXPORT:SETTING:MODULE:RedrowLoadingScreen */
import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { CONFIGURATION_MAPPER, IConfiguration } from "@redrow/configuration";
import { redLibraryIconRedrowLogoRed, RedLibraryIconType } from "@redrow/ng-redlibrary-icons";
import { ICON_REGISTRY_SERVICE, IIconRegistryService } from "@redrow/utilities";

import { RedrowBaseComponent } from "../base-component";
import { ILoadingScreenActionDisplay } from "../interfaces/loading-screen-action-display.interface";
import { RedrowLoadingScreenService } from "../service/loading-screen.service";

interface IAppConfigWithLocalVersion {
	/**
	 * Pulled in from TeamCity - the build number
	 */
	LOCAL_VERSION: string,
}

@Component({
	selector: "rr-loading-screen",
	styleUrls: ["./loading-screen.component.scss"],
	templateUrl: "./loading-screen.component.html",
})
export class RedrowLoadingScreenComponent extends RedrowBaseComponent implements OnInit {
	public displayActions: ILoadingScreenActionDisplay[] = [];

	get appVersion(): string {
		return this.config.get().LOCAL_VERSION;
	}

	constructor(
		protected readonly loadingScreenService: RedrowLoadingScreenService,
		protected readonly router: Router,
		protected readonly cd: ChangeDetectorRef,
		@Inject(CONFIGURATION_MAPPER) protected readonly config: IConfiguration<IAppConfigWithLocalVersion>,
		@Optional() @Inject(ICON_REGISTRY_SERVICE) protected readonly iconRegistry: IIconRegistryService<RedLibraryIconType>
	) {
		super();

		if (this.iconRegistry) {
			this.iconRegistry.addSvg(redLibraryIconRedrowLogoRed);
		}
	}

	get isLoading() {
		return this.loadingScreenService.loading;
	}

	ngOnInit() {
		this.ngSubscriptions.add(
			this.loadingScreenService.actionDisplayStatus.subscribe(x => {
				this.displayActions = x.map(y => {
					return {
						...y,
					};
				});

				this.cd.detectChanges();
			})
		);
	}
}
