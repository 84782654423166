import { IJwtTokenBase } from "@redrow/utilities";

export class JwtToken implements IJwtTokenBase {
	protected _raw: { [key: string]: any };

	constructor(raw: any) {
		this._raw = raw;
	}

	get nbf() {
		return new Date(this._raw.nbf * 1000);
	}

	get exp() {
		return new Date(this._raw.exp * 1000);
	}

	get iat() {
		return new Date(this._raw.iat * 1000);
	}
}
