import { Component, Inject, Input, Optional, Output } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { redLibraryIconCancel, RedLibraryIconType } from "@redrow/ng-redlibrary-icons";
import { ICON_REGISTRY_SERVICE, IIconRegistryService } from "@redrow/utilities";
import { Subject } from "rxjs";

export interface IConfirmationModalData {
	title?: string;
	content?: string;
}

@Component({
	selector: "confirm-modal",
	templateUrl: `./confirm-modal.component.html`
})
export class ConfirmationModalComponent {

	@Output("yes")
	public yes: Subject<void> = new Subject<void>();

	@Output("no")
	public no: Subject<void> = new Subject<void>();

	@Input()
	public title: string;

	@Input()
	public content: string;

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) protected readonly data: IConfirmationModalData,
		@Optional() @Inject(ICON_REGISTRY_SERVICE) protected readonly iconRegistry: IIconRegistryService<RedLibraryIconType>
	) {
		if (data) {
			this.title = data.title ? data.title : this.title;
			this.content = data.content ? data.content : this.content;
		}

		if (this.iconRegistry) {
			this.iconRegistry.addSvg(redLibraryIconCancel);
		}
	}

}
