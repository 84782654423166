import { ComponentType } from "@angular/cdk/overlay";
import { Injectable, TemplateRef } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subscription } from "rxjs";

export enum MatDialogExtendedEventType {
	OPEN = 1,
	CLOSED = 2,
	BACKDROP_CLICK = 3,
	KEYDOWN_EVENT = 4
}

export interface IMatDialogExtendedEvent<T, R = any> {
	type: MatDialogExtendedEventType;
	modal: MatDialogRef<T, R>;
	result?: R;
}

@Injectable({
	providedIn: "root"
})
export class RedrowModalService {

	constructor(protected readonly dialog: MatDialog) {

	}

	public openSubscribe<T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): Observable<IMatDialogExtendedEvent<T, R>> {
		return new Observable(observable => {

			let subs = new Subscription();
			let modal = this.dialog.open(componentOrTemplateRef, config);

			subs.add(
				modal.afterClosed().subscribe(
					x => {
						observable.next({
							modal: modal,
							result: x,
							type: MatDialogExtendedEventType.CLOSED
						});
						modal = undefined;
						observable.complete();
					}
				)
			);

			subs.add(
				modal.afterOpened().subscribe(
					() => observable.next({
						type: MatDialogExtendedEventType.OPEN,
						modal: modal
					})
				)
			);

			// TODO: Could subscribe to these and post other events?
			// modal.beforeClosed().subscribe();
			// modal.backdropClick().subscribe();
			// modal.keydownEvents().subscribe();

			return {
				unsubscribe() {
					if (subs) {
						subs.unsubscribe();
						subs = undefined;
					}
					if (modal) {
						modal.close();
						modal = undefined;
					}
				}
			};

		});
	}


}
