import { Component, Inject, OnDestroy, OnInit } from "@angular/core";

import { RedrowBaseComponent } from "../../base-component";
import { IGenericActionProvider } from "../../interfaces/generic-action-provider.interface";
import { IGenericAction } from "../../interfaces/generic-action.interface";
import { GENERIC_ACTIONS } from "../../tokens/generic-action-provider.token";



@Component({
	selector: "rr-action-button-bar",
	styleUrls: [
		`./generic-action-button-bar.component.scss`
	],
	templateUrl: "./generic-action-button-bar.component.html",
	// providers: [

	// 	/**
	// 	 * Provide a way for actions to be communicated between the different tabs to this container
	// 	 */
	// 	{
	// 		provide: GENERIC_ACTIONS,
	// 		useClass: SimpleActionProvider
	// 	}
	// ]
})
export class GenericActionButtonBarComponent extends RedrowBaseComponent implements OnDestroy, OnInit {

	constructor(

		@Inject(GENERIC_ACTIONS) protected readonly actionProvider: IGenericActionProvider
	) {
		super();



	}

	ngOnInit() {
		this.ngSubscriptions.add(
			this.actionProvider.consumeActions().subscribe(
				actions => this.actions = actions
			)
		);
	}

	ngOnDestroy() {
		this.actionProvider.clearActions();
	}


	actions: IGenericAction[];

	clickAction(action: IGenericAction) {
		this.ngSubscriptions.add(
			action.action.subscribe()
		);
	}



}
