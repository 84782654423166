import { InjectionToken } from "@angular/core";

import { ISvgIcon } from "../../interfaces/registry/svg-icon.interface";

/**
 * Provide icons for the icon registry.
 * i.e.
 * ```typescript
 * {
 *  provide: ICON_REGISTRY,
 *  useValue: myIcon,
 *  multi: true
 * }
 * ```
 */
export const ICON_REGISTRY = new InjectionToken<ISvgIcon<any>[]>("ICON_REGISTRY");