import { Injectable } from "@angular/core";
import { defer, Observable } from "rxjs";
import { finalize, map, switchMap } from "rxjs/operators";

import { RedrowCanvasConverterService } from "../../canvas/redrow-canvas-converter.service";
import { RedrowCanvasManagerService } from "../../canvas/redrow-canvas-manager.service";
import { RedrowImageConverterService } from "../redrow-image-converter.service";
import { IImageResizerOptions } from "./IImageResizerOptions";
import { IImageResizeBlobOptions } from "./image-resize-blob-options.interface";

/***
 * Utilities for resizing images.
 */
@Injectable({
	providedIn: "root"
})
export class RedrowImageResizerService {

	protected readonly resizeImageBlobCanvasName: string = "resizeImageBlob";

	constructor(
		protected readonly redrowCanvasConverterService: RedrowCanvasConverterService,
		protected readonly redrowCanvasManagerService: RedrowCanvasManagerService,
		protected readonly redrowImageConverterService: RedrowImageConverterService
	) { }

	/**
	 * 
	 * @param {HTMLImageElement} image image to resize
	 * @param {IImageResizerOptions} options resize options
	 * @returns 
	 */
	protected resizeImageToCanvas(image: HTMLImageElement, options: IImageResizerOptions): Observable<HTMLCanvasElement> {
		// Create a canvas to resize the blob with
		return defer(() => {
			return this.redrowCanvasManagerService.get(this.resizeImageBlobCanvasName, options.width, options.height, 2000)
				.pipe(

					// Draw new image using generated canvas and return the canvas
					map((canvas => {
						const ctx = canvas.getContext("2d");
						ctx.drawImage(image, 0, 0, options.width, options.height);
						return canvas;
					})),

					// Unlock canvas resource
					finalize(() => this.redrowCanvasManagerService.unlock("resizeImageBlob"))
				);
		});
	}

	/**
	 * Resize a HTML image element to the desired target
	 * @param image HTMLImageElement to resize
	 * @param options image options to configure resizing with
	 * @returns HTMLImageElement
	 */
	resizeImage(image: HTMLImageElement, options: IImageResizerOptions): Observable<HTMLImageElement> {
		// Resize image using canvas
		return this.resizeImageToCanvas(image, options)
			.pipe(

				// Convert canvas to blob
				switchMap(canvas => this.redrowCanvasConverterService.toBlob(
					canvas,
					{
						mimeType: options.mime,
						quality: options.quality
					}
				)),

				// Convert blob to image
				switchMap(blob => this.redrowImageConverterService.fromBlob(blob)),
			);
	}

	/**
	 * Resize an image blob to the desired target
	 * @param imageBlob blob to resize
	 * @param options blob options to configure resizing with
	 * @returns Blob
	 */
	resizeBlob(imageBlob: Blob, options: IImageResizeBlobOptions): Observable<Blob> {

		// Convert blob to image
		return this.redrowImageConverterService.fromBlob(imageBlob)
			.pipe(

				// Resize image using canvas
				switchMap(image => {
					// Calculate desired size if function has been provided
					const { width: imageWidth, height: imageHeight } = options?.calcDimensionFn ?
						options.calcDimensionFn(image.width, image.height) :
						// Use the image's current dimensions if no function is provided
						{ width: image.width, height: image.height };

					return this.resizeImageToCanvas(
						image,
						{
							width: imageWidth,
							height: imageHeight,
							quality: options.quality,
							mime: options.mime
						}
					)
				}),

				// Convert canvas to blob
				switchMap(canvas => this.redrowCanvasConverterService.toBlob(
					canvas,
					{
						mimeType: options.mime,
						quality: options.quality
					}
				))

			);
	}
}