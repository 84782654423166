import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { PageShellModule } from "@shared/page-shell/page-shell.module";

import { DocumentsComponent } from "./documents.component";



@NgModule({
	declarations: [
		DocumentsComponent,

	],
	imports: [
		CommonModule,

		MatProgressSpinnerModule,
		MatListModule,
		PageShellModule
	]
})
export class DocumentsModule { }
