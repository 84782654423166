
export function MaxDimensionSizeResolver(maxDimension: number) {
	return (target: HTMLImageElement) => {
		let targetWidth: number;
		let targetHeight: number;
		if (target.width > maxDimension || target.height > maxDimension) {
			// Scale image down so it fits within the maxWidth x maxHeight
			if (target.width > target.height) {
				// scale by width
				const f = target.height / target.width;
				targetWidth = maxDimension;
				targetHeight = targetWidth * f;
			}
			else {
				// scale by height
				const f = target.width / target.height;
				targetHeight = maxDimension;
				targetWidth = targetHeight * f;
			}
		}
		return {
			width: targetWidth,
			height: targetHeight
		};
	};
}
