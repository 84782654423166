import { ModuleWithProviders, NgModule } from "@angular/core";
import { getDefaultConfigurationMapperProvider } from "./functions/default-configuration-mapper.function";
import { getDefaultLegacyConfigurationMapperProvider } from "./functions/default-legacy-configuration-provider.function";
import { CONFIGURATION } from "./tokens/configuration.token";
import { MergeConfiguration } from "./types/configuration-merge.type";

@NgModule({})
export class ConfigurationModule {
	static defaultWithConfiguration<TConfiguration, T1, T2 = undefined, T3 = undefined, T4 = undefined>(
		config: MergeConfiguration<TConfiguration, T1, T2, T3, T4>,
		provideLegacyConfig?: boolean
	): ModuleWithProviders<ConfigurationModule> {
		return {
			ngModule: ConfigurationModule,
			providers: [
				{
					provide: CONFIGURATION,
					useValue: config,
					multi: false
				},
				getDefaultConfigurationMapperProvider<TConfiguration, T1, T2, T3, T4>(),
				...(provideLegacyConfig ? [getDefaultLegacyConfigurationMapperProvider()] : [])
			]
		};
	}

	static defaultWithConfigurationFactory<TConfiguration, T1, T2 = undefined, T3 = undefined, T4 = undefined>(
		config: () => MergeConfiguration<TConfiguration, T1, T2, T3, T4>,
		provideLegacyConfig?: boolean
	): ModuleWithProviders<ConfigurationModule> {
		return {
			ngModule: ConfigurationModule,
			providers: [
				{
					provide: CONFIGURATION,
					useFactory: config,
					multi: false
				},
				getDefaultConfigurationMapperProvider<TConfiguration, T1, T2, T3, T4>(),
				...(provideLegacyConfig ? [getDefaultLegacyConfigurationMapperProvider()] : [])
			]
		};
	}
}
