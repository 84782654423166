import { IConfiguration } from "@redrow/configuration";
import { IAuthConfig } from "@redrow/oauth";

import { IAppConfiguration } from "./external-contractor-login-app.interface";

/**
 * A factory function to take the configured apiUrl for the app & provide IAuthConfig format
 */
export function ResolveAngularOAuthConfigFromAppConfiguration(config: IConfiguration<IAppConfiguration>): IAuthConfig {
	return {
		resourceServer: {
			sendAccessToken: true,
			allowedUrls: [
				config.get().apiUrl
			]
		}
	};
};
