import { OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { Constructor } from "../constructor.type";
import { NgSubscriptionsCtor } from "./ng-subscriptions-ctor.type";

/**
 * Mixin that provides {@link INgSubscriptions} interface, allowing you to more easily manage your RXJS Subscriptions.
 * 
 * Usage:
 * ```typescript
 * // Import for this mixin
 * import { mixinNgSubscriptions } from "@redrow/utilities"
 * // ... Other imports omitted
 * 
 * // Construct a class to extend from which has the implementation of INgSubscriptions
 * const _MixinMyAwesomeComponent = mixinNgSubscriptions(class MyAwesomeComponentBase {});
 * 
 * @Component({
 *   // ...
 * })
 * export class MyAwesomeComponent extends _MixinMyAwesomeComponent { // Then we extend from the class we created above to inherit the implementation.
 * 
 *  constructor(
 *   protected readonly httpClient: HttpClient
 *  ){
 *      super(); // Be sure to call super()
 *  }
 * 
 *  buttonClick() {
 *   // Then make sure to add any subscriptions for this view to ngSubscriptions so they can be teared down when this component is
 *   this.ngSubscriptions.add(
 *      this.httpClient.get("https://www.google.com/").subscribe()
 *   );
 *  }
 * 
 * }
 * ```
 * 
 * **NOTE** Please refer to {@page ~~/wiki/best-practice/import-inferred-types.md} for a common gotcha when using this within the library.
 * 
 * @param base Another class to extend
 * @returns The base class extended implementing {@link INgSubscriptions}
 */
export function mixinNgSubscriptions<T extends Constructor<{}>>(base: T): Constructor<OnDestroy> & NgSubscriptionsCtor & T {
    return class extends base implements OnDestroy {
        private _ngSubscriptions: Subscription | undefined;

        /**
         * When subscribing to events you must add discardable events to this list.
         * When the component is destroyed this list of subscriptions will automatically
         * be freed from memory and unbound.
         */
        get ngSubscriptions(): Subscription {
            if (this._ngSubscriptions === undefined || this._ngSubscriptions === null || this._ngSubscriptions.closed) {
                this._ngSubscriptions = new Subscription();
            }
            return this._ngSubscriptions;
        }

        constructor(...args: any[]) { super(...args); }

        ngOnDestroy() {
            this.clearNgSubscriptions();
        }

        clearNgSubscriptions(): void {
            if (this._ngSubscriptions) {
                this._ngSubscriptions.unsubscribe();
                this._ngSubscriptions = undefined;
            }
        }
    };
}