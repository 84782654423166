import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { RedrowBaseComponent } from "@redrow/material";
import { OAuthChangePasswordService } from "@redrow/oauth";
import { IChangePasswordEventType, IRedrowOAuthService, OAUTH_SERVICE } from "@redrow/utilities";
import { UserManagementController } from "@shared/api/usermanagment.controller";
import { ContractorProfile } from "@shared/oauth/contractor-profile";
import { IContractorProfile } from "@shared/oauth/contractor-profile.interface";
import { ErrorContext, UserMessageService } from "@shared/services/user-message-service.service";
import { iif, of, throwError } from "rxjs";
import { concatMap, delay, first, retryWhen, switchMap } from "rxjs/operators";

import { EditProfileFormService } from "./edit-profile.form";



@Component({

	templateUrl: './settings.component.html',
	styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent extends RedrowBaseComponent implements OnInit {

	protected _user: IContractorProfile;

	get user(): IContractorProfile {
		return this._user;
	}

	set user(newUser: IContractorProfile) {
		this._user = newUser;

		if (this._user) {
			this.forename = this._user.name ? this._user.name.split(" ")[0] : null;
			this.surname = this._user.name ? this._user.name.split(" ")[1] : null;
			this.emailAddress = this._user.email;
		} else {
			this.forename = null;
			this.surname = null;
			this.emailAddress = null;
		}
	}

	forename: string;
	surname: string;
	emailAddress: string;
	editMode: boolean;

	form: UntypedFormGroup;

	constructor(
		@Inject(OAUTH_SERVICE) protected readonly oauthService: IRedrowOAuthService<IContractorProfile>,
		protected readonly userManagementController: UserManagementController,
		protected readonly formService: EditProfileFormService,
		protected readonly changePasswordService: OAuthChangePasswordService,
		protected readonly userMessageService: UserMessageService
	) {
		super();


	}

	changePassword() {
		this.loading = true;
		this.ngSubscriptions.add(
			this.getUser().pipe(
				switchMap(
					user => {
						// Open change password popup
						return this.changePasswordService.changePassword({
							emailAddress: user.email
						});
					}
				)
			).subscribe(
				res => {
					if (res && res.type === IChangePasswordEventType.SUCCESS) {
						this.ngSubscriptions.add(
							this.userMessageService.showNotice(
								`Your password has been changed successfully!`,
								`Change Password`
							).subscribe()
						);
					}
				},
				err => {
					this.ngSubscriptions.add(
						this.userMessageService.showErrorNotice(
							`Something went wrong when changing your password, please try again later.`,
							ErrorContext.SAVING,
							`Change Password`
						).subscribe()
					);

					this.loading = false;
				},
				() => this.loading = false
			)
		);
	}

	getUser() {
		return this.oauthService.getUserProfile().pipe(
			first(),
			switchMap(user => {
				if (!user) {
					return throwError(new Error("No user available"));
				}
				return of(user);
			})
		).pipe(
			// Retry a few times - the user profile may not have loaded yet - up to 5 seconds
			retryWhen(errors => errors.pipe(
				concatMap(
					(e, i) => iif(
						() => i > 50,
						throwError(e),
						of(e).pipe(delay(50))
					)
				)
			))
		);
	}

	refresh() {
		this.loading = true;
		this.error = null;
		this.ngSubscriptions.add(
			this.getUser().subscribe(
				user => {
					this.user = user;

					this.refreshForm();
				},
				err => {
					this.error = "Unable to load your profile right now, please try again later.";
					this.loading = false;
				},
				() => {
					if (!this.user) {
						this.error = "Unable to load your profile right now, please try again later.";
					}
					this.loading = false
				}
			)
		);
	}

	setupForm() {
		this.form = this.formService.create();
	}

	// Shortcut to form controls
	get f() {
		return this.form?.controls;
	}

	refreshForm() {
		this.form.patchValue({
			forename: this.user.name ? this.user.name.split(" ")[0] : null,
			surname: this.user.name ? this.user.name.split(" ")[1] : null,
			email: this.user.email
		});

		if (this.editMode) {
			this.form.enable();
			this.form.get("email").disable();
		} else {
			this.form.disable();
		}
	}

	ngOnInit() {

		this.setupForm();

		this.refresh();
	}


	async leaveEditModeAndRefreshUserInfo() {
		this.loading = true;

		try {
			await this.oauthService.refreshUserProfile(true);
			this.editMode = false;
			this.refresh();
		}
		catch (e) {
			console.error(e);
			this.error = "Failed to refresh user profile";
		}
		finally {
			this.loading = false;
		}
	}

	public saveProfile() {

		if (this.form.invalid) {
			return;
		}

		this.loading = true;
		this.error = null;

		this.ngSubscriptions.add(
			this.userManagementController.patchUser(this.user.id, this.formService.get(this.form)).subscribe(
				success => {
					if (success) {
						this.leaveEditModeAndRefreshUserInfo();
					} else {
						this.error = "Failed to save profile";
					}
				},
				err => {
					this.error = "Something went wrong when saving your profile. Please try again.";
					this.loading = false;
				}
			)
		);

	}

	public editProfile() {
		this.editMode = true;
		this.refreshForm();
	}

	public cancelProfileChange() {
		this.editMode = false;
		this.user = new ContractorProfile({
			...this.user["_raw"]
		});
		this.refreshForm();
	}


}
