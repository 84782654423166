import { Provider } from "@angular/core";
import { DeepMergeConfiguration } from "../services/deepmerge-configuration-mapper.service";
import { CONFIGURATION_MAPPER } from "../tokens/configuration-mapper.token";

/**
 * Default configuration mapper provider
 */
export function getDefaultConfigurationMapperProvider<TConfiguration, T1, T2 = undefined, T3 = undefined, T4 = undefined>(): Provider {
	return {
		provide: CONFIGURATION_MAPPER,
		useClass: DeepMergeConfiguration<TConfiguration, T1, T2, T3, T4>,
		multi: false
	};
}