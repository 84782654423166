import { Injectable } from "@angular/core";
import { Guid, UpdateQueryString } from "@redrow/utilities";
import { interval, Observable, Subscription, timer } from "rxjs";

import { PrefixedPostMessageService } from "../post-message/prefixed-post-message.service";

export enum WindowServiceEventType {
    OPEN = 1,
    CLOSE = 2,
    MESSAGE = 3,
    FAILED_TO_OPEN = 4
}

export interface IWindowServiceEvent<DataType> {
    type: WindowServiceEventType;
    source: Window;
    data?: DataType;
}

// See https://developer.mozilla.org/en-US/docs/Web/API/Window/open
interface IWindowServiceOpenOptions {
    url: string;
    windowName?: "_blank" | "_parent" | "_self" | "_top" | string;
    features?: string;

    /**
     * @deprecated Source: [Bugs Chromium Issue 1164959](https://bugs.chromium.org/p/chromium/issues/detail?id=1164959).
     */
    replace?: boolean;

    // Key to inject into the url with an expected prefix - defaults to ws-prefix
    // You can set this to null if you don't want it to append the prefix to the url
    expectedPrefixKey?: string;
    // Optionally provide your own prefix - defaults to a random guid
    expectedPrefixValue?: string;

    // Optional timeout error if the window does not send a message within a given period of time
    // ms
    timeout?: number;
}

/**
 * This service can open up a child window and setup communication with it.
 * The child window must read the client param ws-prefix and prefix all encoded messages with it when sending them via postMessage.
 */
@Injectable({
    providedIn: "root"
})
export class WindowService {

    constructor(
        protected readonly prefixedPostMessageService: PrefixedPostMessageService
    ) {

    }

    public open<DataType>(opts: IWindowServiceOpenOptions): Observable<IWindowServiceEvent<DataType>> {
        return new Observable<IWindowServiceEvent<DataType>>(observable => {

            const ep = opts.expectedPrefixValue ? opts.expectedPrefixValue : Guid.newGuidString();
            let win: Window;
            let _close: () => void = null;
            let _subs: Subscription = new Subscription();
            let _timeout: Subscription;

            let _cleanup: () => void = null;
            _cleanup = () => {

                // Close window?
                if (_close) {
                    _close();
                }

                // Unsubscribe from subs
                if (_subs) {

                    if (!_subs.closed) {
                        _subs.unsubscribe();
                    }
                    _subs = undefined;
                }

                // Cleanup vars
                win = undefined;
                _close = undefined;
                _cleanup = undefined;
            };

            _close = () => {
                if (!win) return;
                win.close();

                // Fire close event
                observable.next({
                    type: WindowServiceEventType.CLOSE,
                    source: win
                });

                win = undefined;
            };

            // Attempt to open the window

            try {
                let resolvedUrl = opts.url;

                // Append the expected prefix onto the url
                if (opts.expectedPrefixKey !== null) {
                    const epk = opts.expectedPrefixKey === undefined ? "ws-prefix" : opts.expectedPrefixKey;
                    resolvedUrl = UpdateQueryString(epk, ep, resolvedUrl)
                }

                win = window.open(
                    resolvedUrl,
                    opts.windowName,
                    opts.features
                );
                if (!win) throw new Error("Failed to open window");
            } catch (e) {
                observable.next({
                    type: WindowServiceEventType.FAILED_TO_OPEN,
                    source: null
                });
                observable.complete();
            }

            // Fire the open event
            observable.next({
                type: WindowServiceEventType.OPEN,
                source: win
            });

            // timeout
            if (opts && typeof opts.timeout === "number") {
                _timeout = timer(opts.timeout).subscribe(
                    () => observable.error(new Error("Timeout"))
                );

                _subs.add(
                    _timeout
                );
            }

            // Check for window close
            _subs.add(
                interval(100).subscribe(
                    () => {
                        if (!win) return;
                        if (win.closed) {
                            _close();

                            // We're done here
                            observable.complete();
                        }
                    }
                )
            );

            // Setup communication with the window
            _subs.add(
                this.prefixedPostMessageService.listenForMessages<DataType>({
                    expectedPrefix: ep,
                    target: "current",
                    targetOrigin: new URL(opts.url).origin
                }).subscribe(
                    msg => {
                        // Clear timeout
                        if (_timeout) {
                            if (!_timeout.closed) {
                                _timeout.unsubscribe();
                            }
                            _timeout = undefined;
                        }

                        // Pass along the message
                        observable.next({
                            type: WindowServiceEventType.MESSAGE,
                            source: win,
                            data: msg
                        })
                    },
                    err => observable.error(err)
                )
            );

            return {
                unsubscribe() {
                    if (_cleanup) {
                        _cleanup();
                    }
                }
            };

        });
    }

}