import { MakeApiUrl, RedrowEnvironmentType } from "@redrow/utilities";
import { AuthConfig } from "angular-oauth2-oidc";

import { IOAuthClientConfig } from "../interfaces/application-config-with-oauth.interface";

/**
 * Used in Inspection Portal to configure oauth.
 * @deprecated Please see {@page ~~/wiki/solutions/authentication/oauth-quickstart.md} for how to configure OAuth.
 */
export function BaseOAuthCOnfig(customAuthConfig: Partial<AuthConfig>): IOAuthClientConfig {
	// Standard oauth settings
	const authConfig: AuthConfig = {
		// Url of the Identity Provider
		// issuer: "https://local-oauth.redrow.co.uk",

		// URL of the SPA to redirect the user to after login
		redirectUri: `${window.location.origin}/oauth/callback.html`,

		// URL of the SPA to redirect the user after a logout
		postLogoutRedirectUri: `${window.location.origin}/`,

		// set the scope for the permissions the client should request
		// The first three are defined by OIDC. The 4th is a usecase-specific one
		scope: "openid redrow.profile offline_access",

		// We require a https connection
		requireHttps: true,

		// Authenticate using openid
		oidc: true,

		// We want an access token so we can talk to our API
		requestAccessToken: true,

		// Code Flow so we can use PKCE
		responseType: "code",

		showDebugInformation: false,

		// We want to be notified if the user is logged out from outside this app
		sessionChecksEnabled: true,
	};

	// Merge users custom object
	if (customAuthConfig) {
		// merge scope manually
		if (typeof customAuthConfig.scope === "string" && customAuthConfig.scope.length > 0) {
			const mergeScopes = customAuthConfig.scope.split(" ").filter(x => !x.startsWith("-"));
			const existingScopes = authConfig.scope.split(" ");

			// List of scopes to remove
			const toRemove = customAuthConfig.scope
				.split(" ")
				.filter(x => x.startsWith("-"))
				.map(y => y.substr(1, y.length - 1));

			// combined
			authConfig.scope = [...existingScopes, ...mergeScopes]
				// unique
				.filter((value, index, self) => {
					return self.indexOf(value) === index && toRemove.indexOf(value) === -1;
				})
				// joined into one string
				.join(" ");
		}

		if (!("clientId" in customAuthConfig)) {
			throw new Error("Missing clientId in auth config!");
		}

		// Overwrite authconfig with custom
		for (const key of Object.keys(customAuthConfig)) {
			// Skip scope as the merge is handled earlier
			if (key === "scope") {
				continue;
			}

			// Overwrite base with custom
			authConfig[key] = customAuthConfig[key];
		}
	}

	/**
	 * Build up a merge object to be merged with the transform config
	 */
	const OAuthAppConfigPart: IOAuthClientConfig = {
		OAuthClient: authConfig,
	};

	return OAuthAppConfigPart;
}

export function EnvironmentOAuthConfig(env: RedrowEnvironmentType, customAuthConfig?: Partial<AuthConfig>): IOAuthClientConfig {
	// Standard oauth settings
	const authConfig: AuthConfig = {
		// Url of the Identity Provider

		// We require a https connection
		requireHttps: env !== "Local",

		showDebugInformation: env === "Local",

		// Default to the ms-oauth server
		issuer: MakeApiUrl(env, "ms-oauth.redrow.co.uk", window.location.protocol === "https:"),
	};

	// Merge users custom object
	if (customAuthConfig) {
		// Overwrite authconfig with custom
		for (const key of Object.keys(customAuthConfig)) {
			// Skip scope as the merge is handled earlier
			if (key === "scope") {
				continue;
			}

			// Overwrite base with custom
			authConfig[key] = customAuthConfig[key];
		}
	}

	/**
	 * Build up a merge object to be merged with the transform config
	 */
	const OAuthAppConfigPart: IOAuthClientConfig = {
		OAuthClient: authConfig,
	};

	return OAuthAppConfigPart;
}
