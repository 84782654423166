import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class LoadingService {

	protected readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	protected get _loadingCounter(): number {
		return this._loaders.length;
	}
	protected _loaders: string[] = [];

	public startLoading(loaderName: string) {
		this._loaders.push(loaderName);
		this.refreshLoading();
	}

	public stopLoading(loaderName: string) {
		const idx = this._loaders.indexOf(loaderName);
		if (idx === -1) {
			console.error(`COULD NOT FIND LOADER!`, loaderName);
		}
		this._loaders.splice(idx, 1);
		this.refreshLoading();
	}

	protected refreshLoading() {
		console.log(this._loaders);
		const shouldBeLoading = this._loadingCounter > 0;
		if (shouldBeLoading !== this._loading.value) {
			this._loading.next(shouldBeLoading);
		}
	}

	public isLoading(): BehaviorSubject<boolean> {
		return this._loading;
	}

	protected _lastKnownScrollY: number;

	constructor() {
		this._loading.subscribe(loading => {

			// Node check
			if (typeof window === "undefined") return;

			// Lock the body if we are loading
			if (loading) {

				if (window.scrollY > 0) {
					this._lastKnownScrollY = window.scrollY;

					document.body.style.position = "fixed";
					document.body.style.top = `-${window.scrollY}px`;
					document.body.style.left = "0";
					document.body.style.right = "0";
				}

			} else {

				if (typeof this._lastKnownScrollY !== "number") return;
				if (this._lastKnownScrollY === 0) return;

				setTimeout(
					() => {
						const scrollY = document.body.style.top;
						document.body.style.position = "";
						document.body.style.top = "";
						document.body.style.left = "";
						document.body.style.right = "";
						// window.scrollTo(0, parseInt(scrollY || '0') * -1);

						window.scrollTo(0, this._lastKnownScrollY);
					}
					, 1
				);


			}

		});
	}

}
