<div class="error-wrapper">
	<div class="error-container">
		<rr-icon name="redrow-flower" [size]="50"></rr-icon>
		<h1 class="main-text">
			You might be offline
		</h1>
		<p class="sub-text">
			It looks like you might be offline. Please check your connection and tap the refresh button below
		</p>
		<rr-button class="btn reload" icon="refresh" (rrClick)="reloadApplication()">Reload {{ appName }}</rr-button>
	</div>
</div>
