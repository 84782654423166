import { ModuleWithProviders, NgModule } from "@angular/core";
import { ILoggerDestination } from "./interfaces/logger-destination.interface";
import { ILoggerFilter } from "./interfaces/logger-filter.interface";
import { ConsoleLogger } from "./services/destinations/console-logger.service";
import { FancyLoggerFormat } from "./services/formatters/fancy-format.service";
import { DefaultLogger } from "./services/loggers/default-logger.service";
import { LOGGER_CATEGORY } from "./tokens/logger-category.token";
import { LOGGER_DESTINATION } from "./tokens/logger-destination.token";
import { LOGGER_FORMAT } from "./tokens/logger-formatter.token";
import { LOGGER } from "./tokens/logger.token";

export function DefaultLoggerFactoryWithCategory(category: string) {
	return (loggers: ILoggerDestination | ILoggerDestination[], filters: ILoggerFilter | ILoggerFilter[]) => {
		return new DefaultLogger(loggers, filters, category);
	};
}
@NgModule({})
export class LoggerModule {
	static disabled(): ModuleWithProviders<LoggerModule> {
		return {
			ngModule: LoggerModule,
			providers: [
				// Use the default logger middleware - this shows as an example of how to out right disable logging from a particular module
				// A logger is provided but with no destination provided, nothing will happen
				{
					provide: LOGGER,
					useClass: DefaultLogger,
					multi: false,
				},
			],
		};
	}

	/**
	 * Provide the default logging environment.
	 * This includes a console logger which logs to the default category.
	 */
	static default(): ModuleWithProviders<LoggerModule> {
		return {
			ngModule: LoggerModule,
			providers: [
				// Use the default logger middleware
				{
					provide: LOGGER,
					useClass: DefaultLogger,
					multi: false,
				},

				// Use the default category
				{
					provide: LOGGER_CATEGORY,
					useValue: "default",
					multi: false,
				},

				// Implement a console logger by default
				{
					provide: LOGGER_DESTINATION,
					useClass: ConsoleLogger,
					multi: true,
				},

				// Implement fancy logging for console
				{
					provide: LOGGER_FORMAT,
					useClass: FancyLoggerFormat,
					multi: false,
				},
			],
		};
	}

	/**
	 * Provide the default logging environment with a custom category value.
	 * This includes a console logger which logs to the default category.
	 */
	static defaultWithCustomCategory(category: string): ModuleWithProviders<LoggerModule> {
		return {
			ngModule: LoggerModule,
			providers: [
				// Use the default logger middleware
				{
					provide: LOGGER,
					useClass: DefaultLogger,
					multi: false,
				},

				// Use the default category
				{
					provide: LOGGER_CATEGORY,
					useValue: category,
					multi: false,
				},

				// Implement a console logger by default
				{
					provide: LOGGER_DESTINATION,
					useClass: ConsoleLogger,
					multi: true,
				},

				// Implement fancy logging for console
				{
					provide: LOGGER_FORMAT,
					useClass: FancyLoggerFormat,
					multi: false,
				},
			],
		};
	}

	/**
	 * Provide the default logging environment with a custom category value.
	 * This includes a console logger which logs to the default category.
	 */
	// static defineLibraryModuleLogging(category: string): ModuleWithProviders {
	// 	return {
	// 		ngModule: LoggerModule,
	// 		providers: [
	// 			// Use the default logger middleware
	// 			{
	// 				provide: LOGGER,
	// 				useFactory: DefaultLoggerFactoryWithCategory(category),
	// 				deps: [LOGGER_DESTINATION, LOGGER_FILTER],
	// 				multi: false,
	// 			},
	// 		],
	// 	};
	// }
}
