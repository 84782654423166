import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatIconRegistryService } from "@redrow/ng-material-components";
import { ICON_REGISTRY_SERVICE } from "@redrow/utilities";

@NgModule()
export class SubbyAppIconRegistryModule {
	public static forRoot(): ModuleWithProviders<SubbyAppIconRegistryModule> {
		return {
			ngModule: SubbyAppIconRegistryModule,
			providers: [

				{
					provide: ICON_REGISTRY_SERVICE,
					useClass: MatIconRegistryService
				}

			]
		};
	}
}
