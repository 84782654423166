/**
 * Base component for services and components.
 * Comes with logging this.console().warn etc
 * Also comes with this.AppConfig which gets injected into the core library
 */
export class RedrowBaseClass {

	constructor() { }

	/**
	 * A function to dispose resources used by this object.
	 * Called automatically on components but services must implement this themselves
	 */
	protected dispose() {

	}

}
