<ng-template #content><ng-content></ng-content></ng-template>

<ng-container *ngIf="type === _checkbox">
	<mat-checkbox
		[id]="id"
		[name]="name"
		[disabled]="disabled"
		[color]="color"
		[labelPosition]="labelPosition"
		[required]="required"
		[disableRipple]="disableRipple"
		[checked]="checked"
		[aria-label]="ariaLabel"
		[aria-labelledby]="ariaLabelledby"
		[aria-describedby]="ariaDescribedby"
		[(ngModel)]="value"
		(ngModelChange)="_inputChange()"
	>
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</mat-checkbox>
</ng-container>

<ng-container *ngIf="type === _slideToggle">
	<mat-slide-toggle
		[id]="id"
		[name]="name"
		[disabled]="disabled"
		[color]="color"
		[labelPosition]="labelPosition"
		[required]="required"
		[disableRipple]="disableRipple"
		[checked]="checked"
		[aria-label]="ariaLabel"
		[aria-labelledby]="ariaLabelledby"
		[(ngModel)]="value"
		(ngModelChange)="_inputChange()"
	>
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</mat-slide-toggle>
</ng-container>
