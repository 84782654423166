import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Inject,
    Input,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatSelect } from "@angular/material/select";
import {
    CanDisableCtor,
    FormValueCtor,
    IRedrowTextFilter,
    mixinCanDisable,
    mixinFormValue,
    mixinNgSubscriptions,
    NgSubscriptionsCtor,
    REDROW_TEXT_FILTER,
} from "@redrow/utilities";
import { filter, tap } from "rxjs/operators";


class RedrowMaterialSelectFilterComponentBase {
    constructor() { }
}

const _RedrowMaterialSelectFilterComponentMixinBase: NgSubscriptionsCtor & FormValueCtor<string> & CanDisableCtor & typeof RedrowMaterialSelectFilterComponentBase = mixinNgSubscriptions(mixinFormValue(mixinCanDisable(RedrowMaterialSelectFilterComponentBase)));

@Component({
    selector: 'rr-material-select-filter',
    templateUrl: './select-filter.component.html',
    styleUrls: ["./select-filter.component.scss"],
    exportAs: "rrMaterialSelectFilter",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => RedrowMaterialSelectFilterComponent),
        },
    ],
    inputs: [
        "disabled"
    ]
})
export class RedrowMaterialSelectFilterComponent extends _RedrowMaterialSelectFilterComponentMixinBase {

    @ViewChild("filterInput", { read: MatInput }) filterInput: MatInput;

    @Input("placeholder")
    public placeholder: string = "Filter";

    constructor(
        // Get the parent select component
        protected readonly selectComponent: MatSelect,

        // Get the filter directive?
        @Inject(REDROW_TEXT_FILTER) protected readonly filterQueryDirective: IRedrowTextFilter,

        protected readonly cd: ChangeDetectorRef
    ) {
        super();

        // React to text filter changes
        this.ngSubscriptions.add(
            this.filterQueryDirective.textFilterChange()
                .pipe(
                    // Detect change from our current value
                    filter(
                        text => text !== this.formValue
                    )
                )
                .subscribe(
                    text => {
                        this.setFormValue(text);
                        this.cd.markForCheck();
                    }
                )
        );

        // Reset the text filter when the select component is closed
        this.ngSubscriptions.add(
            this.selectComponent.openedChange
                .pipe(
                    tap(open => {
                        if (open) {
                            // Focus the filter input when opening the select component
                            this.filterInput.focus();
                            this.cd.detectChanges();
                        }
                    }),
                    filter(open => !open) // Only emit when the select is closed
                )
                .subscribe(
                    () => this.filterQueryDirective.resetTextFilter()
                )
        );
    }

    writeValue(obj: string): void {
        super.writeValue(obj);

        // Update our filter component
        this.filterQueryDirective.setTextFilter(obj);
    }

}
