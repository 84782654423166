<ng-template #circle>
  <svg [attr.viewBox]="_viewBox()" class="mdc-circular-progress__indeterminate-circle-graphic"
       xmlns="http://www.w3.org/2000/svg" focusable="false">
    <circle [attr.r]="_circleRadius()"
            [style.stroke-dasharray.px]="_strokeCircumference()"
            [style.stroke-dashoffset.px]="_strokeCircumference() / 2"
            [style.stroke-width.%]="_circleStrokeWidth()"
            cx="50%" cy="50%"/>
  </svg>
</ng-template>

<!--
  All children need to be hidden for screen readers in order to support ChromeVox.
  More context in the issue: https://github.com/angular/components/issues/22165.
-->
<div class="mdc-circular-progress__determinate-container" aria-hidden="true" #determinateSpinner>
  <svg [attr.viewBox]="_viewBox()" class="mdc-circular-progress__determinate-circle-graphic"
       xmlns="http://www.w3.org/2000/svg" focusable="false">
    <circle [attr.r]="_circleRadius()"
            [style.stroke-dasharray.px]="_strokeCircumference()"
            [style.stroke-dashoffset.px]="_strokeDashOffset()"
            [style.stroke-width.%]="_circleStrokeWidth()"
            class="mdc-circular-progress__determinate-circle"
            cx="50%" cy="50%"/>
  </svg>
</div>
<!--TODO: figure out why there are 3 separate svgs-->
<div class="mdc-circular-progress__indeterminate-container" aria-hidden="true">
  <div class="mdc-circular-progress__spinner-layer">
    <div class="mdc-circular-progress__circle-clipper mdc-circular-progress__circle-left">
      <ng-container [ngTemplateOutlet]="circle"></ng-container>
    </div>
    <div class="mdc-circular-progress__gap-patch">
      <ng-container [ngTemplateOutlet]="circle"></ng-container>
    </div>
    <div class="mdc-circular-progress__circle-clipper mdc-circular-progress__circle-right">
      <ng-container [ngTemplateOutlet]="circle"></ng-container>
    </div>
  </div>
</div>
