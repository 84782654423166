/* AUTOEXPORT:SETTING:MODULE:RedrowLoadingScreen */
import { Inject, Injectable, Optional } from "@angular/core";
import { IRedrowLoadingScreenService, LoadingScreenActionStatus, ThrottledConcurrentActions } from "@redrow/utilities";
import { BehaviorSubject, Observable, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";

import { ILoadingScreenActionDisplay } from "../interfaces/loading-screen-action-display.interface";
import { ILoadingScreenAction } from "../interfaces/loading-screen-action.interface";
import { LOADING_SCREEN_ACTIONS } from "../tokens/loading-screen-action.token";

/* AUTOEXPORT:SETTING:MODULE:RedrowLoadingScreen */

@Injectable({
	providedIn: "root",
})
export class RedrowLoadingScreenService implements IRedrowLoadingScreenService {
	/**
	 * Display representation of actions and their state.
	 */
	public readonly actionDisplayStatus: BehaviorSubject<ILoadingScreenActionDisplay[]> = new BehaviorSubject<ILoadingScreenActionDisplay[]>([]);

	/**
	 * true when the application loading has completed fully.
	 */
	public readonly hasLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

	/**
	 * true when this service is loading
	 */
	public readonly loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(@Optional() @Inject(LOADING_SCREEN_ACTIONS) protected readonly actions: ILoadingScreenAction[]) {
		/**
		 * Setup display
		 */
		this.resetDisplayState();
	}

	protected resetDisplayState() {
		if (Array.isArray(this.actions) && this.actions.length > 0) {
			this.actionDisplayStatus.next(
				this.actions.map(
					(x): ILoadingScreenActionDisplay => {
						return {
							text: x.getDisplayName(),
							status: LoadingScreenActionStatus.READY,
							showWarning: false,
						};
					}
				)
			);
		}
	}

	/**
	 * Reset the loading state & start loading again
	 */
	public reload() {
		return of(null).pipe(
			switchMap(() => {
				this.hasLoaded.next(false);
				this.loading.next(false);
				this.resetDisplayState();
				return of(null);
			}),
			switchMap(() => this.load())
		);
	}

	/**
	 * Execute all loading actions in sequence
	 */
	public load(): Observable<number> {
		if (this.hasLoaded.value || this.loading.value) {
			return of(0);
		}

		if (Array.isArray(this.actions) && this.actions.length > 0) {
			return of(null).pipe(
				/**
				 * Start loading
				 */
				tap(() => this.loading.next(true)),

				/**
				 * Execute all loading actions
				 */
				switchMap(() => {
					return ThrottledConcurrentActions(this.actions.map(x => this.loadAction(x)), {
						concurrencyLimit: 1,
						reportProgress: false,
					}).pipe(switchMap(res => of(res.results.reduce((total, current) => total + (current ? 1 : 0), 0))));
				}),

				/**
				 * Stop loading
				 */
				tap(() => this.loading.next(false)),

				/**
				 * Complete
				 */
				tap(() => this.hasLoaded.next(true))
			);
		}

		return of(0);
	}

	/**
	 * Execute a single action tracking it's state
	 * @param action
	 */
	protected loadAction(action: ILoadingScreenAction) {
		return of(null).pipe(
			/**
			 * Update display status
			 */
			tap(() => {
				this.updateDisplayStatus(action, LoadingScreenActionStatus.LOADING, false);
			}),

			/**
			 * Start loading
			 */
			switchMap(() => {
				return action.execute(); //.pipe(catchError(err => of(false)));
			}),

			/**
			 * Update display status
			 */
			tap(success => {
				if (success) {
					this.updateDisplayStatus(action, LoadingScreenActionStatus.LOADED, false);
				} else {
					this.updateDisplayStatus(action, LoadingScreenActionStatus.LOADED, true);
				}
			})
		);
	}

	protected updateDisplayStatus(action: ILoadingScreenAction, status: LoadingScreenActionStatus, showWarning: boolean) {
		const idx = this.actions.indexOf(action);
		if (idx > -1) {
			this.actionDisplayStatus.value[idx].status = status;
			this.actionDisplayStatus.value[idx].showWarning = showWarning;
			this.actionDisplayStatus.next(this.actionDisplayStatus.value);
		}
	}
}
