import { MergeConfiguration } from "@redrow/configuration";
import { CONFIGURATION_BASE, OAUTH_BASE } from "./config.base";
import { CONFIGURATION_ENVIRONMENT, OAUTH_ENVIRONMENT } from "./config.local";
import { IAppConfiguration, AppConfigurationBase, AppConfigurationEnvironment } from "./external-contractor-login-app.interface";
import { IOAuthClientConfig } from "@redrow/oauth";

/**
 * Factory function provided to the configuration system which will then combine all configurations based on the environment.
 * 
 * Although we are importing the local config - this will be replaces in angular.json to the appropriate environment configuration
 */
export const applicationConfigurationFactory: () => MergeConfiguration<
		IAppConfiguration, 
		AppConfigurationBase, 
		AppConfigurationEnvironment,
		IOAuthClientConfig,
		IOAuthClientConfig
	> = () => {
	return [CONFIGURATION_BASE, CONFIGURATION_ENVIRONMENT, OAUTH_BASE, OAUTH_ENVIRONMENT];
}
