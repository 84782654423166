import { trimStringSafe } from "@redrow/material";

import { IInstructionAttachment } from "./IInstructionAttachment";
import { IInstructionComment } from "./IInstructionComment";
import { IWorkInstructionHeader, WorkInstructionHeader } from "./IWorkInstructionHeader";

export interface IWorkInstructionDetail extends IWorkInstructionHeader {

	// Attachments are not included & must be collected separately
	attachments?: IInstructionAttachment[];

	// Attachments are not included & must be collected separately
	comments?: IInstructionComment[];

	commentCount: number;
	attachmentCount: number;

	lastSentDate: Date;
	firstSentDate: Date;
	details: string;

	targetDate: Date;
	subcontractorContactAttemptDate: Date;
	SubcontractorLetterSentDate: Date;

	createdDate: Date;
	assigneeDisplayName: string;
	assigneeDisplayCode: string;
	assigneeType: number;
	incidentReference: string;
	regionId: number;
	siteId: number;
	plotId: number;
	createdRegion: string;
	createdBy: string;
	showPostalAddress: boolean;
	showDetails: boolean;
	incidentTypeId: number;
	incidentTypeName: string;
	commentsEnabled: boolean;
	appointmentPast: boolean;
	daysOutstanding: number;
	overdue: boolean;
	plotAddress: string;
	plotString: string;
	postalAddress: string;
	awaitingParts: boolean;
	contactAttempted: boolean;
	contactAwaitingResponse: boolean;
	urgencyName: string;

	/**
	 * Customer contact details
	 */

	customerOrganisation?: string;
	customerTitle?: string;
	customerFirstName?: string;
	customerLastName?: string;
	customerHomeTelephone?: string;
	customerMobileTelephone?: string;
	customerEmailAddress?: string;
	customerWorkTelephone?: string;

}




export class WorkInstructionDetail extends WorkInstructionHeader {

	protected _raw: IWorkInstructionDetail;

	constructor(raw: IWorkInstructionDetail | WorkInstructionDetail) {
		super(raw);
	}

	get commentCount(): number {
		return +this._raw.commentCount;
	}

	get attachmentCount(): number {
		return +this._raw.attachmentCount;
	}

	get lastSentDate(): Date {
		return ("lastSentDate" in this._raw && this._raw.lastSentDate !== null ? new Date(this._raw.lastSentDate) : null);
	}
	get firstSentDate(): Date {
		return ("firstSentDate" in this._raw && this._raw.firstSentDate !== null ? new Date(this._raw.firstSentDate) : null);
	}
	get details(): string {
		return this._raw.details;
	}

	get targetDate(): Date {
		return ("targetDate" in this._raw && this._raw.targetDate !== null ? new Date(this._raw.targetDate) : null);
	}
	get subcontractorContactAttemptDate(): Date {
		return ("subcontractorContactAttemptDate" in this._raw && this._raw.subcontractorContactAttemptDate !== null ? new Date(this._raw.subcontractorContactAttemptDate) : null);
	}
	get SubcontractorLetterSentDate(): Date {
		return ("SubcontractorLetterSentDate" in this._raw && this._raw.SubcontractorLetterSentDate !== null ? new Date(this._raw.SubcontractorLetterSentDate) : null);
	}

	get createdDate(): Date {
		return ("createdDate" in this._raw && this._raw.createdDate !== null ? new Date(this._raw.createdDate) : null);
	}
	get assigneeDisplayName(): string {
		return this._raw.assigneeDisplayName;
	}
	get assigneeDisplayCode(): string {
		return this._raw.assigneeDisplayCode;
	}
	get assigneeType(): number {
		return +this._raw.assigneeType;
	}
	get incidentReference(): string {
		return this._raw.incidentReference;
	}
	get regionId(): number {
		return +this._raw.regionId;
	}
	get siteId(): number {
		return +this._raw.siteId;
	}
	get plotId(): number {
		return +this._raw.plotId;
	}
	get createdRegion(): string {
		return this._raw.createdRegion;
	}
	get createdBy(): string {
		return trimStringSafe(this._raw.createdBy);
	}
	get showPostalAddress(): boolean {
		return (+this._raw.showPostalAddress) ? true : false;
	}
	get showDetails(): boolean {
		return (+this._raw.showDetails) ? true : false;
	}
	get incidentTypeId(): number {
		return +this._raw.incidentTypeId;
	}
	get commentsEnabled(): boolean {
		return (+this._raw.commentsEnabled) ? true : false;
	}
	get appointmentPast(): boolean {
		return (+this._raw.appointmentPast) ? true : false;
	}
	get daysOutstanding(): number {
		return +this._raw.daysOutstanding;
	}
	get overdue(): boolean {
		return (+this._raw.overdue) ? true : false;
	}
	get plotAddress(): string {
		return this._raw.plotAddress;
	}
	get plotString(): string {
		return this._raw.plotString;
	}
	get postalAddress(): string {
		return this._raw.postalAddress;
	}
	get awaitingParts(): boolean {
		return (+this._raw.awaitingParts) ? true : false;
	}
	get contactAttempted(): boolean {
		return (+this._raw.contactAttempted) ? true : false;
	}
	get contactAwaitingResponse(): boolean {
		return (+this._raw.contactAwaitingResponse) ? true : false;
	}


	get urgencyName(): string {
		return this._raw.urgencyName;
	}

	get incidentTypeName(): string {
		return this._raw && typeof this._raw.incidentTypeName === "string" ? this._raw.incidentTypeName.split(" Incident").join("") : null;
	}


	get customerOrganisation(): string {
		return this._raw && typeof this._raw.customerOrganisation === "string" ? this._raw.customerOrganisation : null;
	}
	get customerTitle(): string {
		return this._raw && typeof this._raw.customerTitle === "string" ? this._raw.customerTitle : null;
	}
	get customerFirstName(): string {
		return this._raw && typeof this._raw.customerFirstName === "string" ? this._raw.customerFirstName : null;
	}
	get customerLastName(): string {
		return this._raw && typeof this._raw.customerLastName === "string" ? this._raw.customerLastName : null;
	}
	get customerHomeTelephone(): string {
		return this._raw && typeof this._raw.customerHomeTelephone === "string" ? this._raw.customerHomeTelephone : null;
	}
	get customerMobilePhone(): string {
		return this._raw && typeof this._raw.customerMobileTelephone === "string" ? this._raw.customerMobileTelephone : null;
	}
	get customerEmailAddress(): string {
		return this._raw && typeof this._raw.customerEmailAddress === "string" ? this._raw.customerEmailAddress : null;
	}
	get customerWorkTelephone(): string {
		return this._raw && typeof this._raw.customerWorkTelephone === "string" ? this._raw.customerWorkTelephone : null;
	}

}
