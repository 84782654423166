import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SharedDirectiveModule } from "@shared/directives/shared-directive.module";

import { ModalLoaderComponent } from "./modal-loader.component";
import { PageShellComponent } from "./page-shell.component";


const EXPORT_COMPONENTS = [
	PageShellComponent,
	ModalLoaderComponent
];

@NgModule({
	imports: [
		CommonModule,
		SharedDirectiveModule,
		MatProgressSpinnerModule
	],
	declarations: [...EXPORT_COMPONENTS],
	exports: [...EXPORT_COMPONENTS]
})
export class PageShellModule { }
