import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { IGenericActionProvider } from "../interfaces/generic-action-provider.interface";
import { IGenericAction } from "../interfaces/generic-action.interface";

/**
 * A simple implementation of an action provider which can be used to provide & consume actions.
 */
@Injectable()
export class SimpleActionProvider implements IGenericActionProvider {

	protected readonly _actions: BehaviorSubject<IGenericAction[]> = new BehaviorSubject<IGenericAction[]>(undefined);

	provideActions(actions: IGenericAction[]) {
		this._actions.next(actions);
	}
	consumeActions(): Observable<IGenericAction[]> {
		return this._actions.asObservable();
	}

	clearActions() {
		this._actions.next(undefined);
	}

}
