import { InjectionToken } from "@angular/core";

import { IAppAuthenticationService } from "./app-authentication-service.interface";

/**
 * Authentication service for use with apps.
 * The provided service should implement {@link IAppAuthenticationService} and handle authenticating the user.
 * 
 * NOTE: New interface which OAuth is the first to implement, but going forward we should try to move existing authentication methods to use the same interface.
 */
export const APP_AUTHENTICATION_SERVICE = new InjectionToken<IAppAuthenticationService>("APP_AUTHENTICATION_SERVICE");