import { InjectionToken } from "@angular/core";

import { IRedrowInternalLoginFormService } from "./internal-login-form-service";
import { IRedrowInternalLoginService } from "./internal-login-service.interface";
import { IRedrowInternalLoginApplicationRepository } from "./repositories/internal-login-application-repository.interface";
import { IRedrowInternalLoginDepartmentRepository } from "./repositories/internal-login-department-repository.interface";
import { IRedrowInternalLoginDivisionRepository } from "./repositories/internal-login-division-repository.interface";
import { IRedrowInternalLoginOutletRepository } from "./repositories/internal-login-outlet-repository.interface";

export const REDROW_INTERNAL_LOGIN_APPLICATION_REPOSITORY = new InjectionToken<IRedrowInternalLoginApplicationRepository>("APPLICATION_REPOSITORY");
export const REDROW_INTERNAL_LOGIN_DEPARTMENT_REPOSITORY = new InjectionToken<IRedrowInternalLoginDepartmentRepository>("DEPARTMENT_REPOSITORY");
export const REDROW_INTERNAL_LOGIN_DIVISION_REPOSITORY = new InjectionToken<IRedrowInternalLoginDivisionRepository>("DIVISION_REPOSITORY");
export const REDROW_INTERNAL_LOGIN_OUTLET_REPOSITORY = new InjectionToken<IRedrowInternalLoginOutletRepository>("OUTLET_REPOSITORY");

export const REDROW_INTERNAL_LOGIN_SERVICE = new InjectionToken<IRedrowInternalLoginService>("REDROW_INTERNAL_LOGIN_SERVICE");
export const REDROW_INTERNAL_LOGIN_FORM_SERVICE = new InjectionToken<IRedrowInternalLoginFormService>("REDROW_INTERNAL_LOGIN_FORM_SERVICE");