import { ContractorProfileStatus } from "./contractor-profile-status.enum";
import { IContractorProfile } from "./contractor-profile.interface";
import { BuildIssuePermission } from "./permissions/build-issue-permission";
import { IBuildIssuePermission } from "./permissions/build-issue-permission.interface";
import { UserManagementPermission } from "./permissions/user-management-permission";
import { IUserManagementPermission } from "./permissions/user-management.interface";
import { WorkInstructionPermission } from "./permissions/work-instruction-permission";
import { IWorkInstructionPermission } from "./permissions/work-instruction-permission.interface";

/**
 * Actual implementation of the contractor profile - which handles the conversion of types from the token representation
 */
export class ContractorProfile implements IContractorProfile {

	_raw: IContractorProfile;

	constructor(raw: object) {
		this._raw = raw as IContractorProfile;
	}
	get sub(): string {
		return this._raw.sub;
	}
	get email(): string {
		return this._raw.email;
	}
	get name(): string {
		return this._raw.name;
	}
	get group(): number {
		return this._raw && "group" in this._raw ? +this._raw.group : null
	}
	get company(): string {
		return this._raw.company;
	}
	get id(): number {
		return +this._raw.id;
	}
	get status(): ContractorProfileStatus {
		return +this._raw.status;
	}
	get WorkInstructionApi(): IWorkInstructionPermission {
		return new WorkInstructionPermission(+this._raw.WorkInstructionApi);
	}
	get BuildIssueApi(): IBuildIssuePermission {
		return new BuildIssuePermission(+this._raw.BuildIssueApi);
	}
	get UserManagementApi(): IUserManagementPermission {
		return new UserManagementPermission(+this._raw.UserManagementApi);
	}



}
