import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IAppConfiguration } from "@config/external-contractor-login-app.interface";
import { CONFIGURATION_MAPPER, IConfiguration } from "@redrow/configuration";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseSubcontractorController } from "./base-controller";
import { IOAuthGroupCreatePatch, IUserGroupModel } from "./group.model";
import { IMinimalPermissionsDTO, MinimalPermissionsDTO } from "./permission-models";
import { IOAuthUserCreate, IUserModel, IUserModelWithGroup } from "./user.model";

interface IUserModalData {
	permissions?: IMinimalPermissionsDTO;
	userPermissions?: number[];
	groups?: IUserGroupModel[];
	numberOfActiveAdmins?: number;
}

interface IGroupModalData {
	permissions?: IMinimalPermissionsDTO;
	groupPermissions?: number[];

}

@Injectable({
	providedIn: "root"
})
export class UserManagementController<TUserClass = IUserModel, TUserGroupClass = IUserGroupModel> extends BaseSubcontractorController {

	constructor(protected readonly httpClient: HttpClient, @Inject(CONFIGURATION_MAPPER) protected readonly configMapper: IConfiguration<IAppConfiguration>) {
		super(configMapper);
	}

	protected url(path: string) {
		return super.url(
			`usermanagement/${path}`
		);
	}

	public getUserModalData(id?: number): Observable<IUserModalData> {
		return this.httpClient.get<IUserModalData>(this.urlWithId(`modals/user`, id)).pipe(
			map(x => {

				x.permissions = new MinimalPermissionsDTO(x.permissions);

				return x;
			})
		);
	}

	public getGroupModalData(id?: number): Observable<IGroupModalData> {
		return this.httpClient.get<IGroupModalData>(this.urlWithId(`modals/group`, id)).pipe(
			map(x => {

				x.permissions = new MinimalPermissionsDTO(x.permissions);

				return x;
			})
		);
	}

	public patchGroup(id: number, patch: Partial<IOAuthGroupCreatePatch>): Observable<boolean> {
		return this.httpClient.patch(
			this.urlWithId("groups", id),
			patch,
			{
				observe: "response"
			}
		).pipe(
			map(x => x.ok)
		);
	}

	public patchUser(id: number, patch: Partial<IOAuthUserCreate>): Observable<boolean> {
		return this.httpClient.patch(
			this.urlWithId("users", id),
			patch,
			{
				observe: "response"
			}
		).pipe(
			map(x => x.ok)
		);
	}

	public reinviteUser(id: number): Observable<boolean> {
		return this.httpClient.post<number>(`${this.urlWithId("users", id)}/resendinvite`, null, {
			observe: "response"
		}).pipe(
			map(x => x.ok)
		)
	}

	public createUser(model: IOAuthUserCreate): Observable<number> {
		return this.httpClient.post<number>(this.url("users"), model);
	}

	public listUsers(): Observable<IUserModelWithGroup[]> {
		return this.httpClient.get<IUserModelWithGroup[]>(this.url(`users`));
	}

	public listGroups(): Observable<TUserGroupClass[]> {
		return this.httpClient.get<TUserGroupClass[]>(this.url(`groups`));
	}


	/**
	 * Schedule a user for deletion after a certain date.
	 * @param id
	 * @param date
	 */
	public deleteUserOn(id: number, date: Date): Observable<boolean> {
		return this.patchUser(id, {
			scheduledDeleteDateTime: date,
			clearScheduledDeletion: date instanceof Date ? false : true
		});
	}

	public deleteUser(id: number): Observable<boolean> {
		return this.httpClient.delete(this.urlWithId(`users`, id), {
			observe: "response"
		}).pipe(
			map(x => x.ok)
		)
	}


	public deleteGroup(groupId: number): Observable<boolean> {
		return this.httpClient.delete(this.urlWithId(`groups`, groupId), {
			observe: "response"
		}).pipe(
			map(x => x.ok)
		)
	}

	public createGroup(model: IOAuthGroupCreatePatch): Observable<number> {
		return this.httpClient.post<number>(this.url(`groups`), {
			...model,
			description: typeof model.description === "string" && model.description.length > 0 ? model.description : null
		});
	}

}
