import { Component } from "@angular/core";

/**
 * Loader Component
 */
@Component({
  selector: 'rr-loader',
  templateUrl: './rr-loader.component.html',
  styleUrls: ['./rr-loader.component.scss']
})
export class RedrowLoaderComponent {

  /**
   * constructor
   */
  constructor() { }

}
