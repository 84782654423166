import { Directive, HostListener, Inject, Input } from "@angular/core";
import { OAUTH_SERVICE, IRedrowOAuthService, mixinNgSubscriptions } from "@redrow/utilities";
import { BuildIssueController } from "@shared/api/buildissue.controller";
import { IInstructionController } from "@shared/api/IInstructionController";
import { IWorkInstructionHeader } from "@shared/api/IWorkInstructionHeader";
import { WorkInstructionController } from "@shared/api/workinstruction.controller";
import { IContractorProfile } from "@shared/oauth/contractor-profile.interface";
import { of, Subject } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";

@Directive({
	selector: "[checkSeenDate]"
})
export class InstructionOpened extends mixinNgSubscriptions(class _ {}) {
	@Input("checkSeenDate") instruction: IWorkInstructionHeader;
	@Input("instruction-type") instructionType: string;

	private checked: boolean = false;
	private checkInProgress: boolean = false;

	private selectedController: IInstructionController;

	protected readonly clickSubject = new Subject<boolean>();

	constructor(
		@Inject(BuildIssueController) protected readonly buildIssueApi: IInstructionController,
		@Inject(WorkInstructionController) protected readonly workInstructionApi: IInstructionController,
		@Inject(OAUTH_SERVICE) protected readonly oauthService: IRedrowOAuthService<IContractorProfile>
	) {
		super();

		this.ngSubscriptions.add(
			this.clickSubject
			.pipe(
				// Prevent multiple requests at the same time and assign correct controller for issue type
				tap(() => {
					this.checkInProgress = true;

					if(this.instructionType === "work") {
						this.selectedController = this.workInstructionApi;
					} else if (this.instructionType === "build") {
						this.selectedController = this.buildIssueApi;
					} else {
						this.selectedController = undefined;
					}
				}),

				// Get user profile
				switchMap(() => this.oauthService.getUserProfile()),

				// Make request to set opened
				switchMap(user => this.selectedController.setOpened(this.instruction.id)),

				catchError(err => of(false)),

				// Mark instruction as successfully opened
				tap(() => {
					this.checkInProgress = false;
					this.checked = true;
				}),

				take(1)
			)
			.subscribe()
		);
	}

	@HostListener("click")
	onClick() {
		if(!this.checkInProgress && !this.checked && this.instruction && !this.instruction.OpenedOnScPortalDate) {
			this.clickSubject.next(true);
		}
	}
}