import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";

import { RedrowBaseComponent } from "../../base-component";

/* AUTOEXPORT:SETTING:MODULE:SharedComponentsModule */



/**
 * Button Component
 */
@Component({
	selector: "rr-button",
	templateUrl: "./rr-button.component.html",
	styleUrls: ["./rr-button.component.scss"],
})
export class RedrowButtonComponent extends RedrowBaseComponent {
	constructor(protected readonly Renderer2: Renderer2, protected readonly el: ElementRef, protected readonly ChangeDetectorRef: ChangeDetectorRef) {
		super();
	}

	@ViewChild("wrapper")
	set content(content: ElementRef) {
		if (content !== null) {
			this.contentSpan = content;
			this.updateHasContent();
		}
	}

	@HostBinding("class.no-text")
	get hasNoText(): boolean {
		return !this.hasContent;
	}
	protected hasContent: boolean = true;
	/**
	 * Icon class name that you want to display.
	 */
	@Input()
	icon = "";

	/**
	 * type of the button eg. 'submit'. defaults to 'button'.
	 */
	@Input()
	type = "button";

	/**
	 * Custom click event due to disabled not disabling the underlying button.
	 */
	@Output()
	rrClick = new EventEmitter();

	private contentSpan: ElementRef;

	protected updateHasContent() {
		if (!!this.contentSpan) {
			this.hasContent = !!this.contentSpan.nativeElement.innerHTML;
		} else {
			this.hasContent = false;
		}
		this.ChangeDetectorRef.detectChanges();
	}


	/**
	 * HostListener for click event on element.
	 * @param e Click event.
	 */
	@HostListener("click", ["$event"])
	onClick(e: Event) {
		(document.activeElement as any).blur();

		if (this.disabled) {
			return;
		}
		this.rrClick.emit();
	}
}
