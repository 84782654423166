import { AccessLevel, IRedrowProfile, IRedrowTokenBase } from "@redrow/utilities";

import { OAuthIdentityToken } from "../../private/models/oauth-token-identity.model";
import { RedrowTokenBase } from "../../private/models/redrow-token-base.model";

/**
 * Expected interface for the redrow.profile claims given as part of the internal oauth.
 */
export class RedrowProfile extends OAuthIdentityToken implements IRedrowProfile {
	protected _RedrowTokenBase: IRedrowTokenBase;

	constructor(raw: any) {
		super(raw);

		this._RedrowTokenBase = new RedrowTokenBase(raw);
	}

	// scopes

	get departmentName() {
		return this._raw.dept_name;
	}

	get regionName() {
		return this._raw.region_name;
	}

	get outletName() {
		return this._raw.outlet_name;
	}

	get email() {
		return this._raw.email;
	}

	get name() {
		return this._raw.name;
	}

	get windowsUsername() {
		return this._raw.windows_username;
	}

	// redrow profile base
	get userId() {
		return this._RedrowTokenBase.userId;
	}

	get departmentCode() {
		return this._RedrowTokenBase.departmentCode;
	}

	get regionId() {
		return this._RedrowTokenBase.regionId;
	}

	get accessLevel(): AccessLevel {
		return this._RedrowTokenBase.accessLevel;
	}

	get outletId() {
		return this._RedrowTokenBase.outletId;
	}

	// Backwards compatibility with rr.web.ng.core
	get divisionName() {
		return this.regionName;
	}

	get divisionId() {
		return this.regionId;
	}
}
