import { InjectionToken } from "@angular/core";

import { IGenericActionProvider } from "../interfaces/generic-action-provider.interface";



/**
 * Provider for actions that can be performed on the User Management screen.
 */
export const GENERIC_ACTIONS = new InjectionToken<IGenericActionProvider>("GENERIC_ACTIONS");
