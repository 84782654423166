import { Provider } from "@angular/core";
import { LEGACY_APP_CONFIG } from "@redrow/utilities";
import { CONFIGURATION } from "../tokens/configuration.token";
import { deepMergeConfiguration } from "./deep-merge-configuration.function";

/**
 * Default configuration mapper provider
 */
export function getDefaultLegacyConfigurationMapperProvider(): Provider {
	return {
		provide: LEGACY_APP_CONFIG,
		useFactory: deepMergeConfiguration,
		multi: false,
		deps: [CONFIGURATION]
	};
}