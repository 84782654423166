import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IAppConfiguration } from "@config/external-contractor-login-app.interface";
import { CONFIGURATION_MAPPER, IConfiguration } from "@redrow/configuration";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseSubcontractorController } from "./base-controller";
import { IDashboardPageData } from "./dashboard-page-data.interface";

interface IDashboardPageDataRawTile {
	a?: number;
	b?: number;
	c?: number;
	d?: number;
	e?: number;
}
interface IDashboardPageDataRaw {
	w?: IDashboardPageDataRawTile;
	b?: IDashboardPageDataRawTile;
}

@Injectable({
	providedIn: "root"
})
export class DashboardController extends BaseSubcontractorController {

	constructor(protected readonly httpClient: HttpClient, @Inject(CONFIGURATION_MAPPER) protected readonly configMapper: IConfiguration<IAppConfiguration>) {
		super(configMapper);
	}

	protected url(path: string) {
		return super.url(
			`dashboard/${path}`
		);
	}

	public getPageData(): Observable<IDashboardPageData> {
		return this.httpClient.post<IDashboardPageDataRaw>(this.url(`page/data`), {}).pipe(
			map(x => {
				const result: IDashboardPageData = {
					workInstructionCount: x?.w?.a,
					buildIssueInstructionCount: x?.b?.a,
					workInstructionsAssignedToMe: x?.w?.b,
					buildIssuesAssignedToMe: x?.b?.b,
					workInstructionsRequiringAppointment: x?.w?.c,
					buildIssuesRequiringAppointment: x?.b?.c,
					buildIssuesRequiringPhotoEvidence: x?.b?.d,
					workInstructionsNotYetOpened: x?.w?.e,
					buildIssuesNotYetOpened: x?.b?.e
				};
				return result;
			})
		);
	}


}
