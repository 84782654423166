import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialogExtendedEventType, NoticeModalComponent, RedrowModalService } from "@redrow/material";
import { filter } from "rxjs/operators";

export enum ErrorContext {
	DEFAULT,

	LOADING,
	SAVING,

	DELETING
}

/**
 * The UI service to handle all things error like.
 */
@Injectable({
	providedIn: "root"
})
export class UserMessageService {

	constructor(
		protected readonly modalService: RedrowModalService
	) { }

	public isTooFewAdminError(res) {
		return res instanceof HttpErrorResponse && res.error.errors && Array.isArray(res.error.errors.tooFewAdminCheck) && res.error.errors.tooFewAdminCheck.indexOf("TOO_FEW_ADMINS") > -1;
	}

	public isOtherAdminBeingDeletedError(res) {
		return res instanceof HttpErrorResponse && res.error.errors && Array.isArray(res.error.errors.tooFewAdminCheck) && res.error.errors.tooFewAdminCheck.indexOf("OTHER_ADMIN_BEING_DELETED") > -1;
	}

	public translateErrorToDisplayMessage(res: HttpErrorResponse, context: ErrorContext): string {

		// HTTP Errors
		// Validation errors
		if (res.status === 400) {
			if (res.error && typeof res.error === "string") {
				return res.error;
			} else if (typeof res.error === "object") {
				// Possibly a standard .net validation error
				const validationKeys = res.error.errors ? Object.keys(res.error.errors) : null;
				if (res.error.errors && Array.isArray(res.error.errors.emailAddress) && res.error.errors.emailAddress.indexOf("INVITE_ALREADY_SENT") > -1) {
					return "An invite has already been sent to this user. Please wait before sending another one.";
				} else if (res.error.errors && Array.isArray(res.error.errors.emailAddress) && res.error.errors.emailAddress.indexOf("ACCOUNT_ALREADY_EXISTS") > -1) {
					return "An account with that email address already exists.";
				} else if (res.error.errors && Array.isArray(res.error.errors.emailAddress) && res.error.errors.emailAddress.indexOf("OPERATION_FAILED") > -1) {
					return "The operation failed, please try again later.";
				} else if (this.isTooFewAdminError(res)) {
					if (context === ErrorContext.DELETING) {
						return "At least one user is required to be part of the admin group. Please assign another user as an admin before removing this one.";
					} else if (context == ErrorContext.SAVING) {
						return "You cannot change this users permissions as at least one user is required to be in the admin group. Please assign another user as an admin before changing this users permissions.";
					}

				} else if (this.isOtherAdminBeingDeletedError(res)) {
					return "You cannot delete this user. One admin user is required and the other has an end date on their profile. Please remove the end date or assign another user as an admin before deleting this one.";
				} else if (res.error.errors && validationKeys.length > 0) {

					// Try to list out the validation errors
					let s = "";

					for (const key of validationKeys) {
						const e = res.error.errors[key];
						for (const ee of e) {
							s += `${key}: ${ee}\n`;
						}
					}

					return s;

				}
			}
		} else if (res.status >= 500 && res.status < 600) {
			// Server errors
			return "Something went wrong, please try again later.";
		}

		if (res.status === 401 || res.status === 403) {
			return "It looks like you don't have the correct permissions to do that.";
		}

		// Something else happened
		return "Something went wrong, please try again later.";

	}

	public showNotice(message: string, title: string = "Notice") {
		return this.modalService.openSubscribe(
			NoticeModalComponent,
			{
				width: "500px",
				data: {
					title: title,
					content: message
				}
			}
		).pipe(
			filter(x => x.type === MatDialogExtendedEventType.CLOSED)
		);
	}

	public showErrorNotice(messageOrError: string | HttpErrorResponse, context: ErrorContext, title: string = "Notice") {

		console.error(`showErrorNotice`, messageOrError, context);

		/**
		 * Extract error from http error
		 */
		if (messageOrError instanceof HttpErrorResponse) {
			messageOrError = this.translateErrorToDisplayMessage(messageOrError, context);
		}

		if (context === ErrorContext.LOADING) {
			title = "Loading Notice";
		} else if (context === ErrorContext.SAVING) {
			title = "Saving Notice";
		}


		return this.modalService.openSubscribe(
			NoticeModalComponent,
			{
				width: "500px",
				data: {
					title: title,
					content: messageOrError
				}
			}
		).pipe(
			filter(x => x.type === MatDialogExtendedEventType.CLOSED)
		);

	}

}
