import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { PageShellModule } from "@shared/page-shell/page-shell.module";

import { SettingsComponent } from "./settings.component";



@NgModule({
	declarations: [
		SettingsComponent,

	],
	imports: [
		CommonModule,

		ReactiveFormsModule,
		MatInputModule,
		MatFormFieldModule,
		MatSelectModule,
		MatIconModule,
		MatInputModule,
		MatButtonModule,
		MatToolbarModule,
		MatProgressSpinnerModule,
		PageShellModule
	]
})
export class SettingsModule { }
