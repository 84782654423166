import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { configureProduction } from "./main";

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

const providers = [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

window.addEventListener("unhandledrejection", function (promiseRejectionEvent) {
    console.error(`Unhandled rejection`, promiseRejectionEvent);
});

window.addEventListener("error", function (err) {
    console.error(`window.error`, err);
});

configureProduction();

platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .catch(err => {
        console.error(err);
        try {
            alert("Failed to boostrap AppModule." + JSON.stringify(err));
        } catch (e) {
            alert("Failed to boostrap AppModule.");
        }
    });
