import { HttpClient } from "@angular/common/http";
import { IAppConfiguration } from "@config/external-contractor-login-app.interface";
import { IConfiguration } from "@redrow/configuration";
import { MaxDimensionSizeResolver } from "@redrow/material";
import { RedrowImageConverterService, RedrowImageResizerService, restrictImageSize } from "@redrow/ng-services";
import { defer, Observable, of, zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { BaseSubcontractorController } from "./base-controller";
import { IAssigneeDTO } from "./IAssigneeDTO";
import { ICommentDTO } from "./ICommentDTO";
import { IInstructionAttachment } from "./IInstructionAttachment";
import { IInstructionComment } from "./IInstructionComment";
import { IInstructionController } from "./IInstructionController";
import { IInstructionPageDataOptions } from "./instruction-page-data-options.interface";
import { IInstructionPageData } from "./instruction-page-data.interface";
import { IOutletDTO } from "./IOutletDTO";
import { IUpdateInstructionDTO } from "./IUpdateInstructionDTO";
import { IWorkInstructionDetail, WorkInstructionDetail } from "./IWorkInstructionDetail";
import { IWorkInstructionHeader, WorkInstructionHeader } from "./IWorkInstructionHeader";
import { IListWorkInstructionOptionsDTO } from "./ListWorkInstructionOptionsDTO";
import { SharedSettingController } from "./sharedsetting.controller";

export class InstructionController extends BaseSubcontractorController implements IInstructionController {

	constructor(protected readonly controllerName,
		protected readonly httpClient: HttpClient,
		protected readonly sharedSettings: SharedSettingController,
		protected readonly configMapper: IConfiguration<IAppConfiguration>,
		protected readonly imageResizer: RedrowImageResizerService,
		protected readonly imageConverter: RedrowImageConverterService) {
		super(configMapper);
	}

	protected url(path: string) {
		return super.url(
			`${this.controllerName}/${path}`
		);
	}

	/**
	 * Get all the required data for an instruction page
	 * @param opts
	 */
	public getInstructionPageData(opts: IInstructionPageDataOptions): Observable<IInstructionPageData> {
		const _opts = opts;

		if (_opts.instructionOptions) {
			_opts.instructionOptions.headerOnly = true;
		}

		return this.httpClient.post<IInstructionPageData>(this.url(`page/data`), _opts).pipe(
			map(x => {

				/**
				 * Handle data conversions for instruction data.
				 */
				if (Array.isArray(x.instructionList)) {
					x.instructionList = x.instructionList.map(y => new WorkInstructionHeader(y))
				}

				x.filterDateRange = x.filterDateRange ?? {};

				if (typeof x.filterDateRange.minDate === "string") {
					x.filterDateRange.minDate = new Date(x.filterDateRange.minDate);
				}

				if (typeof x.filterDateRange.maxDate === "string") {
					x.filterDateRange.maxDate = new Date(x.filterDateRange.maxDate);
				}

				x.completedFilterDateRange.minDate = new Date(x.completedFilterDateRange.minDate);
				x.completedFilterDateRange.maxDate = new Date(x.completedFilterDateRange.maxDate);

				return x;
			})
		);
	}

	public listAttachments(instructionId: number): Observable<IInstructionAttachment[]> {
		return this.httpClient.post<IInstructionAttachment[]>(this.url(`list/attachments`), {
			id: instructionId
		});
	}
	public listComments(instructionId: number): Observable<IInstructionComment[]> {
		return this.httpClient.post<IInstructionComment[]>(this.url(`list/comments`), {
			id: instructionId
		});
	}

	public assignees(): Observable<IAssigneeDTO[]> {
		return this.httpClient.get<IAssigneeDTO[]>(this.url(`assignees`));
	}

	public filterAssignees(): Observable<IAssigneeDTO[]> {
		return this.httpClient.get<IAssigneeDTO[]>(this.url(`filter/assignees`));
	}

	public outlets(): Observable<IOutletDTO[]> {
		return this.httpClient.get<IOutletDTO[]>(this.url(`outlets`));
	}

	public count(filter: IListWorkInstructionOptionsDTO = {}): Observable<number> {
		return this.httpClient.post<string>(this.url(`count`), filter).pipe(
			map(x => parseInt(x))
		);
	}

	public get(id: number, filter: IListWorkInstructionOptionsDTO = {}): Observable<IWorkInstructionDetail> {
		return this.httpClient.post<IWorkInstructionDetail[]>(this.url(`list`), {
			...filter,
			instruction: id,
			includeDetails: true
		}).pipe(
			map(x => new WorkInstructionDetail(x[0]))
		);
	}

	public list(filter: IListWorkInstructionOptionsDTO = {}): Observable<IWorkInstructionHeader[]> {
		return this.httpClient.post<IWorkInstructionHeader[]>(this.url(`list`), {
			...filter,
			headerOnly: true
		}).pipe(
			map(x => x.map(y => new WorkInstructionHeader(y)))
		)
	}

	public assignOther(instructionId: number, userId?: number): Observable<boolean> {
		return this.httpClient.post(this.url(`assign/other`), {
			Id: instructionId,
			AssigneeId: userId
		}, {
			observe: "response"
		}).pipe(
			map(x => x.ok)
		)
	}

	public assignSelf(instructionId: number): Observable<boolean> {
		return this.httpClient.post<string>(this.url(`assign/self`), {
			Id: instructionId
		}, {
			observe: "response"
		}).pipe(
			map(x => x.ok)
		)
	}


	public update(instructionId: number, model: IUpdateInstructionDTO): Observable<boolean> {
		return this.httpClient.post(this.url(`update`), {
			id: instructionId,
			...model
		}, {
			observe: "response"
		}).pipe(
			map(x => x.ok)
		);
	}

	public comment(instructionId: number, model: ICommentDTO) {
		return this._comment(instructionId, model, "comment");
	}

	public resolve(instructionId: number, model: ICommentDTO) {
		return this._comment(instructionId, model, "resolve");
	}

	public reject(instructionId: number, reason: string) {
		return this.httpClient.post(this.url(`reject`), {
			id: instructionId,
			commentBody: reason
		}, {
			observe: "response"
		}).pipe(
			map(x => x.ok)
		);
	}

	protected _resize(file: File, maxDimension: number, quality: number): Observable<Blob> {
		return defer(() => this.imageConverter.fromBlob(file)).pipe(
			switchMap(img => {
				const size = MaxDimensionSizeResolver(maxDimension)(img);
				return defer(
					() => this.imageConverter.toBlob(img).pipe(
						switchMap(blob => this.imageResizer.resizeBlob(blob, {
								calcDimensionFn: restrictImageSize({
									maxHeight: size.height,
									maxWidth: size.width,
									maintainAspectRatio: true
								}),
								quality: quality,
							})
						)
					)
				);
			})
		);
	}

	protected _comment(instructionId: number, model: Partial<ICommentDTO>, endpoint: string) {

		return this.sharedSettings.get().pipe(
			switchMap(sharedSettings => {

				const m = model;

				if (Array.isArray(m.attachments) && m.attachments.length > 0) {
					return zip(
						of(m),
						...m.attachments.map(
							a => defer(() => this._resize(a, sharedSettings.maxAttachmentImageDimension, 0.8))
						)
					);
				}

				return zip(of(m));

			}),

			switchMap((res: any[]) => {

				const m: ICommentDTO = res[0];
				let blobs: Blob[];
				if (m.attachments) {
					res.splice(0, 1);
					blobs = res;
				}

				// Start building form data
				const formData = new FormData();
				formData.append("commentBody", model.commentBody);
				formData.append("id", `${instructionId}`);

				// Add image attachments
				if (blobs) {
					let i = 0;
					for (const blob of blobs) {
						i++;
						formData.append(`attachments`, blob, `fileupload${i}`);
					}
				}

				// Then submit the file uploads to the db
				return this.httpClient.post<string>(this.url(`${endpoint}`), formData, {
					observe: "response"
				}).pipe(
					map(x => x.ok)
				);

			})
		);



	}

	/*
	* #57903 - Send instruction id and current user id to mark an instruction as viewed by a subcontractor
	*/
	public setOpened(instructionId: number): Observable<void> {
		return this.httpClient.put<void>(this.url(`setopened/${instructionId}`), null);
	}
}
