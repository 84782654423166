import { Component, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { RedrowImageConverterService, RedrowImageResizerService, restrictImageSize } from "@redrow/ng-services";
import { Subject, firstValueFrom, switchMap } from "rxjs";

import { MaxDimensionSizeResolver } from "../classes/MaxDimensionSizeResolver";

@Component({
	selector: "img-file",
	template: `<img class="{{imageClass}}" [src]="imageUri" />`
})
export class FIleImageComponent implements OnChanges, OnDestroy {
	ngOnChanges(changes: SimpleChanges): void {
		if ("file" in changes || "maxDimension" in changes || "quality" in changes) {
			this.refresh();
		}
	}
	ngOnDestroy(): void {
		this.disposeImage();
	}

	@Input()
	imageClass: string;

	@Input()
	file: File;

	@Input()
	maxDimension: number = 256;

	@Input()
	quality: number = 0.5;

	protected _imageUri: string;

	imageUri: SafeResourceUrl;

	@Output()
	imageReady: Subject<void> = new Subject<void>();

	disposeImage() {
		if (this._imageUri) {
			URL.revokeObjectURL(this._imageUri as string);
			console.log(`Revoke ${this._imageUri}`);
			this._imageUri = null;
			this.imageUri = null;
		}
	}

	constructor(
		protected readonly domSan: DomSanitizer,
		protected readonly imageResizer: RedrowImageResizerService,
		protected readonly imageConverter: RedrowImageConverterService
	) {

	}

	async refresh() {
		this.disposeImage();
		if (this.file) {
			console.log(`Resize file to ${this.maxDimension} @ ${this.quality}`);

			const img = await firstValueFrom(this.imageConverter.fromBlob(this.file));
			const size = MaxDimensionSizeResolver(this.maxDimension)(img);
			const blob = await firstValueFrom(this.imageResizer.resizeBlob(this.file, {
				calcDimensionFn: restrictImageSize({
					maxHeight: size.height,
					maxWidth: size.width,
					maintainAspectRatio: true
				}),
				quality: this.quality,
			}));
			this._imageUri = URL.createObjectURL(blob);
			this.imageUri = this.domSan.bypassSecurityTrustUrl(this._imageUri);
			this.imageReady.next();
		}
	}
}
