import { Inject, Injectable } from "@angular/core";
import { Guid, ILogger, UpdateQueryString } from "@redrow/utilities";
import { OAuthService as AngularOAuthOpenIdService } from "angular-oauth2-oidc";
import { Observable, Subject, Subscription } from "rxjs";
import { share, take, takeUntil, tap } from "rxjs/operators";

import { IOAuthParamInjectionFlow } from "../_deprecated/interfaces/oauth-param-injection-flow.interface";
import { OAUTH_LOGGER } from "../_deprecated/tokens/oauth-logger.token";
import { decrypt } from "../functions/tiny-decrypt.function";
import { IFrameHandlerService } from "./oauth-iframe-handler.service";

interface IExpectedCallbackResult {
	key: string;
	href: string;
	error?: string;
}

/**
 * Inject the iframe flow into the legacy oauth service.
 * 
 * @deprecated Please see {@page ~~/wiki/solutions/authentication/oauth-quickstart.md} for how to configure OAuth.
 */
@Injectable({
	providedIn: "root",
})
export class OAuthIFrameParamInjectionFlow implements IOAuthParamInjectionFlow {
	constructor(
		protected readonly iframeHandler: IFrameHandlerService,
		protected readonly oauthService: AngularOAuthOpenIdService,
		@Inject(OAUTH_LOGGER) protected readonly logger: ILogger
	) { }

	protected setRequestParam(key: string, value: any) {
		return sessionStorage.setItem(`OCB_${key}`, JSON.stringify(value));
	}

	protected getRequestParam<T>(key: string): T {
		return JSON.parse(sessionStorage.getItem(`OCB_${key}`));
	}

	protected clearRequestParams() {
		this.logger.debug("Clearing request params...");
		let counter = 0;
		while (counter < sessionStorage.length) {
			const key = sessionStorage.key(counter);
			if (key.startsWith("OCB_")) {
				sessionStorage.removeItem(key);
				counter = 0;
				continue;
			}
			counter++;
		}
	}

	protected _isInjectingParams: boolean = false;

	/**
	 * When successful, this function will push state onto the window url for the auth code.
	 * IMPORTANT: Be sure to replace state after using the code, otherwise you will get code leakage
	 * @param requiredParams 
	 * @param asyncCancelToken 
	 */
	public async tryInjectParams(requiredParams: string[], asyncCancelToken: Observable<any> = null) {

		if (asyncCancelToken === null || asyncCancelToken === undefined) {
			asyncCancelToken = new Subject<void>();
		}

		if (this._isInjectingParams) {
			throw new Error("Trying to inject params twice!");
		}

		// Try build a login url using the oauth library
		const expectedPrefix = Guid.newGuidString();
		const additionalState = "";

		// Tell the login page it is running in an iframe & should act appropriately
		const params = {
			iframeFlow: true,
			iframePrefix: expectedPrefix,
		};
		const loginUrl = await this.oauthService["createLoginUrl"](additionalState, "", null, false, params);

		this.logger.debug(`Generated login url: ${loginUrl}`);
		this.logger.debug(`Expected prefix: ${expectedPrefix}`);
		this.logger.debug(`Required params: ${requiredParams}`);

		// Store session params to pass onto iframe
		this.setRequestParam("id", expectedPrefix);
		this.setRequestParam("required", requiredParams);

		let asyncCancelTokenSub: Subscription;

		try {

			let asyncTokenCancelled = false;

			// Allow the caller to cancel the async function based on an observable
			if (asyncCancelToken) {
				asyncCancelTokenSub = asyncCancelToken.subscribe(
					null, null, () => {
						asyncTokenCancelled = true;
					}
				);
			}

			this._isInjectingParams = true;

			let result: IExpectedCallbackResult = undefined;

			// Create the iframe and listen for a response
			const iframeObservable = this.iframeHandler
				.createListen<IExpectedCallbackResult>(loginUrl, `${expectedPrefix}:`, {
					fullscreen: true,
					id: "oauth-iframe",
					timeout: 10000, // 10 second timeout
					hideUntilReady: true,
				})
				.pipe(
					share(),
					takeUntil(asyncCancelToken),
					take(1),
					tap(res => {
						result = res;
					})
				);

			// Wait for iframe observable to complete
			await iframeObservable.toPromise();

			if (asyncTokenCancelled) {
				return false;
			}

			// Check for valid object
			if (!("href" in result && "key" in result)) {
				throw new Error("Missing 'key' or 'href' in iframe response.");
			}

			// Catch errors thrown by the iframe
			if (result.error) {
				throw new Error(`IFrame error: ${result.error}`);
			}

			this.logger.debug(`iframe result:`, { ...result });

			// Extract encryption key from session storage
			const encryptionKey = this.getRequestParam<string>(result.key);

			// Remove the encryption key straight away
			sessionStorage.removeItem(result.key);

			this.logger.debug(`encryption key: ${encryptionKey}`);

			// Try to decrypt the payload
			const href = decrypt(atob(result.href), encryptionKey);

			this.logger.debug(`href: ${href}`);

			// Extract params from href
			const outParams = {};

			// Check origin
			const url = new URL(href);
			if (url.origin !== location.origin) {
				throw new Error("ORIGIN MISMATCH");
			}

			// Extract params from result
			const urlParams = new URLSearchParams(url.search);
			urlParams.forEach((value, key) => {
				outParams[key] = value;
			});

			this.logger.debug(`outParams:`, outParams);

			if (asyncTokenCancelled) {
				return false;
			}

			// Push a history state with the code in so that it can be read later on by frontend auth implementation
			let newUri = window.location.pathname;
			for (const key of Object.keys(outParams)) {
				newUri = UpdateQueryString(key, outParams[key], newUri);
			}

			history.pushState(
				null,
				"",
				newUri
			);

			return true;
		} catch (e) {
			throw e;
		} finally {

			if (asyncCancelTokenSub && !asyncCancelTokenSub.closed) {
				asyncCancelTokenSub.unsubscribe();
				asyncCancelTokenSub = undefined;
			}

			this._isInjectingParams = false;

			// Cleanup
			this.clearRequestParams();
		}
	}
}
