import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IAppConfiguration } from "@config/external-contractor-login-app.interface";
import { CONFIGURATION_MAPPER, IConfiguration } from "@redrow/configuration";
import { Observable } from "rxjs";

import { BaseSubcontractorController } from "./base-controller";
import { IASiteDocument } from "./IASiteDocument.interface";
import { IDocumentController } from "./IDocumentController";

@Injectable({
	providedIn: "root"
})
export class DocumentController extends BaseSubcontractorController implements IDocumentController {

	constructor(protected readonly httpClient: HttpClient, @Inject(CONFIGURATION_MAPPER) protected readonly configMapper: IConfiguration<IAppConfiguration>) {
		super(configMapper);
	}

	protected url(path: string) {
		return super.url(
			`document/${path}`
		);
	}

	public listDocuments(): Observable<IASiteDocument[]> {
		return this.httpClient.get<IASiteDocument[]>(this.url(`list`));
	}



}
