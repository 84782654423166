import { Inject, Injectable } from "@angular/core";
import {
    APP_AUTHENTICATION_SERVICE,
    AppAuthenticationServiceLogoutEvent,
    AppAuthenticationServiceUserProfileEvent,
    IAppAuthenticatedUserService,
    IAppAuthenticationService,
    mixinNgSubscriptions,
    NgSubscriptionsCtor,
} from "@redrow/utilities";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";

// class _RedrowAppAuthenticatedUserServiceBase { }
const _RedrowAppAuthenticatedUserServiceMixin: NgSubscriptionsCtor = mixinNgSubscriptions(class _ { });

/**
 * Default implementation of {@link @redrow/utilities!IAppAuthenticatedUserService} which uses your chosen {@link @redrow/utilities!APP_AUTHENTICATION_SERVICE} to determine the user profile.
 */
@Injectable({
    providedIn: "root"
})
export class RedrowAppAuthenticatedUserService<TProfile> extends _RedrowAppAuthenticatedUserServiceMixin implements IAppAuthenticatedUserService<TProfile> {

    protected readonly _userProfile = new BehaviorSubject<TProfile>(undefined);

    constructor(
        @Inject(APP_AUTHENTICATION_SERVICE) protected readonly appAuthenticationService: IAppAuthenticationService
    ) {
        super();

        this.ngSubscriptions.add(
            this.appAuthenticationService.observeAppAuthenticationEvents().pipe(

                // If the logout event occurs - we null out the user profile
                tap(
                    e => e instanceof AppAuthenticationServiceLogoutEvent && this._userProfile.next(undefined)
                ),

                // We only want to listen for user profile events.
                filter(
                    e => e instanceof AppAuthenticationServiceUserProfileEvent
                ),

                // Emiting the 
                map(e => e instanceof AppAuthenticationServiceUserProfileEvent && e.getProfile<TProfile>())
            ).subscribe(
                userProfile => this._userProfile.next(userProfile)
            )
        );
    }

    observeAuthenticatedUser(): Observable<TProfile> {
        return this._userProfile.asObservable();
    }

}