import { InjectionToken } from "@angular/core";

import { ISyncItemFactoryService } from "../interfaces";


/**
 * Token which provides {@link ISyncItemFactoryService} to sync services.
 * 
 * <ng-sync-sync-item-factory-demo></ng-sync-sync-item-factory-demo>
 */
export const SYNC_ITEM_FACTORY_SERVICE = new InjectionToken<ISyncItemFactoryService<any>>("SYNC_ITEM_FACTORY_SERVICE");
