/* AUTOEXPORT:SETTING:MODULE:ApplicationShellModule */
import { CommonModule } from "@angular/common";
import { ErrorHandler, ModuleWithProviders, NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import {
    APP_SERVICE,
    APP_SHELL_SETTINGS,
    IAppShellSettings,
    REDROW_ERROR_HANDLER,
    REDROW_LOADING_SCREEN_SERVICE,
} from "@redrow/utilities";
import { LazyLoadImageModule } from "ng-lazyload-image";

import { ApplicationErrorComponent } from "./components/application-error/application-error.component";
import { BrowserOfflineErrorComponent } from "./components/browser-offline-error/browser-offline-error.component";
import { FIleImageComponent } from "./components/file-image.component";
import { ImageThumbnailComponent } from "./components/ImageThumbnailComponent";
import { RedrowLoadingScreenActionComponent } from "./components/loading-screen-action.component";
import { RedrowLoadingScreenComponent } from "./components/loading-screen.component";
import { RedrowDefaultAppShellComponent } from "./components/redrow-default-app-shell.component";
import { RedrowButtonComponent } from "./components/rr-button/rr-button.component";
import { RedrowIconComponent } from "./components/rr-icon/rr-icon.component";
import { RedrowLoaderComponent } from "./components/rr-loader/rr-loader.component";
import { ConfirmationModalComponent } from "./modals/confirm/confirm-modal.component";
import { NoticeModalComponent } from "./modals/notice/notice-modal.component";
import { ModalTitleComponent } from "./modals/title/modal-title.component";
import { ServiceWorkerErrorHandler } from "./redrow-error-handlers/service-worker-error-handler";
import { RedrowLoadingScreenService } from "./service/loading-screen.service";
import { RedrowDefaultAppService } from "./services/redrow-default-app.service";
import { RedrowErrorHandler } from "./services/redrow-error-handler.service";

const ExportModals = [
	ConfirmationModalComponent,
	NoticeModalComponent
];

const ExportComponent = [
	RedrowDefaultAppShellComponent,
	RedrowButtonComponent,
	RedrowIconComponent,
	RedrowLoaderComponent,
	RedrowLoadingScreenActionComponent,
	RedrowLoadingScreenComponent,
	ApplicationErrorComponent,
	BrowserOfflineErrorComponent,
	FIleImageComponent,
	ImageThumbnailComponent,
	ModalTitleComponent

];

const ExportProvider = [
	RedrowDefaultAppService,
	RedrowErrorHandler
];


@NgModule({
    imports: [CommonModule, RouterModule, MatDialogModule, MatIconModule, MatButtonModule, LazyLoadImageModule],
    declarations: [...ExportComponent, ...ExportModals],
    exports: [...ExportComponent, ...ExportModals],
    providers: [...ExportProvider]
})
export class RedrowLibModule {

	/**
	 * Provides various services using the shared components module
	 */
	static forRoot(opts?: IAppShellSettings): ModuleWithProviders<RedrowLibModule> {
		return {
			ngModule: RedrowLibModule,
			providers: [

				{
					provide: REDROW_LOADING_SCREEN_SERVICE,
					useExisting: RedrowLoadingScreenService,
					multi: false,
				},

				/**
				 * Provide the default app service
				 */
				{
					provide: APP_SERVICE,
					useClass: RedrowDefaultAppService,
					multi: false,
				},

				/**
				 * Provide some default settings for the app shell
				 */
				{
					provide: APP_SHELL_SETTINGS,
					useValue: opts ? opts : {

						checkForBrowserOffline: false,
						checkForBrowserSupport: true,
						loadingScreen: false,
						modal: true,
						sharedBackdrop: true,
						sidebar: true

					} as IAppShellSettings,
					multi: false,
				},

				/**
				 * Provide a default error handler
				 */
				{
					provide: ErrorHandler,
					useClass: RedrowErrorHandler
				},

				/**
				 * Handle service worker errors
				 */
				{
					provide: REDROW_ERROR_HANDLER,
					useClass: ServiceWorkerErrorHandler,
					multi: true
				}
			],
		};
	}

}
