import { IBuildIssuePermission } from "./build-issue-permission.interface";

export class BuildIssuePermission implements IBuildIssuePermission {

	constructor(flags: number) {
		this.list = (flags & 1) === 1;
		this.update = (flags & 2) === 2;
		this.assignOthers = (flags & 4) === 4;
		this.assignSelf = (flags & 8) === 8;
	}

	list: boolean;
	update: boolean;
	assignSelf: boolean;
	assignOthers: boolean;

}
